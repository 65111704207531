import React from 'react'
import Button from '../Button'
import { isNil } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/free-solid-svg-icons'

export enum FileTypes {
  PNG = 'image/x-png',
  JPG = 'image/jpeg',
}

type FileInputProps = {
  handleMultipleFiles?: (files: File[]) => void
  handleFile?: (file: File) => void
  label?: string
  acceptedFileTypes: FileTypes[]
  styles?: string
  editPencilStyle?: boolean
}

const FileInput = ({
  handleMultipleFiles,
  label,
  acceptedFileTypes,
  handleFile,
  styles = 'flex w-full justify-center',
  editPencilStyle,
}: FileInputProps) => {
  const hiddenFileInput: React.RefObject<HTMLInputElement> = React.createRef()

  const handleClick = () => {
    if (!isNil(hiddenFileInput.current)) {
      hiddenFileInput.current.click()
    }
  }
  const onChange = (event: any) => {
    const files = event.target.files
    if (files && files.length > 0) {
      if (handleMultipleFiles) {
        handleMultipleFiles(Array.from(files))
      } else if (!isNil(handleFile)) {
        handleFile(files[0])
      }
    }
  }

  return (
    <>
      <div className={styles}>
        {editPencilStyle ? (
          <div
            className="w-7 h-7 bg-bg_details shadow-md hover:bg-lumepic-medium_grey transition duration-200 cursor-pointer flex justify-center items-center"
            style={{ borderRadius: '50%' }}
            onClick={handleClick}
          >
            <FontAwesomeIcon icon={faPencil} className="text-lumepic-light_black text-sm" />
          </div>
        ) : (
          <Button gold onClick={handleClick}>
            {label}
          </Button>
        )}
      </div>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={onChange}
        style={{ display: 'none' }}
        accept={acceptedFileTypes.toString()}
        multiple={!isNil(handleMultipleFiles)}
      />
    </>
  )
}

export default FileInput
