import React, { useState, useEffect, useContext } from 'react'
import { observer } from 'mobx-react'
import { withTranslation, useTranslation } from 'react-i18next'
import StoresContext from '../../../../providers/storesContext'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title, LinearScale } from 'chart.js'
import UsersTypeRoleStore from './UsersTypeRoleStore'
import Doughnut from '../../components/Doughnut'
import Spinner from '../../../../components/UI/Spinner/Spinner'

const UsersTypeRole = () => {
  const { t } = useTranslation('common')
  const { authStore } = useContext(StoresContext)!
  const [usersTypeRoleStore] = useState(() => new UsersTypeRoleStore(authStore))

  const fetchData = async () => {
    await usersTypeRoleStore.fetchData()
  }

  useEffect(() => {
    ChartJS.register(ArcElement, Tooltip, Legend, Title, LinearScale)
    fetchData()
  }, [])

  if (usersTypeRoleStore.isLoading) {
    return (
      <div className="bg-white p-5 rounded-lg">
        <div className="flex flex-col">
          <div className="flex flex-row justify-between items-center text-gray-800 pb-8">
            <p className="pb-6">
              <span className="font-bold">{t('Users Type of Roles')}</span>
            </p>
          </div>
          <div className="mx-auto pt-4">
            <Spinner color="text-primary-light" size={10} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="bg-white p-5 rounded-lg">
      <div className="flex flex-col">
        <p className="pb-6">
          <span className="font-bold text-base">{t('Users Type Role')}</span>
        </p>
        {usersTypeRoleStore.data && usersTypeRoleStore.userRoleTypeCountDataset?.length > 0 ? (
          <Doughnut data={usersTypeRoleStore.data} />
        ) : (
          <p>{t('No information for Users type of Role')}</p>
        )}
      </div>
    </div>
  )
}

export default withTranslation('common')(observer(UsersTypeRole))
