import axios, { adminApiInstance } from './axios'
import * as paths from '../services/Paths'
import { parseAxiosErrorResponse } from '../utility'

class PhotographService {
  deletePhotograph(photoId: string, token: string) {
    return axios
      .delete(paths.deletePhoto(photoId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return response.data
      })
      .catch(parseAxiosErrorResponse)
  }

  updatePhotographPrice(photoId: string, newPrice: number, token: string) {
    return axios
      .put(
        `${paths.PHOTOGRAPH}/${photoId}`,
        {
          price: Number(newPrice),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => response)
      .catch(parseAxiosErrorResponse)
  }

  getAdminPhotographDownload(token: string, photographId: string) {
    return adminApiInstance
      .get(paths.adminPhotographDownloadUrl(photographId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const link = document.createElement('a')
        link.href = response.data

        link.click()
      })
      .catch(parseAxiosErrorResponse)
  }

  getAdminPhotographDownloadUrl(token: string, photographId: string) {
    return adminApiInstance
      .get(paths.adminPhotographDownloadUrl(photographId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return response.data
      })
      .catch(parseAxiosErrorResponse)
  }

  fetchAdminAlbumsPhotographs(albumId: string, token: string, page: number) {
    return axios
      .get(`${paths.photographsFromAlbumById(albumId)}?page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        return {
          photographs: data.data,
          paginator: data.meta,
        }
      })
      .catch(parseAxiosErrorResponse)
  }

  suspendPhotograph(token: string, photographId: string) {
    return axios
      .post(paths.suspendPhotographById(photographId), photographId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  activatePhotograph(token: string, photographId: string) {
    return axios
      .post(paths.activatePhotographById(photographId), photographId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data)
      .catch(parseAxiosErrorResponse)
  }
}

export default PhotographService
