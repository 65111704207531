const prefix = '/dashboard'
export const DASHBOARD_ALBUMS = `${prefix}/albums`
export const DASHBOARD_UPLOADED_PHOTOGRAPHS = `${prefix}/uploaded-photographs`
export const DASHBOARD_USERS_TYPE_ROLE = `${prefix}/users-type-role`
export const DASHBOARD_ALBUMS_LOCATION = `${prefix}/albums-location`
export const DASHBOARD_SPOTTED_EARNINGS = `${prefix}/spotted-earnings`
export const DASHBOARD_PHOTOGRAPHERS_EARNINGS = `${prefix}/photographers-earnings`
export const DASHBOARD_PURCHASES = `${prefix}/purchases`
export const DASHBOARD_REVENUE_IN_PERIOD_TIME = `${prefix}/revenue-in-period-time`

export const photographerEarningsById = (photographerId: string) => {
  return `${prefix}/photographer/${photographerId}/earnings`
}

export const photographerSalesById = (photographerId: string) => {
  return `${prefix}/photographer/${photographerId}/sales`
}

export const athletePurchaseQuantityIById = (athleteId: string) => {
  return `${prefix}/athlete/${athleteId}/purchases`
}
