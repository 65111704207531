import React, { useState, useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useParams } from 'react-router'
import AdminAlbumsPhotographsStore from './AdminAlbumsPhotographsStore'
import { withTranslation } from 'react-i18next'
import AdminPhotographList from './components/AdminPhotographList'
import PhotographDeleteModal from './components/PhotographDeleteModal'
import EditPhotographModal from './components/EditPhotographModal/EditPhotographModal'
import EditPhotographStore from './components/EditPhotographModal/EditPhotographStore'
import { isNil } from 'lodash'
import StoresContext from '../../providers/storesContext'
import { useQuery } from '../../hooks/useQuery'
import { Photograph } from '../../models/Photograph'
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler'
import axios from '../../services/axios'
import ResultsPager from '../../components/UI/ResultsPager'
import Spinner from '../../components/UI/Spinner/Spinner'

const AdminAlbumsPhotographs = () => {
  const query = useQuery()
  const { id } = useParams<{ id: string }>()
  const { authStore } = useContext(StoresContext)!
  const [adminAlbumPhotographsStore] = useState(() => new AdminAlbumsPhotographsStore(authStore))
  const [visibleAddPhotographFormModal, setVisibleAddPhotographFormModal] = useState(false)
  const [visibleEditPhotographFormModal, setVisibleEditPhotographFormModal] = useState(false)
  const [editionAdminPhotographStore, setEditionAdminPhotographStore] =
    useState<EditPhotographStore | null>(null)
  const [editionAdminPhotograph, setEditionAdminPhotograph] = useState<Photograph | null>(null)
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)
  const [photographIdToDelete, setPhotographIdToDelete] = useState<string | null>(null)
  const [deletionAdminPhotographError, setDeletionAdminPhotographError] = useState(null)
  const [addingImagesToAlbum] = useState<{
    toggle: boolean
    albumId: string | null
  }>({
    toggle: false,
    albumId: null,
  })

  if (!id) {
    throw Error('No ID found.')
  }

  const page = Number(query.get('page')) || 1

  const hideAddPhotographModal = () => {
    setVisibleAddPhotographFormModal(false)
  }

  const handleFinishUploadImages = () => {
    hideAddPhotographModal()
    adminAlbumPhotographsStore.fetchAdminAlbumsPhotographs(id, page)
  }

  //Delete Photograph
  const handleClickDelete = (photographId: string) => {
    showDeleteConfirm(photographId)
  }

  const showDeleteConfirm = (photographId: string) => {
    setVisibleDeleteModal(true)
    setPhotographIdToDelete(photographId)
  }

  const hideDeleteConfirm = () => {
    setVisibleDeleteModal(false)
    setDeletionAdminPhotographError(null)
    // avoids showing undefined name while modal is fading out
    setTimeout(() => setPhotographIdToDelete(null), 300)
  }

  const handleDeleteAdminPhotograph = () => {
    if (!isNil(photographIdToDelete)) {
      adminAlbumPhotographsStore.deletePhotograph(photographIdToDelete).then(() => {
        hideDeleteConfirm()
        adminAlbumPhotographsStore.fetchAdminAlbumsPhotographs(id, page)
      })
    }
  }

  //Suspend Photograph
  const handleClickSuspend = (photographId: string) => {
    adminAlbumPhotographsStore.suspendPhotograph(photographId).then(() => {
      adminAlbumPhotographsStore.fetchAdminAlbumsPhotographs(id, page)
    })
  }

  //Download original Photograph
  const handleClickDownload = (photographId: string) => {
    adminAlbumPhotographsStore.downloadPhotograph(photographId)
  }

  //Activate Photograph
  const handleClickActivate = (photographId: string) => {
    adminAlbumPhotographsStore.activatePhotograph(photographId).then((result) => {
      if (result) {
        adminAlbumPhotographsStore.fetchAdminAlbumsPhotographs(id, page)
      }
    })
  }

  //Edit Photograph
  const onClickEditIcon = (photograph: Photograph) => {
    const editPhotographStore = new EditPhotographStore(photograph)
    setEditionAdminPhotographStore(editPhotographStore)
    setEditionAdminPhotograph(photograph)
    showEditPhotographModal()
  }

  const showEditPhotographModal = () => {
    setVisibleEditPhotographFormModal(true)
  }

  const hideEditPhotographModal = () => {
    setVisibleEditPhotographFormModal(false)
    setEditionAdminPhotographStore(null)
    setEditionAdminPhotograph(null)
  }

  const handleClickEdit = (photographId: string) => {
    editionAdminPhotographStore?.updatePrice(photographId, authStore.getToken()).then(() => {
      hideEditPhotographModal()
      adminAlbumPhotographsStore.fetchAdminAlbumsPhotographs(id, page)
    })
  }

  useEffect(() => {
    adminAlbumPhotographsStore.fetchAdminAlbumsPhotographs(id, page).then(() => {})
    adminAlbumPhotographsStore.fetchAlbum(id)
  }, [id, page])

  if (adminAlbumPhotographsStore.isLoading) {
    return (
      <div className="container flex justify-center">
        <div className="mt-14">
          <Spinner size={11} color="text-spotted-gold" />
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <AdminPhotographList
            album={adminAlbumPhotographsStore.album!}
            photographs={adminAlbumPhotographsStore.photographs}
            isLoading={adminAlbumPhotographsStore.isLoading}
            handleClickDownload={handleClickDownload}
            handleClickEdit={onClickEditIcon}
            handleClickSuspend={handleClickSuspend}
            handleClickActivate={handleClickActivate}
            handleClickDelete={handleClickDelete}
          />
          {adminAlbumPhotographsStore.photographs.length > 0 &&
            !adminAlbumPhotographsStore.isLoading && (
              <ResultsPager paginator={adminAlbumPhotographsStore.paginator} query={query} />
            )}
        </div>
      </div>

      {editionAdminPhotograph && editionAdminPhotographStore && (
        <EditPhotographModal
          open={visibleEditPhotographFormModal}
          handleClose={hideEditPhotographModal}
          store={editionAdminPhotographStore}
          photograph={editionAdminPhotograph}
          handleEdit={handleClickEdit}
        />
      )}

      {/*{addingImagesToAlbum.toggle && (*/}
      {/*  <UploadImageToAlbum*/}
      {/*    handleFinish={handleFinishUploadImages}*/}
      {/*    open={visibleAddPhotographFormModal}*/}
      {/*    album={adminAlbumPhotographsStore.album!}*/}
      {/*  />*/}
      {/*)}*/}

      <PhotographDeleteModal
        handleDelete={handleDeleteAdminPhotograph}
        handleClose={hideDeleteConfirm}
        open={visibleDeleteModal}
        isDeleting={adminAlbumPhotographsStore.isDeleting}
        error={deletionAdminPhotographError}
      />
    </div>
  )
}

export default withErrorHandler(withTranslation('common')(observer(AdminAlbumsPhotographs)), axios)
