import ConfigInterface from './interfaces/config.interface'

const API_URL = 'https://api.spotted.com.uy'
const ADMIN_API_URL = 'https://admin-api-gtuaqenefq-uc.a.run.app'
const WEB_URL = 'https://www.lumepic.com'

const production: ConfigInterface = {
  API_URL,
  ADMIN_API_URL,
  WEB_URL,
}

export default production
