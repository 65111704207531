import React from 'react'
import { useTranslation } from 'react-i18next'
import { CodeDiscount } from '../../../../models/CodeDiscount'
import Modal from '../../../../components/UI/Modal/Modal'

type DiscountDeleteModalProps = {
  deletionAdminDiscount: CodeDiscount
  handleDelete: () => void
  handleClose: () => void
  open: boolean
  isDeleting: boolean
  error: any
}

const DiscountDeleteModal = ({
  deletionAdminDiscount,
  handleDelete,
  handleClose,
  open,
  isDeleting = false,
  error,
}: DiscountDeleteModalProps) => {
  const { t } = useTranslation()
  return (
    <Modal
      onSuccess={handleDelete}
      onCancel={handleClose}
      loadingOk={isDeleting}
      okMessage={t('Delete').toString()}
      isDeleteConfirmation
      opened={open}
    >
      <>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3 className="text-lg leading-6 font-bold text-gray-800 text-center" id="modal-title">
            {t('Delete Discount')}
          </h3>
          <div className="mt-4">
            <p className="text-gray-600 text-center font-normal">
              {`${t('Are you sure that you want to delete discount')} 
              ${deletionAdminDiscount?.code} - ${deletionAdminDiscount?.name} ?`}
            </p>
            {error && <p className="text-red-400 text-center font-normal">{`${error}`}</p>}
          </div>
        </div>
      </>
    </Modal>
  )
}

export default DiscountDeleteModal
