import React from 'react'
import { useTranslation } from 'react-i18next'
import { User } from '../../../models/User'
import Modal, { ModalSize } from '../../../components/UI/Modal/Modal'

type UserDeleteModalProps = {
  deletionAdminUser: User | null
  handleDelete: () => void
  handleClose: () => void
  open: boolean
  isDeleting: boolean
  error: boolean | null
}

const UserDeleteModal = ({
  deletionAdminUser,
  handleDelete,
  handleClose,
  open,
  isDeleting = false,
  error,
}: UserDeleteModalProps) => {
  const { t } = useTranslation('common')
  return (
    <Modal
      onSuccess={handleDelete}
      onCancel={handleClose}
      loadingOk={isDeleting}
      okMessage={t('Delete').toString()}
      isDeleteConfirmation
      opened={open}
      modalSize={ModalSize.SMALL}
    >
      <>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3 className="text-lg leading-6 font-bold text-gray-800 text-center" id="modal-title">
            {t('Delete User')}
          </h3>
          <div className="mt-4">
            <p className="text-gray-600 text-center font-normal">
              {`${t('Are you sure that you want to delete')} ${deletionAdminUser?.firstName} ${
                deletionAdminUser?.lastName
              }?`}
            </p>
            {error && <p className="text-red-400 text-center font-normal">{`${error}`}</p>}
          </div>
        </div>
      </>
    </Modal>
  )
}

export default UserDeleteModal
