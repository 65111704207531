import React, { useEffect } from 'react'

import Aux from '../AuxHocs/AuxHoc'
import useHttpErrorHandler from '../../hooks/http-error-handler'
import { toast } from 'react-toastify'

const withErrorHandler = (WrappedComponent: any, axios: any) => {
  // eslint-disable-next-line react/display-name
  return (props: any) => {
    const [error, errorConfirmedHandler] = useHttpErrorHandler(axios)

    useEffect(() => {
      if (error) {
        toast.error(error.message || error, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        errorConfirmedHandler()
      }
    })
    return (
      <Aux>
        <WrappedComponent {...props} />
      </Aux>
    )
  }
}

export default withErrorHandler
