import { isNil } from 'lodash'
import { CodeDiscount } from './CodeDiscount'

export enum RoleType {
  ADMIN = 'admin',
  ATHLETE = 'athlete',
  PHOTOGRAPHER = 'photographer',
}

export class Role {
  type: RoleType
}

export class User {
  id: string

  firstName: string

  lastName: string

  alias: string

  email: string

  password: string

  isSuspended?: boolean

  mercadoPagoVerified?: boolean

  personalCodeDiscount?: CodeDiscount

  clients?: User[] //Esta la van a tener los fotografos

  clientOf?: User //Esta la van a tener los atletas cuando son clientes de un fotografo. Por ahora no la voy a exponer en la API porque no la precisamos en el Front

  roles: Role[]

  createdAt!: Date

  get isPhotographer() {
    return !isNil(this.roles.find((role) => role.type === RoleType.PHOTOGRAPHER))
  }
  get isAthlete() {
    return !isNil(this.roles.find((role) => role.type === RoleType.ATHLETE))
  }
  get isAdmin() {
    return !isNil(this.roles.find((role) => role.type === RoleType.ADMIN))
  }

  static init(userData: User): User {
    const newUser = new User()
    newUser.id = userData.id
    newUser.firstName = userData.firstName
    newUser.lastName = userData.lastName
    newUser.email = userData.email
    newUser.password = userData.password
    newUser.isSuspended = userData.isSuspended
    newUser.mercadoPagoVerified = userData.mercadoPagoVerified
    newUser.personalCodeDiscount = userData.personalCodeDiscount
    newUser.roles = userData.roles
    newUser.createdAt = userData.createdAt
    newUser.clients = userData.clients
    newUser.clientOf = userData.clientOf

    return newUser
  }
}
