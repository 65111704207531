import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation, withTranslation } from 'react-i18next'
import { PlusCircleIcon } from '@heroicons/react/20/solid'
import AlbumRow from './AlbumRow'
import SearchResultListNoResults from '../../../components/SearchResultListNoResults/SearchResultListNoResults'
import Album from '../../../models/Album'
import Spinner from '../../../components/UI/Spinner/Spinner'

type AlbumListProps = {
  albums: Album[]
  isLoading: boolean
  handleClickEdit: (e: any, album: Album) => void
  handleClickSuspend: (e: any, album: Album) => void
  handleClickToAlbumPhotographs: (albumId: string) => void
  handleCreateAlbum: () => void
}

const AlbumList = ({
  albums = [],
  isLoading = false,
  handleClickEdit,
  handleClickSuspend,
  handleClickToAlbumPhotographs,
  handleCreateAlbum,
}: AlbumListProps) => {
  const { t } = useTranslation()

  if (isLoading) {
    return (
      <div className="container flex justify-center">
        <div className="mt-14">
          <Spinner size={11} color="text-spotted-gold" />
        </div>
      </div>
    )
  }

  if (albums.length === 0) {
    return (
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className="flex flex-col items-center justify-center my-10">
          <SearchResultListNoResults />
          <div className="bg-teal-100 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md w-1/2 flex justify-center mt-8">
            <div className="flex">
              <span className="font-bold mr-4">{t('Create Album')}</span>
              <PlusCircleIcon
                className="text-primary-light h-10 w-10 cursor-pointer transition-colors hover:text-primary-dark"
                onClick={handleCreateAlbum}
                title="Create Album"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr className="font-bold">
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {t('Description')}
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {t('Status')}
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {t('Taken Date')}
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {t('Default Image Price')}
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {t('Spot Name')}
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {t('Owner Name')}
            </th>
            <th scope="col" className="relative px-6 py-3 flex justify-end">
              <PlusCircleIcon
                className="text-primary-light h-6 w-6 cursor-pointer transition-colors hover:text-primary-dark"
                onClick={handleCreateAlbum}
                title="Create Album"
              />
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {albums.map((album, index) => (
            <AlbumRow
              key={index}
              album={album}
              handleClickEdit={handleClickEdit}
              handleClickSuspend={handleClickSuspend}
              handleClickToAlbumPhotographs={handleClickToAlbumPhotographs}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default withTranslation()(observer(AlbumList))
