import React, { useState, useEffect, useContext } from 'react'
import { observer } from 'mobx-react'
import { useTranslation, withTranslation } from 'react-i18next'
import DiscountFormModalStore from './DiscountFormModalStore'
import PurchaseCodeDiscountForm from './PurchaseCodeDiscountForm'
import { CodeDiscount } from '../../../../models/CodeDiscount'
import StoresContext from '../../../../providers/storesContext'
import { DiscountType } from '../../../../models/BaseDiscount'
import Modal, { ModalButtonsDisplay } from '../../../../components/UI/Modal/Modal'
import ServerError from '../../../../components/UI/ServerError'
import InputWrapper from '../../../../components/UI/Forms/InputWrapper'
import DatePicker from '../../../../components/UI/Forms/DatePicker'
import FormSelect from '../../../../components/UI/Forms/FormSelect'
import Spinner from '../../../../components/UI/Spinner/Spinner'

type BaseDiscountFormModalProps = {
  open: boolean
  handleClose: () => void
  editionAdminDiscount: CodeDiscount
  afterSaveDiscount: (discount: CodeDiscount) => void
}

const BaseDiscountFormModal = ({
  open,
  handleClose,
  editionAdminDiscount,
  afterSaveDiscount,
}: BaseDiscountFormModalProps) => {
  const { authStore } = useContext(StoresContext)!
  const { t } = useTranslation()
  const [discountFormModalStore] = useState(() => new DiscountFormModalStore(authStore))

  useEffect(() => {
    if (!open) {
      setTimeout(() => discountFormModalStore.reset(), 300)
    }
  }, [open])

  useEffect(() => {
    if (editionAdminDiscount) {
      discountFormModalStore.fillEditionAdminDiscount(editionAdminDiscount)
    }
  }, [editionAdminDiscount])

  const handleChangeDiscountName = (val: string) => discountFormModalStore.changeDiscountName(val)
  const handleChangeType = (val: { value: DiscountType; label: string }) =>
    discountFormModalStore.changeDiscountType(val)
  const handleChangeExpiry = (val: Date) => discountFormModalStore.changeDiscountExpiry(val)

  const { discountName, type, expiry } = discountFormModalStore

  const handleSave = () => {
    if (!editionAdminDiscount) {
      discountFormModalStore.createDiscount().then((discount) => {
        if (discount) {
          afterSaveDiscount(discount)
        }
      })
    } else {
      discountFormModalStore.editDiscount().then((discount) => {
        if (discount) {
          afterSaveDiscount(discount)
        }
      })
    }
  }

  const renderExtraDiscountFormInputs = () => {
    switch (type.value?.value) {
      case DiscountType.PURCHASE_CODE:
        return (
          <>
            <PurchaseCodeDiscountForm discountFormModalStore={discountFormModalStore} />
          </>
        )
      default:
        return ''
    }
  }

  if (discountFormModalStore.isLoading) {
    return (
      <div className="container flex justify-center">
        <div className="mt-14">
          <Spinner size={11} color="text-spotted-gold" />
        </div>
      </div>
    )
  }

  return (
    <Modal
      okMessage={editionAdminDiscount === null ? t('Add').toString() : t('Save').toString()}
      loadingOk={discountFormModalStore.isLoading}
      onCancel={handleClose}
      onSuccess={handleSave}
      opened={open}
      buttonsDisplay={ModalButtonsDisplay.ONLY_OK_BUTTON}
      isDeleteConfirmation
    >
      <div className="w-full grid grid-rows-1 gap-2">
        <div className="text-lg text-gray-800 font-bold text-center">
          {editionAdminDiscount === null
            ? t('New discount').toString()
            : t('Edit discount').toString()}
        </div>
        {discountFormModalStore.error && (
          <ServerError message={t(discountFormModalStore.error).toString()} />
        )}
        <InputWrapper
          label={t('Discount Name').toString()}
          inputStore={discountName}
          onChange={handleChangeDiscountName}
        />

        <InputWrapper
          onChange={handleChangeExpiry}
          selected={expiry}
          as={DatePicker}
          label={t('Expiry Date').toString()}
          inputStore={expiry}
        />

        <InputWrapper
          onChange={handleChangeType}
          placeholder={t('Seleccione tipo de descuento').toString()}
          inputStore={type}
          label={t('Type of discount').toString()}
          as={FormSelect}
          options={discountFormModalStore.discountTypes.map((discountType) => ({
            value: discountType.value,
            label: t(discountType.label).toString(),
          }))}
        />

        {renderExtraDiscountFormInputs()}
      </div>
    </Modal>
  )
}

export default withTranslation('common')(observer(BaseDiscountFormModal))
