import { makeAutoObservable, runInAction } from 'mobx'
import { number } from 'yup'
import { Photograph } from '../../../../models/Photograph'
import InputStore from '../../../../components/UI/Forms/InputWrapper/InputStore'
import PhotographService from '../../../../services/PhotographService'

class EditPhotographStore {
  public photograph: Photograph | null
  public price: InputStore<number>
  public isSaving: boolean
  public error: any
  private photographService: PhotographService
  constructor(photograph: Photograph) {
    this.reset()
    this.photograph = photograph
    makeAutoObservable(this)
    this.photographService = new PhotographService()
  }

  reset() {
    this.price = new InputStore(
      number()
        .positive('El precio debe ser positivo')
        .min(1, 'El precio debe ser mayor a 1')
        .required('El precio es requerido')
    )
    this.isSaving = false
    this.photograph = null
    this.error = null
  }

  changePrice(value: number) {
    this.price.setValue(value)
  }

  clearErrors() {
    this.price.clearError()
  }

  async validate() {
    this.clearErrors()
    let isValid = true

    if (!(await this.price.validate())) {
      isValid = false
    }

    return isValid
  }

  async updatePrice(imageId: string, token: string) {
    if (await this.validate()) {
      runInAction(() => {
        this.isSaving = true
      })

      try {
        const response = await this.photographService.updatePhotographPrice(
          imageId,
          this.price.value,
          token
        )

        runInAction(() => {
          this.isSaving = false
        })

        return {
          success: true,
          result: response,
        }
      } catch (e: any) {
        runInAction(() => {
          const displayedError = this.parseRequestErrors(e.response?.data?.errors || {})

          if (!displayedError) {
            this.error = 'Something went wrong, please check the provided data and try again.'
          }

          if (e?.message) {
            this.error = e.message
          }

          this.isSaving = false
        })
        return { success: false }
      }
    }
    return { success: false }
  }

  parseRequestErrors(messages: any) {
    const keys = Object.keys(messages)
    let displayedError = false

    keys.forEach((key: string) => {
      const [error] = messages[key]

      switch (key) {
        case 'price':
          this.price.setError(true, error)
          displayedError = true
          break

        default:
          break
      }
    })

    return displayedError
  }
}

export default EditPhotographStore
