import { adminApiInstance } from './axios'
import * as paths from './Paths'
import { AxiosResponse } from 'axios'
import { Event } from '../models/Event'
import { parseAxiosErrorResponse } from '../utility'
import UploadCoverPhotographResponse from './Interfaces/Event/UploadCoverPhotographResponse'
import UploadCoverPhotographRequest from './Interfaces/Event/UploadCoverPhotographRequest'
import CreateOrUpdateEventRequestInterface from './Interfaces/Event/CreateEventRequest.interface'

class EventService {
  fetchEvents(token: string, page: number, take: number, name?: string) {
    const path = name
      ? `${paths.ADMIN_EVENTS}?page=${page}&take=${take}&name=${name}`
      : `${paths.ADMIN_EVENTS}?page=${page}&take=${take}`
    return adminApiInstance
      .get(path, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }: AxiosResponse) => {
        const eventModels = data.data.map((e) => Event.init(e))

        return {
          events: eventModels,
          paginator: data.meta,
        }
      })
      .catch(parseAxiosErrorResponse)
  }

  createEvent(token: string, eventData: CreateOrUpdateEventRequestInterface): Promise<Event> {
    return adminApiInstance
      .post(paths.ADMIN_EVENTS, eventData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  deleteEvent(eventId: string, token: string) {
    return adminApiInstance
      .delete(paths.updateEventById(eventId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  editEvent(token: string, eventData: CreateOrUpdateEventRequestInterface): Promise<Event> {
    return adminApiInstance
      .put(paths.updateEventById(eventData.id!), eventData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  suspendEvent(id: string, token: string) {
    return adminApiInstance
      .put(
        paths.suspendEventById(id),
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  async createEventCoverPhotograph(
    type: string,
    eventId: string,
    token: string
  ): Promise<UploadCoverPhotographResponse> {
    const photographPayload: UploadCoverPhotographRequest = {
      type,
    }
    return adminApiInstance
      .post(paths.createEventCoverPhotograph(eventId), photographPayload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => {
        return response.data
      })
      .catch(parseAxiosErrorResponse)
  }
}

export default EventService
