import React, { useRef } from 'react'
import { observer } from 'mobx-react'
import { Doughnut as DoughnutChart } from 'react-chartjs-2'
import { ChartData, TooltipItem } from 'chart.js'

type DoughnutProps = {
  data: ChartData<'doughnut'>
}

const Doughnut = ({ data }: DoughnutProps) => {
  const chartRef = useRef(null)

  const getPercentageLabel = (data: number[], currentValue: number) => {
    let total = 0
    data.forEach((element: number) => {
      total += element
    })

    const percentage = Math.round((currentValue * 100) / total)
    return `${percentage}% (${currentValue})`
  }

  const displayLabel = (tooltipItem: TooltipItem<'doughnut'>) => {
    const percentageInfoLabel = getPercentageLabel(tooltipItem.dataset.data, tooltipItem.parsed)
    return `${percentageInfoLabel} ${tooltipItem.label}`
  }

  return (
    <DoughnutChart
      ref={chartRef}
      data={data}
      options={{
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              usePointStyle: true,
              padding: 20,
            },
          },
          tooltip: {
            callbacks: {
              label: (tooltipItem) => displayLabel(tooltipItem),
            },
          },
        },
      }}
    />
  )
}

export default observer(Doughnut)
