import Modal from 'components/UI/Modal'
import { ModalSize } from 'components/UI/Modal/Modal'
import { Event } from 'models/Event'
import React from 'react'
import { useTranslation } from 'react-i18next'

type SuspendEventModalProps = {
  event: Event
  handleSuspend: () => void
  handleClose: () => void
  opened: boolean
  isLoading: boolean
}

const SuspendEventModal = ({
  event,
  handleSuspend,
  handleClose,
  opened,
  isLoading,
}: SuspendEventModalProps) => {
  const { t } = useTranslation('common')
  return (
    <Modal
      onSuccess={handleSuspend}
      onCancel={handleClose}
      loadingOk={isLoading}
      okMessage={t('Suspend').toString()}
      isDeleteConfirmation
      opened={opened}
      modalSize={ModalSize.SMALL}
    >
      <>
        <div className="my-3">
          <h3 className="text-lg leading-6 font-bold text-gray-800" id="modal-title">
            {t('Suspend event')}
          </h3>
          <div className="mt-4">
            <p className="text-gray-600 font-normal">
              {`${t('Are you sure that you want to suspend the event: ')} 
              ${event?.name}?`}
            </p>
          </div>
        </div>
      </>
    </Modal>
  )
}

export default SuspendEventModal
