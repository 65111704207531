import React, { useState } from 'react'
import Modal, { ModalSize } from '../../../components/UI/Modal/Modal'
import { Location } from '../../../models/Location'
import { useTranslation } from 'react-i18next'
import Album from 'models/Album'
import Spinner from 'components/UI/Spinner'
import { isNil } from 'lodash'
import AlbumAttributeReassigned from 'components/ActivityOrLocationReassignerAfterDelete/ActivityOrLocationReassignerAfterDelete'

type LocationDeleteModalProps = {
  locationToDelete: Location
  locations: Location[]
  albums: Album[]
  handleDelete: (location: Location) => void
  handleClose: () => void
  opened: boolean
  isDeleting: boolean
  error: any
}

const LocationDeleteModal = ({
  locationToDelete,
  locations,
  albums,
  handleDelete,
  handleClose,
  opened,
  isDeleting = false,
  error,
}: LocationDeleteModalProps) => {
  const { t } = useTranslation('common')
  const [newLocation, setNewLocation] = useState<Location>()

  const handleLocationChange = (location: Location) => {
    setNewLocation(location)
  }

  const performDelete = () => {
    if (!isNil(newLocation)) {
      handleDelete(newLocation)
    } else {
      handleDelete(locationToDelete)
    }
  }

  return (
    <Modal
      onSuccess={performDelete}
      onCancel={handleClose}
      loadingOk={isDeleting}
      okMessage={t('Delete').toString()}
      isDeleteConfirmation
      opened={opened}
      modalSize={ModalSize.MEDIUM}
    >
      <>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <div className="mt-4">
            <p className="mb-5 text-center text-2xl">
              {`${t('Are you sure that you want to delete spot')} 
              ${locationToDelete?.spotName} ?`}
            </p>
            {error && <p className="text-red-400 text-center font-normal">{`${error}`}</p>}
          </div>
          {!isNil(albums) && albums?.length > 0 ? (
            <AlbumAttributeReassigned
              albums={albums}
              locations={locations}
              locationToDelete={locationToDelete}
              isDeletingLocation
              onLocationChange={handleLocationChange}
            />
          ) : (
            <div className="container flex justify-center">
              <div className="mt-14">
                <Spinner size={11} color="text-spotted-gold" />
              </div>
            </div>
          )}
        </div>
      </>
    </Modal>
  )
}

export default LocationDeleteModal
