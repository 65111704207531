import { makeAutoObservable, runInAction } from 'mobx'
import AuthStore from '../../stores/AuthStore'
import EventService from '../../services/EventService'
import Paginator from '../../stores/Paginator'
import { Event } from 'models/Event'
import CreateOrUpdateEventRequestInterface from 'services/Interfaces/Event/CreateEventRequest.interface'
import { isNil } from 'lodash'

class EventStore {
  public events: Event[]
  public isLoading: boolean
  public error: any
  public paginator: Paginator
  private authStore: AuthStore
  private eventService: EventService
  constructor(authStore: AuthStore) {
    this.reset()
    makeAutoObservable(this)
    this.eventService = new EventService()
    this.authStore = authStore
  }

  reset() {
    this.events = []
    this.isLoading = false
    this.error = false
  }

  async searchAdminEvents(page: number, take: number, search?: string) {
    runInAction(() => {
      this.isLoading = true
    })
    try {
      const response = await this.eventService.fetchEvents(
        this.authStore.getAdminToken(),
        page,
        take,
        search
      )
      runInAction(() => {
        this.isLoading = false
        this.events = response.events
        this.paginator = response.paginator
        this.error = null
      })
      return response
    } catch (e) {
      this.error = e
      this.isLoading = false
    }
  }

  async suspendEvent(event: Event): Promise<void> {
    runInAction(() => {
      this.isLoading = true
    })
    try {
      await this.eventService.suspendEvent(event.id, this.authStore.getAdminToken())
      runInAction(() => {
        this.isLoading = false
      })
    } catch (e: any) {
      runInAction(() => {
        this.isLoading = false
        this.error = e
      })
    }
  }

  async createEvent(payload: CreateOrUpdateEventRequestInterface) {
    runInAction(() => {
      this.isLoading = true
    })
    payload.ownerId = this.authStore.getLoggedUser().id
    payload.isActive = true

    if (!isNil(payload.customBannerSrc) && payload.customBannerSrc === '') {
      payload.customBannerSrc = undefined
    }
    if (!isNil(payload.quantityDiscountId) && payload.quantityDiscountId === '') {
      payload.quantityDiscountId = undefined
    }

    try {
      const newEvent = await this.eventService.createEvent(this.authStore.getAdminToken(), payload)
      runInAction(() => {
        this.isLoading = false
      })
      return newEvent
    } catch (e: any) {
      runInAction(() => {
        this.isLoading = false
        this.error = e
      })
    }
  }

  async editEvent(payload: CreateOrUpdateEventRequestInterface) {
    runInAction(() => {
      this.isLoading = true
    })

    if (!isNil(payload.customBannerSrc) && payload.customBannerSrc === '') {
      payload.customBannerSrc = undefined
    }
    if (!isNil(payload.quantityDiscountId) && payload.quantityDiscountId === '') {
      payload.quantityDiscountId = undefined
    }

    try {
      const updatedEvent = await this.eventService.editEvent(
        this.authStore.getAdminToken(),
        payload
      )
      runInAction(() => {
        this.isLoading = false
      })

      return updatedEvent
    } catch (e: any) {
      runInAction(() => {
        this.isLoading = false
        this.error = e
      })
    }
  }
}

export default EventStore
