import React, { SyntheticEvent, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useTranslation, withTranslation } from 'react-i18next'
import { MobileDatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { PurchaseStatus } from '../../../models/Purchase'
import { onlyUniqueById } from '../../../utility'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Box from '@mui/material/Box'
import { PurchaseDashboardKpi } from 'models/PurchaseDashboardKpi'
import Spinner from 'components/UI/Spinner'
import AdminPurchasesStore from '../AdminPurchasesStore'

type AutoCompleteUserOption = {
  label: string
  id: string
}

type PurchaseListFiltersProps = {
  store: AdminPurchasesStore
  onSearch: (
    dateFrom?: Date,
    dateTo?: Date,
    ownerId?: string,
    sellerId?: string,
    status?: PurchaseStatus
  ) => void
  kpis: PurchaseDashboardKpi[]
  showStatus: boolean
}

const PurchaseListFilters = (props: PurchaseListFiltersProps) => {
  const { t } = useTranslation()

  useEffect(() => {
    props.onSearch()
  }, [
    props.store.ownerId,
    props.store.sellerId,
    props.store.dateFrom,
    props.store.dateTo,
    props.store.status,
  ])

  function handleOwnerDropdownChange(
    e: SyntheticEvent<Element, Event>,
    value: AutoCompleteUserOption | null
  ) {
    props.store.changeOwnerId(value?.id)
  }

  const handleSellerDropdownChange = (
    e: SyntheticEvent<Element, Event>,
    value: AutoCompleteUserOption | null
  ) => {
    props.store.changeSellerId(value?.id)
  }

  const handlePurchaseStatus = (event: SelectChangeEvent<PurchaseStatus>) => {
    const value = event.target.value as PurchaseStatus | null
    if (value) {
      props.store.changeStatus(value)
    } else {
      props.store.changeStatus(undefined)
    }
  }

  const handleFromDateChange = (value: dayjs.Dayjs | null) => {
    const date = value === null ? undefined : value.toDate()
    props.store.changeDateFrom(date)
  }

  const handleToDateChange = (value: dayjs.Dayjs | null) => {
    const date = value === null ? undefined : value.toDate()
    props.store.changeDateTo(date)
  }

  const purchaseOwnerOptions: AutoCompleteUserOption[] = props.store.owners
    ? props.store.owners
        .map((owner) => {
          return {
            label: `${owner.firstName.toUpperCase()} ${owner.lastName.toUpperCase()} (${
              owner.email
            })`,
            id: owner.id,
          }
        })
        .filter(onlyUniqueById)
    : []
  const purchaseSellerOptions: AutoCompleteUserOption[] = props.store.sellers
    ? props.store.sellers
        .map((seller) => {
          return {
            label: `${seller.firstName.toUpperCase()} ${seller.lastName.toUpperCase()} (${
              seller.email
            })`,
            id: seller.id,
          }
        })
        .filter(onlyUniqueById)
    : []
  return (
    <div>
      {props.store.owners?.length > 0 ? (
        <div className="grid grid-cols-5 gap-2 mb-5">
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={purchaseOwnerOptions}
            onChange={handleOwnerDropdownChange}
            renderInput={(params) => <TextField {...params} label={t('Owner')} />}
          />
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={purchaseSellerOptions}
            onChange={handleSellerDropdownChange}
            renderInput={(params) => <TextField {...params} label={t('Seller')} />}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              label="Fecha Desde"
              defaultValue={dayjs(props.store.dateFrom)}
              onChange={(value) => handleFromDateChange(value)}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              label="Fecha Hasta"
              defaultValue={dayjs(props.store.dateTo)}
              onChange={(value) => handleToDateChange(value)}
            />
          </LocalizationProvider>
          <Box>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">{t('Status')}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={props.store.status}
                label="Status"
                onChange={handlePurchaseStatus}
              >
                <MenuItem value="All">
                  <em>{t('All')}</em>
                </MenuItem>
                {Object.keys(PurchaseStatus).map((status) => (
                  <MenuItem key={status} value={PurchaseStatus[status]}>
                    {t(status)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
      ) : (
        <div className="container flex justify-center">
          <div className="mt-14">
            <Spinner size={10} color="text-spotted-gold" />
          </div>
        </div>
      )}
    </div>
  )
}

export default withTranslation()(observer(PurchaseListFilters))
