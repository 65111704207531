import { makeAutoObservable, runInAction } from 'mobx'
import { Purchase, PurchaseStatus } from '../../models/Purchase'
import { User } from '../../models/User'
import PurchaseService from '../../services/PurchaseService'
import AuthStore from '../../stores/AuthStore'

const DEFAULT_PAGE_SIZE = 25

class AdminPurchasesStore {
  public purchases: Purchase[]
  public owners: User[]
  public sellers: User[]
  public ownerId?: string
  public sellerId?: string
  public dateFrom?: Date
  public dateTo?: Date
  public status?: PurchaseStatus
  public take: number
  public isLoading: boolean
  public isDeleting: boolean
  public error: any
  public paginator: any
  private purchaseService: PurchaseService
  constructor(private readonly authStore: AuthStore) {
    this.reset()
    makeAutoObservable(this)
    this.purchaseService = new PurchaseService()
    this.authStore = authStore
  }

  reset() {
    this.purchases = []
    this.paginator = null
    this.ownerId = undefined
    this.sellerId = undefined
    this.dateFrom = new Date('2023-01-01')
    this.dateTo = new Date()
    this.status = undefined
    this.take = DEFAULT_PAGE_SIZE
    this.isLoading = false
    this.isDeleting = false
    this.error = false
  }

  changeOwnerId(val?: string) {
    this.ownerId = val
  }
  changeSellerId(val?: string) {
    this.sellerId = val
  }
  changeDateFrom(val?: Date) {
    this.dateFrom = val
  }
  changeDateTo(val?: Date) {
    this.dateTo = val
  }
  changeStatus(val?: PurchaseStatus) {
    this.status = val
  }

  async fetchPurchasesOwners() {
    runInAction(() => {})
    try {
      const response = await this.purchaseService.getOwners(this.authStore.getAdminToken())
      runInAction(() => {
        this.owners = response.owners
        this.error = null
      })
      return response
    } catch (e) {
      this.error = e
    }
  }

  async fetchPurchasesSellers() {
    runInAction(() => {})
    try {
      const response = await this.purchaseService.getSellers(this.authStore.getAdminToken())
      runInAction(() => {
        this.sellers = response.sellers
        this.error = null
      })
      return response
    } catch (e) {
      this.error = e
    }
  }

  async fetchAdminPurchases(page: number) {
    runInAction(() => {
      this.isLoading = true
    })

    try {
      const response = await this.purchaseService.fetchAdminPurchases(
        this.authStore.getAdminToken(),
        page,
        this.take,
        this.dateFrom,
        this.dateTo,
        this.ownerId,
        this.sellerId,
        this.status
      )
      runInAction(() => {
        this.isLoading = false
        this.purchases = response.purchases
        this.paginator = response.paginator
        this.error = null
      })
      return response
    } catch (e) {
      this.error = e
      this.isLoading = false
    }
  }

  async approvePurchase(purchaseId: string) {
    runInAction(() => {
      this.isLoading = true
    })
    try {
      const purchaseApproved = await this.purchaseService.approvePurchase(
        this.authStore.getToken(),
        purchaseId
      )
      runInAction(() => {
        this.isLoading = false
      })
      return purchaseApproved
    } catch (e) {
      this.error = e
      this.isLoading = false
    }
  }

  async rejectPurchase(purchaseId: string) {
    runInAction(() => {
      this.isLoading = true
    })
    try {
      const purchaseRejected = await this.purchaseService.rejectPurchase(
        this.authStore.getToken(),
        purchaseId
      )
      runInAction(() => {
        this.isLoading = false
      })
      return purchaseRejected
    } catch (e) {
      this.error = e
      this.isLoading = false
    }
  }

  async deletePurchase(purchaseId: string) {
    runInAction(() => {
      this.isDeleting = true
    })
    try {
      await this.purchaseService.deletePurchase(purchaseId, this.authStore.getToken())
      runInAction(() => {
        this.isDeleting = false
      })
    } catch (e: any) {
      this.error = e?.message
      this.isDeleting = false
    }
  }
}

export default AdminPurchasesStore
