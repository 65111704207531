import local from './local'
import staging from './staging'
import production from './production'

const REACT_APP_ENVIRONMENTS = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',
}

let constants = local

if (process.env.REACT_APP_ENVIRONMENT === REACT_APP_ENVIRONMENTS.DEVELOPMENT) {
  constants = local
} else if (process.env.REACT_APP_ENVIRONMENT === REACT_APP_ENVIRONMENTS.STAGING) {
  constants = staging
} else if (process.env.REACT_APP_ENVIRONMENT === REACT_APP_ENVIRONMENTS.PRODUCTION) {
  constants = production
}
export enum ConfigKeys {
  API_URL = 'API_URL',
  ADMIN_API_URL = 'ADMIN_API_URL',
  WEB_URL = 'WEB_URL',
  DATE_LOCALE = 'DATE_LOCALE',
}

export default class ConfigService {
  public static getValue(key: ConfigKeys): string {
    return constants[key]
  }
}
