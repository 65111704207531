import React, { useState, useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import AdminSystemVariablesStore from './AdminSystemVariablesStore'
import { withTranslation } from 'react-i18next'
import AdminSystemVariableList from './components/AdminSystemVariableList'
import SystemVariableFormModal from './components/SystemVariableFormModal/SystemVariableFormModal'
import { useQuery } from '../../hooks/useQuery'
import StoresContext from '../../providers/storesContext'
import Spinner from '../../components/UI/Spinner/Spinner'
import ResultsPager from '../../components/UI/ResultsPager'
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler'
import axios from '../../services/axios'

const AdminSystemVariables = () => {
  const query = useQuery()
  const { authStore } = useContext(StoresContext)!
  const [adminSystemVariablesStore] = useState(() => new AdminSystemVariablesStore(authStore))
  const [visibleAddSystemVariableFormModal, setVisibleAddSystemVariableFormModal] = useState(false)
  const [editionAdminSystemVariable, setEditionAdminSystemVariable] = useState(null)

  const page = Number(query.get('page')) * 1 || 1

  // // Create Spot
  const showAddSystemVariableModal = () => {
    setVisibleAddSystemVariableFormModal(true)
  }

  const hideAddSystemVariableModal = () => {
    setVisibleAddSystemVariableFormModal(false)
    // avoids showing undefined name while modal is fading out
    setTimeout(() => setEditionAdminSystemVariable(null), 300)
  }

  const afterSaveSystemVariable = () => {
    adminSystemVariablesStore.fetchSystemVariables(page)
    hideAddSystemVariableModal()
  }

  //Edit System Variable
  const handleClickEdit = (spot) => {
    setEditionAdminSystemVariable(spot)
    showAddSystemVariableModal()
  }

  useEffect(() => {
    adminSystemVariablesStore.fetchSystemVariables(page).then(() => {})
  }, [page])

  if (adminSystemVariablesStore.isLoading) {
    return (
      <div className="container flex justify-center">
        <div className="mt-14">
          <Spinner size={11} color="text-spotted-gold" />
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <AdminSystemVariableList
            systemVariables={adminSystemVariablesStore.systemVariables}
            isLoading={adminSystemVariablesStore.isLoading}
            handleClickEdit={handleClickEdit}
            formatSystemVariableKey={adminSystemVariablesStore.systemVariableKeyFormated}
          />
          {adminSystemVariablesStore.systemVariables.length > 0 &&
            !adminSystemVariablesStore.isLoading && (
              <ResultsPager paginator={adminSystemVariablesStore.paginator} query={query} />
            )}
        </div>
      </div>
      <SystemVariableFormModal
        editionAdminSystemVariable={editionAdminSystemVariable}
        afterSaveSystemVariable={afterSaveSystemVariable}
        handleClose={hideAddSystemVariableModal}
        open={visibleAddSystemVariableFormModal}
      />
    </div>
  )
}

export default withErrorHandler(withTranslation('common')(observer(AdminSystemVariables)), axios)
