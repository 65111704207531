import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation, withTranslation } from 'react-i18next'
import { PlusCircleIcon } from '@heroicons/react/20/solid'
import SearchResultListNoResults from '../../../components/SearchResultListNoResults/SearchResultListNoResults'
import AdminDiscountRow from './AdminsDiscountRow'
import { CodeDiscount } from '../../../models/CodeDiscount'
import { QuantityDiscount } from '../../../models/QuantityDiscount'
import Spinner from '../../../components/UI/Spinner/Spinner'

type AdminDiscountListProps = {
  discounts?: (CodeDiscount | QuantityDiscount)[]
  isLoading?: boolean
  handleClickDelete: (e, discount) => void
  handleAddDiscount: (e) => void
  handleClickEdit: (e, discount) => void
  handleClickSuspend: (e, discount) => void
  handleClickActivate: (e, discount) => void
}

const AdminDiscountList = ({
  discounts = [],
  isLoading = false,
  handleClickDelete,
  handleAddDiscount,
  handleClickEdit,
  handleClickSuspend,
  handleClickActivate,
}: AdminDiscountListProps) => {
  const { t } = useTranslation()

  if (isLoading) {
    return (
      <div className="container flex justify-center">
        <div className="mt-14">
          <Spinner size={11} color="text-spotted-gold" />
        </div>
      </div>
    )
  }

  if (discounts.length === 0) {
    return (
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className="flex flex-col items-center justify-center my-10">
          <SearchResultListNoResults />
          <div className="bg-teal-100 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md w-1/2 flex justify-center mt-8">
            <div className="flex">
              <span className="font-bold mr-4">{t('Add Discount')}</span>
              <PlusCircleIcon
                className="text-primary-light h-6 w-6 cursor-pointer transition-colors hover:text-primary-dark"
                onClick={handleAddDiscount}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr className="font-bold">
            <th scope="col" className="px-6 py-3 text-left text-xs text-gray-500 uppercase">
              {t('Discount Code')}
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {t('Status')}
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {t('Discount Type')}
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {t('Discount to Applied')}
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {t('Expiry Date')}
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {t('Owner ID')}
            </th>
            <th scope="col" className="relative px-6 py-3 flex justify-end">
              <PlusCircleIcon
                className="text-primary-light h-6 w-6 cursor-pointer transition-colors hover:text-primary-dark"
                onClickCapture={handleAddDiscount}
              />
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {discounts.map((discount, index) => (
            <AdminDiscountRow
              key={index}
              discount={discount}
              handleClickDelete={handleClickDelete}
              handleClickEdit={handleClickEdit}
              handleClickSuspend={handleClickSuspend}
              handleClickActivate={handleClickActivate}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default withTranslation('common')(observer(AdminDiscountList))
