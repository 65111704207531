import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation, withTranslation } from 'react-i18next'

const SearchResultListNoResults = () => {
  const { t } = useTranslation('common')
  return (
    <div className="bg-teal-100 border-gray-500 rounded-b text-gray-900 py-10 shadow-md w-full flex justify-center items-center gap-2">
      <FontAwesomeIcon icon={faMagnifyingGlass} className="text-2xl text-gray-900" />
      <span className="font-bold text-lg">
        {t('No Results were found for your current Search')}
      </span>
    </div>
  )
}

export default withTranslation('common')(SearchResultListNoResults)
