import React from 'react'
import { ToastContainer } from 'react-toastify'
import { observer } from 'mobx-react'

const Layout = (props: any) => {
  return (
    <div>
      <main className="overflow-scroll bg-white h-full">
        {props.children}
        <ToastContainer />
      </main>
    </div>
  )
}

export default observer(Layout)
