import React, { useState, useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import AdminDiscountList from './components/AdminDiscountList'
import { withTranslation } from 'react-i18next'
import BaseDiscountFormModal from './components/DiscountFormModal/BaseDiscountFormModal'
import AdminDiscountsStore from './AdminDiscountsStore'
import DiscountDeleteModal from './components/DiscountFormModal/DiscountDeleteModal'
import StoresContext from '../../providers/storesContext'
import { useQuery } from '../../hooks/useQuery'
import Spinner from '../../components/UI/Spinner/Spinner'
import ResultsPager from '../../components/UI/ResultsPager'
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler'
import axios from '../../services/axios'

const AdminDiscounts = () => {
  const { authStore } = useContext(StoresContext)!
  const query = useQuery()
  const [adminDiscountsStore] = useState(() => new AdminDiscountsStore(authStore))
  const [visibleAddDiscountFormModal, setVisibleAddDiscountFormModal] = useState(false)
  const [editionAdminDiscount, setEditionAdminDiscount] = useState(null)
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)
  const [deletionAdminDiscount, setDeletionAdminDiscount] = useState(null)
  const [deletionAdminDiscountError, setDeletionAdminDiscountError] = useState(null)

  const page = Number(query.get('page')) || 1

  const fetchDiscountsData = async () => {
    await adminDiscountsStore.fetchDiscounts(page)
  }

  const showAddDiscountModal = () => {
    setVisibleAddDiscountFormModal(true)
  }

  const hideAddDiscountModal = () => {
    setVisibleAddDiscountFormModal(false)
    setTimeout(() => setEditionAdminDiscount(null), 300)
  }

  //Delete Discount
  const handleClickDelete = (e, discount) => {
    e.preventDefault()
    e.stopPropagation()
    showDeleteConfirm(discount)
  }

  const showDeleteConfirm = (discount) => {
    setVisibleDeleteModal(true)
    setDeletionAdminDiscount(discount)
  }

  const hideDeleteConfirm = () => {
    setVisibleDeleteModal(false)
    setDeletionAdminDiscountError(null)
    setTimeout(() => setDeletionAdminDiscount(null), 300)
  }

  const handleDeleteAdminDiscount = () => {
    adminDiscountsStore.deleteDiscount(deletionAdminDiscount).then((error) => {
      if (error) {
        setDeletionAdminDiscountError(error)
      } else {
        hideDeleteConfirm()
        fetchDiscountsData()
      }
    })
  }

  //Suspend Discount
  const handleClickSuspend = (e, discount) => {
    e.preventDefault()
    e.stopPropagation()
    adminDiscountsStore.suspendDiscount(discount).then((result) => {
      if (result) {
        fetchDiscountsData()
      }
    })
  }

  //Activate Discount
  const handleClickActivate = (e, discount) => {
    e.preventDefault()
    e.stopPropagation()
    adminDiscountsStore.activateDiscount(discount).then((result) => {
      if (result) {
        fetchDiscountsData()
      }
    })
  }

  //Edit Discount
  const handleClickEdit = (e, discount) => {
    e.preventDefault()
    e.stopPropagation()
    setEditionAdminDiscount(discount)
    showAddDiscountModal()
  }

  useEffect(() => {
    adminDiscountsStore.fetchDiscounts(page).then(() => {})
  }, [page])

  if (adminDiscountsStore.isLoading) {
    return (
      <div className="container flex justify-center h-screen w-screen">
        <div className="mt-32 mr-32 h-16 w-16 ">
          <Spinner size={11} color="text-spotted-gold" />
        </div>
      </div>
    )
  }

  const afterSaveDiscount = () => {
    fetchDiscountsData()
    hideAddDiscountModal()
  }

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <AdminDiscountList
            discounts={adminDiscountsStore.discounts}
            isLoading={adminDiscountsStore.isLoading}
            handleClickDelete={handleClickDelete}
            handleAddDiscount={showAddDiscountModal}
            handleClickEdit={handleClickEdit}
            handleClickSuspend={handleClickSuspend}
            handleClickActivate={handleClickActivate}
          />
          {adminDiscountsStore.discounts.length > 0 && !adminDiscountsStore.isLoading && (
            <ResultsPager paginator={adminDiscountsStore.paginator!} query={query} />
          )}
        </div>
      </div>
      <BaseDiscountFormModal
        editionAdminDiscount={editionAdminDiscount!}
        afterSaveDiscount={afterSaveDiscount}
        handleClose={hideAddDiscountModal}
        open={visibleAddDiscountFormModal}
      />

      <DiscountDeleteModal
        deletionAdminDiscount={deletionAdminDiscount!}
        handleDelete={handleDeleteAdminDiscount}
        handleClose={hideDeleteConfirm}
        open={visibleDeleteModal}
        isDeleting={adminDiscountsStore.isDeleting}
        error={deletionAdminDiscountError}
      />
    </div>
  )
}

export default withErrorHandler(withTranslation('common')(observer(AdminDiscounts)), axios)
