import InputStore from 'components/UI/Forms/InputWrapper/InputStore'
import { makeAutoObservable, runInAction } from 'mobx'
import * as yup from 'yup'
import { DiscountType } from '../../../../models/BaseDiscount'
import { CodeDiscount, CodeDiscountUnit } from '../../../../models/CodeDiscount'
import AuthStore from '../../../../stores/AuthStore'
import DiscountService from '../../../../services/DiscountService'

class DiscountFormModalStore {
  private discountService
  private id: string | null
  public discountName: InputStore<string>
  public discountCode: InputStore<string>
  public type: InputStore<{
    value: DiscountType
    label: string
  }>
  private discountApplied: InputStore<number>
  public expiry: InputStore<Date>
  private unit: InputStore<{
    value: CodeDiscountUnit
    label: string
  }>
  public isLoading: boolean
  public error: any
  private serverError: string
  constructor(private readonly authStore: AuthStore) {
    this.reset()
    makeAutoObservable(this)
    this.discountService = new DiscountService()
  }

  get discountTypes() {
    return [{ label: 'Code', value: DiscountType.PHOTOGRAPH_CODE }]
  }

  get purchaseCodeDiscountUnit() {
    return [
      { label: 'Percentage', value: CodeDiscountUnit.PERCENTAGE },
      { label: 'Currency', value: CodeDiscountUnit.CURRENCY },
    ]
  }

  reset() {
    this.id = null
    this.discountName = new InputStore(yup.string())
    this.discountCode = new InputStore(
      yup.string().required('El codigo del descuento es requerido')
    )
    this.type = new InputStore(yup.string().required('El tipo de descuento es requerido'))
    this.discountApplied = new InputStore(
      yup
        .number()
        .moreThan(0, 'El descuento tiene que ser mayor a 0')
        .positive('El descuento tiene que ser positivo')
        .required('El descuento es requerido')
    )
    this.expiry = new InputStore(
      yup.string().required('La fecha de expiracion del descuento es requerido')
    )
    this.unit = new InputStore(yup.string().required('La unidad del descuento es requerida'))
    this.isLoading = false
    this.error = null
  }

  fillEditionAdminDiscount(discount: CodeDiscount) {
    this.id = discount.id
    this.changeDiscountName(discount.name)
    this.changeDiscountExpiry(discount.expirationDate)
    this.loadType(discount.type)
    this.fillEditionAdminExtraDiscountAttributes(discount)
  }

  fillEditionAdminExtraDiscountAttributes(discount: CodeDiscount) {
    switch (discount.type) {
      case DiscountType.PURCHASE_CODE:
        this.changeDiscountCode(discount.code)
        this.changeDiscountApplied(discount.amount)
        this.loadUnitType(discount.unit)
        break

      default:
        return {}
    }
  }

  loadType(type: DiscountType) {
    this.changeDiscountType({
      value: type,
      label: this.loadTypeLabel(type),
    })
  }

  loadUnitType(unit: CodeDiscountUnit) {
    this.changeDiscountCodeUnit({
      value: unit,
      label: this.loadUnitTypeLabel(unit),
    })
  }

  loadTypeLabel(type: DiscountType) {
    switch (type) {
      case DiscountType.PURCHASE_CODE:
        return 'Código'

      case DiscountType.PURCHASE_QUANTITY:
        return 'Cantidad'

      default:
        return type
    }
  }

  loadUnitTypeLabel(unit: CodeDiscountUnit) {
    switch (unit) {
      case CodeDiscountUnit.CURRENCY:
        return 'Moneda'

      case CodeDiscountUnit.PERCENTAGE:
        return 'Porcentaje'

      default:
        return unit
    }
  }

  changeDiscountName(val: string) {
    this.discountName.setValue(val)
  }

  changeDiscountCode(val: string) {
    this.discountCode.setValue(val.toLowerCase().replace(/ /g, ''))
  }

  changeDiscountExpiry(val: Date) {
    this.expiry.setValue(val)
  }

  changeDiscountApplied(val: number) {
    this.discountApplied.setValue(val)
  }

  changeDiscountType(val: { value: DiscountType; label: string }) {
    this.type.setValue(val)
  }

  changeDiscountCodeUnit(val: { value: CodeDiscountUnit; label: string }) {
    this.unit.setValue(val)
  }

  clearErrors() {
    this.discountName.clearError()
    this.discountCode.clearError()
    this.discountApplied.clearError()
    this.type.clearError()
    this.unit.clearError()
    this.expiry.clearError()
    this.error = null
  }

  async validate() {
    this.clearErrors()
    let isValid = true

    if (!(await this.expiry.validate())) {
      isValid = false
    }

    const now = new Date()
    const expiryDate = new Date(this.expiry.value)
    if (expiryDate < now) {
      this.expiry.error = true
      this.expiry.errorMessage = 'La fecha tiene que ser posterior a hoy'
      isValid = false
    }

    if (!(await this.validateExtraAttributes())) {
      isValid = false
    }

    return isValid
  }

  validateExtraAttributes() {
    switch (this.type.value.value) {
      case DiscountType.PURCHASE_CODE:
        return this.validatePurchaseCodeDiscount()

      default:
        return {}
    }
  }

  async validatePurchaseCodeDiscount() {
    let isValid = true

    if (!(await this.discountCode.validate())) {
      isValid = false
    }

    if (this.unit.value.value === CodeDiscountUnit.PERCENTAGE) {
      const percentageInput = new InputStore(
        yup
          .number()
          .moreThan(0, 'El descuento tiene que ser mayor a 0')
          .positive('El descuento tiene que ser positivo')
          .lessThan(101, 'El descuento tiene que ser menor igual a 100')
          .required('El descuento es requerido')
      )
      percentageInput.setValue(this.discountApplied.value)
      if (!(await percentageInput.validate())) {
        this.discountApplied.error = true
        this.discountApplied.errorMessage = 'El descuento aplicado no es valido'
        isValid = false
      }
    } else {
      if (!(await this.discountApplied.validate())) {
        this.discountApplied.error = true
        this.discountApplied.errorMessage = 'El descuento aplicado no es valido'
        isValid = false
      }
    }

    return isValid
  }

  async createDiscount() {
    if (await this.validate()) {
      runInAction(() => {
        this.isLoading = true
      })

      try {
        const discount = new CodeDiscount()
        discount.name = this.discountName.value
        ;(discount.type = this.type.value.value), (discount.expirationDate = this.expiry.value)
        discount.code = this.discountCode.value
        discount.amount = this.discountApplied.value
        discount.unit = this.unit.value.value
        const newDiscount = await this.discountService.createCodeDiscount(
          this.authStore.getToken(),
          discount
        )

        runInAction(() => {
          this.isLoading = false
        })

        return newDiscount
      } catch (e: any) {
        runInAction(() => {
          const displayedError = this.parseRequestErrors(e.response?.data?.errors || {})

          if (!displayedError) {
            this.serverError = 'Something went wrong, please check the provided data and try again.'
          }

          this.isLoading = false
          if (e?.message) {
            this.error = e?.message
          }
        })
      }
    }

    return false
  }

  async editDiscount() {
    if (!(await this.validate())) {
      return
    }

    runInAction(() => {
      this.isLoading = true
    })

    try {
      const discount = new CodeDiscount()
      discount.id = this.id!
      discount.name = this.discountName.value
      discount.type = this.type.value.value
      discount.expirationDate = this.expiry.value
      discount.code = this.discountCode.value
      discount.amount = this.discountApplied.value
      discount.unit = this.unit.value.value

      const discountUpdated = await this.discountService.editCodeDiscount(
        this.authStore.getToken(),
        discount
      )

      runInAction(() => {
        this.isLoading = false
      })

      return discountUpdated
    } catch (e: any) {
      runInAction(() => {
        this.parseRequestErrors(e.response?.data?.errors || {})
        this.isLoading = false
        if (e?.message) {
          this.error = e?.message
        }
      })
    }

    return false
  }

  parseRequestErrors(messages: any) {
    const keys = Object.keys(messages)
    let displayedError = false

    keys.forEach((key) => {
      const [error] = messages[key]

      switch (key) {
        case 'discountName':
          this.discountName.setError(true, error)
          displayedError = true
          break

        case 'discountCode':
          this.discountCode.setError(true, error)
          displayedError = true
          break

        case 'discounApplied':
          this.discountApplied.setError(true, error)
          displayedError = true
          break

        case 'type':
          this.type.setError(true, error)
          displayedError = true
          break

        case 'expiry':
          this.expiry.setError(true, error)
          displayedError = true
          break

        default:
          break
      }
    })

    return displayedError
  }
}

export default DiscountFormModalStore
