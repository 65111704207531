import { makeAutoObservable, runInAction } from 'mobx'
import { string } from 'yup'
import { toast } from 'react-toastify'
import AuthStore from '../../stores/AuthStore'
import InputStore from '../../components/UI/Forms/InputWrapper/InputStore'

class LoginStore {
  public email: InputStore<string>
  public password: InputStore<string>
  public serverError: any
  constructor(private readonly authStore: AuthStore) {
    this.reset()
    makeAutoObservable(this)
  }

  reset() {
    this.email = new InputStore(
      string().email('Ingrese un formato de email valido').required('El email es requerido')
    )
    this.password = new InputStore(string().required('La contraseña es requerida'))
  }

  changeEmail(val: string) {
    this.email.setValue(val.trim())
  }

  changeLoginPassword(val: string) {
    this.password.setValue(val)
  }

  clearErrors() {
    this.email.clearError()
    this.password.clearError()
  }

  async validateLogin() {
    this.clearErrors()
    let isValid = true

    if (!(await this.email.validate())) {
      isValid = false
    }

    if (!(await this.password.validate())) {
      isValid = false
    }
    return isValid
  }

  async login() {
    if (await this.validateLogin()) {
      try {
        await this.authStore.login(this.email.value, this.password.value)
        return true
      } catch (e: any) {
        runInAction(() => {
          const displayedError = this.parseRequestErrors(e.response?.data?.errors || {})

          if (!displayedError) {
            this.serverError = 'Something went wrong, please check the provided data and try again.'
          }
          toast.warning(displayedError, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
      }
    }

    return false
  }

  parseRequestErrors(messages: any) {
    const keys = Object.keys(messages)
    let displayedError = false

    keys.forEach((key) => {
      const [error] = messages[key]

      switch (key) {
        case 'email':
          this.email.setError(true, error)
          displayedError = true
          break

        case 'password':
          this.password.setError(true, error)
          displayedError = true
          break

        default:
          break
      }
    })

    return displayedError
  }

  get isLoading() {
    return this.authStore.isLoading
  }
}

export default LoginStore
