import Modal, { ModalButtonsDisplay, ModalSize } from 'components/UI/Modal/Modal'
import React from 'react'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { addAccreditationValidationSchema } from './AddAccreditationValidationSchema'
import AccreditationStore from 'containers/Accreditations/AccreditationStore'
import { InputAdornment, TextField } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faUser, faXmark } from '@fortawesome/free-solid-svg-icons'
import Button from 'components/UI/Button'
import { observer } from 'mobx-react'

type AddAccreditationModalProps = {
  store: AccreditationStore
  handleClose: () => void
  open: boolean
}

const AddAccreditationModal = ({ store, handleClose, open }: AddAccreditationModalProps) => {
  const { t } = useTranslation()

  const initialValues = {
    alias: null,
    landingPath: null,
  }

  const formik = useFormik({
    initialValues,
    validationSchema: addAccreditationValidationSchema,
    onSubmit: (values) => {
      const { alias, landingPath } = values
      if (alias && landingPath) {
        store.createAccreditation(alias, landingPath).then((response) => {
          if (response) {
            handleClose()
            store.fetchAdminAccreditations(1)
          }
        })
      } else {
        store.setError(t('Alias or Landing path are missing'))
      }
    },
  })

  const userIcon = <FontAwesomeIcon icon={faUser} className="ml-1 text-xl" />
  const eventIcon = <FontAwesomeIcon icon={faCalendar} className="ml-1 text-xl" />

  return (
    <Modal
      okMessage={t('Save')}
      loadingOk={store.isLoading}
      onCancel={handleClose}
      opened={open}
      buttonsDisplay={ModalButtonsDisplay.NO_BUTTONS}
      modalSize={ModalSize.MEDIUM}
    >
      <div className="flex flex-col gap-2">
        <h1 className="text-lg font-bold text-spotted-light_black mb-4">
          {t('Create accreditation')}
        </h1>
        <form className="flex flex-col gap-4 w-full" onSubmit={formik.handleSubmit}>
          <div className="flex gap-2 items-center w-full">
            <TextField
              required
              fullWidth
              id="alias"
              name="alias"
              label="Alias"
              value={formik.values.alias}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.alias && Boolean(formik.errors.alias)}
              helperText={formik.touched.alias && formik.errors.alias}
              InputProps={{
                startAdornment: <InputAdornment position="start">{userIcon}</InputAdornment>,
              }}
            />
            <TextField
              required
              fullWidth
              id="landing-path"
              name="landingPath"
              label={t('Landing path')}
              value={formik.values.landingPath}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.alias && Boolean(formik.errors.landingPath)}
              helperText={formik.touched.alias && formik.errors.landingPath}
              InputProps={{
                startAdornment: <InputAdornment position="start">{eventIcon}</InputAdornment>,
              }}
            />
          </div>
          {store.error?.response?.data?.message && (
            <p className="w-full p-2 rounded-md bg-spotted-light_grey flex justify-between items-center">
              <span className="text-red-700 text-sm font-medium">
                {store.error?.response?.data?.message}
              </span>
              <FontAwesomeIcon
                icon={faXmark}
                className="mr-1 cursor-pointer"
                onClick={() => store.clearError()}
              />
            </p>
          )}
          <div className="w-full flex justify-end mt-2">
            <Button onClick={formik.handleSubmit} goldInverted extraStyle={'w-min'}>
              {t('Save')}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default observer(AddAccreditationModal)
