import React, { useState, useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import AlbumsStore from './AlbumsStore'
import { withTranslation } from 'react-i18next'
import AdminAlbumList from './components/AlbumList'
import AlbumFormModal from './components/AlbumFormModal'
import SuspendAlbumModal from './components/SuspendAlbumModal'
import { isNil } from 'lodash'
import { useQuery } from '../../hooks/useQuery'
import StoresContext from '../../providers/storesContext'
import Album from '../../models/Album'
import { adminAlbumPhotographsUrl } from '../../routing/Paths'
import Spinner from '../../components/UI/Spinner/Spinner'
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler'
import axios from '../../services/axios'
import ResultsPager from '../../components/UI/ResultsPager'
import { toast } from 'react-toastify'
import AlbumSearchBox from './components/AlbumSearchBox'

const Albums = () => {
  const query = useQuery()
  const navigate = useNavigate()
  const { authStore } = useContext(StoresContext)!
  const [albumStore] = useState(() => new AlbumsStore(authStore))
  const [search, setSearch] = useState<string | undefined>()
  const [openAlbumFormModal, setOpenAlbumFormModal] = useState(false)
  const [albumToEdit, setAlbumToEdit] = useState<Album | null>(null)
  const [openSuspendAlbumModal, setOpenSuspendAlbumModal] = useState(false)
  const [albumToSuspend, setAlbumToSuspend] = useState<Album | null>(null)

  const DEFAULT_PAGE_SIZE = 25

  const page = Number(query.get('page')) || 1

  const goToAlbumsPhotographs = (albumId: string) => {
    navigate(adminAlbumPhotographsUrl(albumId))
  }

  const hideCreateAlbumModal = () => {
    setOpenAlbumFormModal(false)
    // avoids showing undefined name while modal is fading out
    setTimeout(() => setAlbumToEdit(null), 300)
  }

  const afterSaveAlbum = () => {
    albumStore.fetchAlbums(page, DEFAULT_PAGE_SIZE, search)
    hideCreateAlbumModal()
  }

  //Suspend Album
  const handleClickSuspendAlbum = (e: any, album: Album) => {
    e.preventDefault()
    e.stopPropagation()
    setAlbumToSuspend(album)
    setOpenSuspendAlbumModal(true)
  }

  const handleCloseSuspendAlbumModal = () => {
    setAlbumToSuspend(null)
    setOpenSuspendAlbumModal(false)
  }

  const handleSuccessSuspendAlbum = () => {
    if (!isNil(albumToSuspend)) {
      albumStore.deleteAlbum(albumToSuspend.id).then(() => {
        handleCloseSuspendAlbumModal()
        albumStore.fetchAlbums(page, DEFAULT_PAGE_SIZE, search)
      })
    } else {
      toast.error('Album not found')
    }
  }

  //Edit Album
  const handleClickEdit = (e: any, album: Album) => {
    e.preventDefault()
    e.stopPropagation()
    setAlbumToEdit(album)
    setOpenAlbumFormModal(true)
  }

  // Search
  const handleSearch = (textToSearch: string | undefined) => {
    setSearch(textToSearch)
  }

  useEffect(() => {
    albumStore.fetchAlbums(page, DEFAULT_PAGE_SIZE, search)
  }, [page, search])

  if (albumStore.isLoading) {
    return (
      <div className="container flex justify-center h-screen w-screen">
        <div className="mt-32 mr-32 h-16 w-16 ">
          <Spinner size={11} color="text-spotted-gold" />
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col">
      <AlbumSearchBox handleSearch={handleSearch} search={search} />
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <AdminAlbumList
            albums={albumStore.albums}
            isLoading={albumStore.isLoading}
            handleClickEdit={handleClickEdit}
            handleClickSuspend={handleClickSuspendAlbum}
            handleClickToAlbumPhotographs={goToAlbumsPhotographs}
            handleCreateAlbum={() => setOpenAlbumFormModal(true)}
          />
          {albumStore.albums.length > 0 && !albumStore.isLoading && (
            <ResultsPager paginator={albumStore.paginator} query={query} />
          )}
        </div>
      </div>
      {openAlbumFormModal && (
        <AlbumFormModal
          albumToEdit={albumToEdit}
          afterSaveAlbum={afterSaveAlbum}
          handleClose={hideCreateAlbumModal}
          open={openAlbumFormModal}
        />
      )}

      {openSuspendAlbumModal && !isNil(albumToSuspend) && (
        <SuspendAlbumModal
          album={albumToSuspend}
          handleSuspend={handleSuccessSuspendAlbum}
          handleClose={handleCloseSuspendAlbumModal}
          opened={openSuspendAlbumModal}
          isLoading={albumStore.isLoading}
        />
      )}
    </div>
  )
}

export default withErrorHandler(withTranslation('common')(observer(Albums)), axios)
