import axios from 'axios'
import ConfigService, { ConfigKeys } from '../../config'

const instance = axios.create({
  baseURL: ConfigService.getValue(ConfigKeys.API_URL),
})

export const adminApiInstance = axios.create({
  baseURL: ConfigService.getValue(ConfigKeys.ADMIN_API_URL),
})

export default instance
