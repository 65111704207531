import axios, { adminApiInstance } from './axios'
import * as paths from './Paths'
import PurchaseRequest from './Interfaces/Purchase/PurchaseRequest'
import { AxiosResponse } from 'axios'
import { Purchase, PurchaseStatus } from '../models/Purchase'
import { parseAxiosErrorResponse } from '../utility'

type GetAdminPurchasesQueryParams = {
  page?: number
  take?: number
  dateFrom?: Date
  dateTo?: Date
  ownerId?: string
  sellerId?: string
  status?: PurchaseStatus
}

class PurchaseService {
  purchase(purchaseData: PurchaseRequest, token: string): Promise<Purchase> {
    return axios
      .post(paths.PURCHASE, purchaseData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data as Purchase)
      .catch(parseAxiosErrorResponse)
  }

  purchases(token: string, status?: PurchaseStatus): Promise<Purchase[]> {
    let params: { status?: string } = {}
    if (status) {
      params = { status, ...params }
    }
    return axios
      .get(paths.PURCHASE, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  //TODO: Add type
  fetchAdminPurchases(
    token: string,
    page: number,
    take: number,
    dateFrom?: Date,
    dateTo?: Date,
    ownerId?: string,
    sellerId?: string,
    status?: PurchaseStatus
  ) {
    const params: GetAdminPurchasesQueryParams = {
      page,
      take,
      dateFrom,
      dateTo,
      ownerId,
      sellerId,
      status,
    }

    return adminApiInstance
      .get(`${paths.ADMIN_PURCHASES}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: params,
      })
      .then(({ data }) => {
        return {
          purchases: data.data,
          paginator: data.meta,
        }
      })
      .catch(parseAxiosErrorResponse)
  }

  getSellers(token: string) {
    return adminApiInstance
      .get(paths.SELLERS, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        return {
          sellers: data,
        }
      })
      .catch(parseAxiosErrorResponse)
  }

  getOwners(token: string) {
    return adminApiInstance
      .get(paths.OWNERS, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        return {
          owners: data,
        }
      })
      .catch(parseAxiosErrorResponse)
  }

  getRevenue(
    token: string,
    sellerId?: string,
    ownerId?: string,
    dateFrom?: Date,
    dateTo?: Date,
    status?: PurchaseStatus
  ) {
    return adminApiInstance
      .get(paths.SPOTTED_REVENUE, {
        params: {
          ownerId,
          sellerId,
          dateFrom,
          dateTo,
          status,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }: AxiosResponse) => data)
      .catch(parseAxiosErrorResponse)
  }

  getSpottedEarnings(
    token: string,
    ownerId?: string,
    sellerId?: string,
    dateFrom?: Date,
    dateTo?: Date,
    status?: PurchaseStatus
  ) {
    return adminApiInstance
      .get(paths.DASHBOARD_SPOTTED_EARNINGS, {
        params: {
          ownerId,
          sellerId,
          dateFrom,
          dateTo,
          status,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => data)
      .catch(parseAxiosErrorResponse)
  }

  deletePurchase(purchaseId: string, token: string) {
    return axios
      .delete(paths.deletePurchaseById(purchaseId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  approvePurchase(token: string, purchaseId: string) {
    return axios
      .post(paths.approvePurchaseById(purchaseId), purchaseId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  rejectPurchase(token: string, purchaseId: string) {
    return axios
      .post(paths.rejectPurchaseById(purchaseId), purchaseId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  getPhotographersEarnings(
    token: string,
    ownerId?: string,
    sellerId?: string,
    dateFrom?: Date,
    dateTo?: Date,
    status?: PurchaseStatus
  ) {
    return adminApiInstance
      .get(paths.PHOTOGRAPHERS_EARNINGS, {
        params: {
          ownerId,
          sellerId,
          dateFrom,
          dateTo,
          status,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }: AxiosResponse) => data)
      .catch(parseAxiosErrorResponse)
  }
}

export default PurchaseService
