import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation, withTranslation } from 'react-i18next'
import SideBarLink from '../SideBarLink/SideBarLink'
import * as paths from '../../routing/Paths'
import axios from '../../services/axios'
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler'
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/20/solid'

const SideBar = () => {
  const { t } = useTranslation('')
  const [openSideBarButton, setOpenSideBarButton] = useState(false)

  const getNavClasses = () => {
    if (!openSideBarButton) {
      return 'block flex-grow md:block px-4 pb-4 md:pb-0 md:overflow-y-auto'
    }
    return 'hidden '
  }
  return (
    <div className="self-stretch flex-shrink-0 transition-all flex">
      <div className={getNavClasses()}>
        <div className="flex-shrink-0 px-8 py-4 flex flex-row items-center justify-between">
          <a
            href={paths.HOME}
            className="text-lg font-semibold tracking-widest text-gray-900 uppercase rounded-lg dark-mode:text-white focus:outline-none focus:shadow-outline"
          >
            {t('Spotted Admin Panel')}
          </a>
        </div>
        <nav className={getNavClasses()}>
          <SideBarLink label={t('Dashboard')} to={paths.HOME} />
          <SideBarLink label={t('Users')} to={paths.USERS} />
          <SideBarLink label={t('Albums')} to={paths.ALBUMS} />
          <SideBarLink label={t('Locations')} to={paths.LOCATIONS} />
          <SideBarLink label={t('Activities')} to={paths.ACTIVITIES} />
          <SideBarLink label={t('Events')} to={paths.EVENTS} />
          <SideBarLink label={t('Accreditations')} to={paths.ACCREDITATIONS} />
          <SideBarLink label={t('Purchases')} to={paths.PURCHASES} />
          <SideBarLink label={t('Discounts')} to={paths.DISCOUNTS} />
          <SideBarLink label={t('System Variables')} to={paths.SYSTEM_VARIABLES} />
          <SideBarLink label={t('Refereed Users')} to={paths.REFEREED_USERS} />
        </nav>
      </div>
      <button
        onClick={() => setOpenSideBarButton(!openSideBarButton)}
        className="rounded-lg focus:outline-none focus:shadow-outline bg-gray-100"
      >
        <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
          {!openSideBarButton ? (
            <ChevronDoubleLeftIcon title={t('Hide') + ''} />
          ) : (
            <ChevronDoubleRightIcon title={t('Show') + ''} />
          )}
        </svg>
      </button>
    </div>
  )
}

export default withErrorHandler(withTranslation('common')(observer(SideBar)), axios)
