import React, { useContext, useEffect } from 'react'
import { redirect } from 'react-router-dom'
import storesContext from '../../../providers/storesContext'

const Logout = () => {
  const { authStore } = useContext(storesContext)!

  useEffect(() => {
    authStore.logout()
  }, [authStore.getLoggedUser()])

  redirect('/auth')
}

export default Logout
