import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation, withTranslation } from 'react-i18next'
import LocationFormModalStore from './LocationFormModalStore'
import StoresContext from '../../../../providers/storesContext'
import Modal, { ModalButtonsDisplay, ModalSize } from '../../../../components/UI/Modal/Modal'
import ServerError from '../../../../components/UI/ServerError'
import InputWrapper from '../../../../components/UI/Forms/InputWrapper'
import Spinner from '../../../../components/UI/Spinner/Spinner'
import { Location } from '../../../../models/Location'
import { isNil } from 'lodash'

type SpotFormModalType = {
  open: boolean
  handleClose: () => void
  afterSaveSpot: (location: Location) => void
  locationToEdit?: Location | null
}

const LocationFormModal = ({
  open,
  handleClose,
  afterSaveSpot,
  locationToEdit = null,
}: SpotFormModalType) => {
  const { authStore } = useContext(StoresContext)!
  const { t } = useTranslation('common')
  const [spotFormModalStore] = useState(() => new LocationFormModalStore(authStore))

  useEffect(() => {
    if (!open) {
      // waits until modal is hidden to avoid showing empty data / validation errors
      setTimeout(() => spotFormModalStore.reset(), 300)
    }
  }, [open])

  useEffect(() => {
    if (!isNil(locationToEdit)) {
      spotFormModalStore.populateLocationToEdit(locationToEdit)
    }
  }, [locationToEdit])

  const handleChangeSpotName = (val) => spotFormModalStore.changeSpotName(val)
  const handleChangeLocationName = (val) => spotFormModalStore.changeLocationName(val)
  const handleChangeLongitude = (val) => spotFormModalStore.changeLongitude(val)
  const handleChangeLatitude = (val) => spotFormModalStore.changeLatitude(val)

  const { spotName, locationName, longitude, latitude } = spotFormModalStore

  const handleSave = () => {
    if (isNil(locationToEdit)) {
      spotFormModalStore.saveSpot().then((spot) => {
        if (spot) {
          afterSaveSpot(spot)
        }
      })
    } else {
      spotFormModalStore.editSpot().then((spot) => {
        if (spot) {
          afterSaveSpot(spot)
        }
      })
    }
  }

  if (spotFormModalStore.isLoading) {
    return (
      <div className="container flex justify-center">
        <div className="mt-14">
          <Spinner size={11} color="text-spotted-gold" />
        </div>
      </div>
    )
  }

  return (
    <Modal
      okMessage={isNil(locationToEdit) ? t('Add') : t('Save')}
      loadingOk={spotFormModalStore.isLoading}
      onCancel={handleClose}
      onSuccess={handleSave}
      opened={open}
      buttonsDisplay={ModalButtonsDisplay.ONLY_OK_BUTTON}
      modalSize={ModalSize.MEDIUM}
    >
      <div className="w-full grid grid-rows-1 gap-2">
        <div className="text-lg text-gray-800 font-bold text-center">
          {isNil(locationToEdit) ? t('New Spot') : t('Edit Spot')}
        </div>
        {spotFormModalStore.error && <ServerError message={t(spotFormModalStore.error)} />}
        <InputWrapper
          label={t('Spot Name').toString()}
          inputStore={spotName}
          onChange={handleChangeSpotName}
        />
        <InputWrapper
          onChange={handleChangeLatitude}
          inputStore={latitude}
          label={t('Latitude').toString()}
        />
        <InputWrapper
          onChange={handleChangeLongitude}
          inputStore={longitude}
          label={t('Longitude').toString()}
        />
      </div>
    </Modal>
  )
}

export default withTranslation('common')(observer(LocationFormModal))
