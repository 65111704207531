import React from 'react'
import { NoSymbolIcon, PencilSquareIcon } from '@heroicons/react/20/solid'
import limitChars, { formatShortDate } from '../../../utility'
import { observer } from 'mobx-react'
import EventStore from '../EventsStore'
import { Event } from 'models/Event'
import Spinner from 'components/UI/Spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faCircleCheck } from '@fortawesome/free-solid-svg-icons'

type EventRowProps = {
  store: EventStore
  event: Event
  handleClickSuspend: (event: Event) => void
  handleClickEdit: (event: Event) => void
}

const EventRow = ({ store, event, handleClickSuspend, handleClickEdit }: EventRowProps) => {
  if (store.isLoading) {
    return (
      <div className="container flex justify-center">
        <div className="mt-14">
          <Spinner size={11} color="text-spotted-gold" />
        </div>
      </div>
    )
  }

  const trueIcon = (
    <FontAwesomeIcon icon={faCircleCheck} className="text-spotted-succes_dark_green text-lg" />
  )
  const falseIcon = (
    <div className="h-4 w-4 rounded-full flex items-center justify-center bg-spotted-light_black">
      <FontAwesomeIcon icon={faXmark} className="text-xs text-white" />
    </div>
  )
  return (
    <tr className="text-gray-600 transition-colors even:bg-gray-100 odd:white hover:bg-gray-200">
      <td className="px-3 py-4 text-sm font-medium">
        <div className="flex justify-center w-full text-center max-h-36 max-w-30">
          <img loading="lazy" className="" src={event.coverPhotographSrc} alt="Photograph" />
        </div>
      </td>
      <td className="px-3 py-4 text-sm font-medium">{limitChars(event.name, 25)}</td>
      <td className="px-3 py-4 text-sm font-medium">
        {limitChars(event.participants ? event.participants.toString() : '-', 25)}
      </td>
      <td className="px-3 py-4 text-sm font-medium">
        {limitChars(formatShortDate(event.date), 19)}
      </td>
      <td className="px-3 py-4 text-sm font-medium">{limitChars(event.location.spotName, 25)}</td>
      <td className="px-3 py-4 text-sm font-medium">
        {event.defaultImagePrice
          ? limitChars(event.defaultImagePrice + ' ' + event.currency?.toUpperCase(), 8)
          : 'N/A'}
      </td>
      <td className="px-3 py-4 text-sm font-medium">
        {event.defaultPackagePrice
          ? limitChars(event.defaultPackagePrice + ' ' + event.currency?.toUpperCase(), 8)
          : 'N/A'}
      </td>
      <td className="px-3 py-4 text-sm font-medium">
        {event.commission ? limitChars(event.commission + '%', 4) : 'N/A'}
      </td>
      <td className="px-3 py-4 text-sm font-medium">
        {event.quantityDiscountId ? limitChars(event.quantityDiscountId, 25) : 'No'}
      </td>
      <td className="px-3 py-4 text-sm font-medium">{limitChars(event.description, 100)}</td>
      <td className="px-3 py-4 text-sm font-medium">{limitChars(event.activity?.name, 25)}</td>
      <td className="px-3 py-4 text-sm font-medium">
        <div className="flex items-center justify-center">
          {event.isFree ? trueIcon : falseIcon}
        </div>
      </td>
      <td className="px-3 py-4 text-sm font-medium">
        <div className="flex items-center justify-center">
          {event.displayInFeed ? trueIcon : falseIcon}
        </div>
      </td>
      <td className="px-3 py-4 text-sm font-medium">
        <div className="flex items-center justify-center">
          {event.accreditationsEnabled ? trueIcon : falseIcon}
        </div>
      </td>
      <td className="px-3 py-4 text-sm font-medium">
        <div className="flex items-center justify-center">
          {event.customBannerSrc ? trueIcon : falseIcon}
        </div>
      </td>

      <td className="px-3 py-4 text-sm font-medium">
        <div className="flex items-center justify-center">
          <PencilSquareIcon
            className="w-5 h-5 text-gray-500 transition-colors cursor-pointer hover:text-gray-400"
            onClick={() => handleClickEdit(event)}
          />
          {event.isActive && (
            <NoSymbolIcon
              className="w-5 h-5 text-gray-500 transition-colors cursor-pointer hover:text-gray-400"
              onClick={() => handleClickSuspend(event)}
              title="Delete"
            />
          )}
        </div>
      </td>
    </tr>
  )
}

export default observer(EventRow)
