import { makeAutoObservable } from 'mobx'

export interface PaginatorInterface {
  hasNextPage: boolean
  hasPreviousPage: boolean
  itemCount: number
  page: number
  pageCount: number
  take: number
}

export default class Paginator {
  public hasNextPage: boolean
  public hasPreviousPage: boolean
  public itemCount: number
  public page: number
  public pageCount: number
  public take: number

  constructor(values: PaginatorInterface) {
    this.hasNextPage = values.hasNextPage
    this.hasPreviousPage = values.hasPreviousPage
    this.itemCount = values.itemCount
    this.page = values.page
    this.pageCount = values.pageCount
    this.take = values.take

    makeAutoObservable(this)
  }

  setPage(page: number) {
    this.page = page
  }

  static empty() {
    return new Paginator({
      hasNextPage: false,
      hasPreviousPage: false,
      itemCount: 0,
      page: 0,
      pageCount: 0,
      take: 0,
    })
  }
}
