import { Activity } from './Activity'

export class Location {
  id: string

  spotName: string

  latitude: number

  longitude: number

  creatorId?: string

  activities?: Activity[]

  createdAt?: Date

  updatedAt?: Date

  deletedAt?: Date | null
}
