import { BaseDiscount } from './BaseDiscount'

export enum CodeDiscountUnit {
  PERCENTAGE = 'PERCENTAGE',
  CURRENCY = 'CURRENCY',
}

export class CodeDiscount extends BaseDiscount {
  code: string

  amount: number

  unit: CodeDiscountUnit
}
