import React from 'react'
import {
  TrashIcon,
  PencilSquareIcon,
  NoSymbolIcon,
  CheckCircleIcon,
} from '@heroicons/react/20/solid'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import Album from '../../../models/Album'
import limitChars from '../../../utility'

type AlbumRowProps = {
  album: Album
  handleClickEdit: (e: any, album: Album) => void
  handleClickSuspend: (e: any, album: Album) => void
  handleClickToAlbumPhotographs: (albumId: string) => void
}

const AlbumRow = ({
  album,
  handleClickEdit,
  handleClickSuspend,
  handleClickToAlbumPhotographs,
}: AlbumRowProps) => {
  const { t } = useTranslation('common')

  return (
    <tr
      onClick={() => handleClickToAlbumPhotographs(album.id)}
      className="even:bg-gray-100 odd:white text-gray-600 hover:bg-gray-200 transition-colors hover:cursor-pointer"
    >
      <td className="px-6 py-4 text-sm font-medium">{limitChars(album.description, 50)}</td>
      <td className="px-6 py-4 text-sm font-medium">
        {!album.isSuspended ? (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
            {t('Active')}
          </span>
        ) : (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
            {t('Suspended')}
          </span>
        )}
      </td>
      <td className="px-6 py-4 text-sm font-medium">
        {moment.utc(album.takenDate).format('DD.MM.YYYY').toUpperCase()}
      </td>
      <td className="px-6 py-4 text-sm font-medium">$ {album.defaultImagePrice}</td>
      <td className="px-6 py-4 text-sm font-medium">{album.location.spotName}</td>
      <td className="px-6 py-4 text-sm font-medium">{album.owner.alias}</td>

      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium flex justify-end">
        <>
          <PencilSquareIcon
            className="text-gray-500 h-5 w-5 cursor-pointer transition-colors hover:text-gray-400"
            onClick={(e) => handleClickEdit(e, album)}
          />
          {!album.isSuspended && (
            <NoSymbolIcon
              className="text-gray-500 h-5 w-5 cursor-pointer transition-colors hover:text-gray-400"
              onClick={(e) => handleClickSuspend(e, album)}
            />
          )}
        </>
      </td>
    </tr>
  )
}

export default AlbumRow
