import { makeAutoObservable, runInAction } from 'mobx'
import * as yup from 'yup'
import AuthStore from 'stores/AuthStore'
import SystemVariable from '../../../../models/SystemVariable'
import InputStore from '../../../../components/UI/Forms/InputWrapper/InputStore'
import SystemVariableService from '../../../../services/SystemVariableService'

class SystemVariableFormModalStore {
  private authStore: AuthStore
  private id: SystemVariable['id'] | null
  public key: InputStore<string>
  public systemVariableValue: InputStore<string>
  public isLoading: boolean
  public error: string | null
  private systemVariableService: SystemVariableService
  private name: InputStore<string>

  constructor(authStore: AuthStore) {
    this.authStore = authStore
    this.reset()
    makeAutoObservable(this)
    this.systemVariableService = new SystemVariableService()
    this.name = new InputStore(yup.string().required('El nombre es requerido'))
  }

  reset() {
    this.id = null
    this.key = new InputStore(yup.string().required('La clave de la variable es requerida'))
    this.systemVariableValue = new InputStore(
      yup
        .number()
        .required('La variable es requerida')
        .positive('La variable tiene que ser positiva')
        .moreThan(0, 'La variable tiene que ser mayor a 0')
    )
    this.isLoading = false
    this.error = null
  }

  fillEditionAdminSystemVariable(systemVariable: {
    id: string | null
    key: string
    value: string
  }) {
    this.id = SystemVariable['id']
    this.changeSystemVariableKey(systemVariable.key)
    this.changeSystemVariableValue(systemVariable.value)
  }

  changeSystemVariableValue(val: string) {
    this.systemVariableValue.setValue(val)
  }

  changeSystemVariableKey(val: string) {
    this.key.setValue(val)
  }

  clearErrors() {
    this.systemVariableValue.clearError()
    this.error = null
  }

  async validate() {
    this.clearErrors()
    let isValid = true

    if (!(await this.systemVariableValue.validate())) {
      if (
        this.systemVariableValue.errorMessage ===
        'value must be a `number` type, but the final value was: `NaN` (cast from the value `""`).'
      ) {
        this.systemVariableValue.error = true
        this.systemVariableValue.errorMessage = 'El valor es requerido'
      }
      isValid = false
    }

    return isValid
  }

  async editSystemVariable() {
    if (!(await this.validate())) {
      return
    }

    runInAction(() => {
      this.isLoading = true
    })

    try {
      const systemVariableData: SystemVariable = {
        id: this.id,
        key: this.key.value,
        value: this.systemVariableValue.value,
      }

      const systemVariableUpdated = await this.systemVariableService.editSystemVariable(
        this.authStore.getToken(),
        systemVariableData
      )

      runInAction(() => {
        this.isLoading = false
      })

      return systemVariableUpdated
    } catch (e: any) {
      runInAction(() => {
        this.parseRequestErrors(e.response?.data?.errors || {})
        this.isLoading = false
        if (e?.message) {
          this.error = e?.message
        }
      })
    }

    return false
  }

  parseRequestErrors(messages: { [x: string]: [any] }) {
    const keys = Object.keys(messages)
    let displayedError = false

    keys.forEach((key) => {
      const [error] = messages[key]

      switch (key) {
        case 'value':
          this.name.setError(true, error)
          displayedError = true
          break

        default:
          break
      }
    })

    return displayedError
  }
}

export default SystemVariableFormModalStore
