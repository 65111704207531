import { makeAutoObservable, runInAction } from 'mobx'
import AuthStore from 'stores/AuthStore'
import DashboardService from '../../../../services/DashboardService'

class UploadedPhotographsStore {
  public options: { value: number; label: string }[]
  public loading = true
  public days: { value: number }
  public value: string
  public dashboardService: DashboardService
  public authStore: AuthStore

  constructor(options: { value: number; label: string }[], authStore: AuthStore) {
    this.options = options
    this.loading = true
    this.days = options[0]
    makeAutoObservable(this)
    this.dashboardService = new DashboardService()
    this.authStore = authStore
  }

  async updateData(days: { value: number }) {
    this.loading = true
    const data = await this.dashboardService.getUploadedPhotographs(
      days.value,
      this.authStore.getToken()
    )
    runInAction(() => {
      this.value = data.photographs
      this.loading = false
    })
  }

  setDays(days: { value: number }) {
    this.days = days
  }
}

export default UploadedPhotographsStore
