import React from 'react'
import { toUpper } from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  TrashIcon,
  PencilSquareIcon,
  NoSymbolIcon,
  CheckCircleIcon,
} from '@heroicons/react/20/solid'
import { User } from '../../../models/User'
import limitChars from '../../../utility'

type AdminUserRowProps = {
  user: User
  handleClickEdit: (user: User) => void
  handleClickSuspend: (user: User) => void
  handleClickActivate: (user: User) => void
  handleClickDelete: (user: User) => void
}

const AdminUserRow = ({
  user,
  handleClickEdit,
  handleClickSuspend,
  handleClickActivate,
  handleClickDelete,
}: AdminUserRowProps) => {
  const { t } = useTranslation()
  return (
    <tr className="even:bg-gray-100 odd:white text-gray-600 hover:bg-gray-200 transition-colors">
      <td className="px-6 py-4 text-sm font-medium">{limitChars(user.email, 50)}</td>
      <td className="px-6 py-4 text-sm font-medium">
        {limitChars(user.firstName, 25)} {limitChars(user.lastName, 25)}
      </td>
      <td className="px-6 py-4 text-sm font-medium">
        {!user.isSuspended ? (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
            {t('Active')}
          </span>
        ) : (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
            {t('Suspended')}
          </span>
        )}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 capitalize">
        <div className="flex flex-col">
          {user.roles.map((role, index) => (
            <span key={index}>{toUpper(role.type)}</span>
          ))}
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400 capitalize">
        {user.mercadoPagoVerified ? (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
            {t('Active')}
          </span>
        ) : (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
            {t('Inactive')}
          </span>
        )}
      </td>

      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium flex justify-end">
        <>
          <PencilSquareIcon
            className="text-gray-500 h-5 w-5 cursor-pointer transition-colors hover:text-gray-400"
            onClick={() => handleClickEdit(user)}
          />
          {!user.isSuspended ? (
            <NoSymbolIcon
              className="text-gray-500 h-5 w-5 cursor-pointer transition-colors hover:text-gray-400"
              onClick={() => handleClickSuspend(user)}
            />
          ) : (
            <CheckCircleIcon
              className="text-gray-500 h-5 w-5 cursor-pointer transition-colors hover:text-gray-400"
              onClick={() => handleClickActivate(user)}
            />
          )}

          <TrashIcon
            className="text-gray-500 h-5 w-5 cursor-pointer transition-colors hover:text-gray-400"
            onClick={() => handleClickDelete(user)}
          />
        </>
      </td>
    </tr>
  )
}

export default AdminUserRow
