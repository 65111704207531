import React from 'react'
import { CheckIcon, NoSymbolIcon } from '@heroicons/react/20/solid'
import limitChars, { formatDate } from '../../../utility'
import { Accreditation } from 'models/Accreditation'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

type AccreditationRowProps = {
  accreditation: Accreditation
  handleCancelAccreditation: (id: string) => void
  handleApproveAccreditation: (id: string) => void
}

const AccreditationRow = ({
  accreditation,
  handleCancelAccreditation,
  handleApproveAccreditation,
}: AccreditationRowProps) => {
  const { t } = useTranslation()
  return (
    <tr className="even:bg-gray-100 odd:white text-gray-600 hover:bg-gray-200 transition-colors">
      <td className="px-4 py-4 text-sm font-medium text-start">
        {limitChars(accreditation.owner.firstName + ' ' + accreditation.owner.lastName, 25)}
      </td>
      <td className="px-4 py-4 text-sm font-medium text-start">
        {limitChars(accreditation.owner.alias, 25)}
      </td>
      <td className="px-4 py-4 text-sm font-medium text-start">
        {limitChars(accreditation.owner.email, 50)}
      </td>
      <td className="px-4 py-4 text-sm font-medium text-start">
        {limitChars(accreditation.event.name, 30)}
      </td>
      <td className="px-4 py-4 text-sm font-medium text-start">
        {limitChars(formatDate(accreditation.event.date!), 19)}
      </td>
      <td className="px-4 py-4 text-sm font-medium text-start">
        {limitChars(formatDate(accreditation.createdAt!), 19)}
      </td>
      <td className={`px-3 py-4 text-sm text-start `}>
        <span
          className={`px-2 py-1 text-sm leading-5 font-semibold rounded-full ${
            accreditation.isApproved ? ' bg-green-100 text-green-800' : ' bg-red-100 text-red-800'
          }`}
        >
          {accreditation.isApproved ? t('Approved') : t('Canceled')}
        </span>
      </td>
      <td className="px-4 py-4 text-sm font-medium">
        {accreditation.isApproved ? (
          <NoSymbolIcon
            className="text-gray-500 h-5 w-5 cursor-pointer transition-colors hover:text-red-700"
            onClick={() => handleCancelAccreditation(accreditation.id)}
            title="Undo delete"
          />
        ) : (
          <CheckIcon
            className="text-gray-500 h-5 w-5 cursor-pointer transition-colors hover:text-green-700"
            onClick={() => handleApproveAccreditation(accreditation.id)}
            title="Delete"
          />
        )}
      </td>
    </tr>
  )
}

export default observer(AccreditationRow)
