import { makeAutoObservable, runInAction } from 'mobx'
import AuthStore from 'stores/AuthStore'
import DashboardService from '../../../../services/DashboardService'

class AlbumsStore {
  public options: { value: number; label: string }[]
  public loading = true
  public days: { value: number }
  public value: string | null
  private dashboardService: DashboardService
  private authStore: AuthStore

  constructor(options: { value: number; label: string }[], authStore: AuthStore) {
    this.options = options
    this.loading = true
    this.days = options[0]
    this.value = null
    makeAutoObservable(this)
    this.dashboardService = new DashboardService()
    this.authStore = authStore
  }

  async updateData(days: { value: number }) {
    this.loading = true
    const data = await this.dashboardService.getAlbums(days.value, this.authStore.getToken())
    runInAction(() => {
      this.value = data?.albums
      this.loading = false
    })
  }

  formatValue(value: any) {
    return '$' + (value ?? '0.00')
  }

  setDays(days: { value: number }) {
    this.days = days
  }
}

export default AlbumsStore
