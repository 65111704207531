export const HOME = `/*`
export const USERS = `/users`
export const ALBUMS = `/albums`
export const DASHBOARD = `/dashboard`
export const ALBUMS_PHOTOGRAPHS = `/albums/:id/photographs`
export const LOCATIONS = `/locations`
export const ACTIVITIES = `/activities`
export const EVENTS = `/events`
export const PURCHASES = `/purchases`
export const DISCOUNTS = `/discounts`
export const SYSTEM_VARIABLES = `/system-variables`
export const LOGIN = `/login`
export const LOGOUT = `/logout`
export const ACCREDITATIONS = `/accreditations`
export const REFEREED_USERS = '/refereed-users'

export const adminAlbumPhotographsUrl = (id: string) => ALBUMS_PHOTOGRAPHS.replace(':id', id)
