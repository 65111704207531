import { adminApiInstance } from './axios'
import * as paths from '../services/Paths'
import { AxiosResponse } from 'axios'
import { parseAxiosErrorResponse } from '../utility'
import { Accreditation } from 'models/Accreditation'

class AccreditationService {
  fetchAdminAccreditations(token: string, page: number, eventId?: string) {
    const path = eventId
      ? `${paths.ADMIN_ACCREDITATIONS}/search?page=${page}&eventId=${eventId}`
      : `${paths.ADMIN_ACCREDITATIONS}/search?page=${page}`
    return adminApiInstance
      .get(path, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }: AxiosResponse) => {
        return {
          accreditations: data.data,
          paginator: data.meta,
        }
      })
      .catch(parseAxiosErrorResponse)
  }

  fetchEventsWithAccreditationsEnabled(token: string) {
    return adminApiInstance
      .get(paths.ADMIN_EVENTS_WITH_ACCREDITATIONS, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  createAccreditation(alias: string, landingPath: string, token: string): Promise<Accreditation> {
    const data = {
      alias,
      landingPath,
    }
    return adminApiInstance.post(paths.ADMIN_ACCREDITATIONS, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }

  approveAccreditation(token: string, id: string): Promise<void> {
    return adminApiInstance
      .patch(
        paths.approveAccreditationById(id),
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  cancelAccreditation(token: string, id: string): Promise<void> {
    return adminApiInstance
      .patch(
        paths.cancelAccreditationById(id),
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }
}

export default AccreditationService
