import { makeAutoObservable, runInAction } from 'mobx'
import AuthStore from 'stores/AuthStore'
import PurchaseService from 'services/PurchaseService'
import { PurchaseDashboardKpi, PurchaseKpiName } from 'models/PurchaseDashboardKpi'
import { PurchaseStatus } from 'models/Purchase'

class RevenueStore {
  public loading = true
  public kpi: PurchaseDashboardKpi
  public purchaseService: PurchaseService
  private authStore: AuthStore

  constructor(authStore: AuthStore, kpi: PurchaseDashboardKpi) {
    kpi.name = PurchaseKpiName.Revenue
    this.loading = true
    makeAutoObservable(this)
    this.purchaseService = new PurchaseService()
    this.authStore = authStore
    this.kpi = kpi
  }

  async updateData(
    owenerId: string | undefined,
    sellerId: string | undefined,
    dateFrom: Date | undefined,
    dateTo: Date | undefined,
    status: PurchaseStatus | undefined
  ) {
    this.loading = true
    const data = await this.purchaseService.getRevenue(
      this.authStore.getAdminToken(),
      sellerId,
      owenerId,
      dateFrom,
      dateTo,
      status
    )
    runInAction(() => {
      this.kpi.value = this.formatValue(data.revenue)
      this.loading = false
    })
  }

  formatValue(value: { value: string }) {
    return '$' + (value ?? '0.00')
  }
}

export default RevenueStore
