import ConfigInterface from './interfaces/config.interface'

const API_URL = 'https://api.staging.spotted.com.uy'
const ADMIN_API_URL = 'https://admin-api-23hcuwlija-as.a.run.app'
const WEB_URL = 'https://www.staging.lumepic.com'

const staging: ConfigInterface = {
  API_URL,
  ADMIN_API_URL,
  WEB_URL,
}

export default staging
