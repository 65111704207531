import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import AuthService from './services/AuthService'
import AuthStore from './stores/AuthStore'
import { observer } from 'mobx-react'
import StoresContext from './providers/storesContext'
import { BrowserRouter } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import i18next from 'i18next'
import common_en from './translations/en/common.json'
import common_es from './translations/es/common.json'

const initStores = () => {
  const authService = new AuthService()

  const authStore = new AuthStore(authService)

  return {
    authStore,
  }
}

const initTranslations = () => {
  const resources = {
    en: {
      translation: common_en,
    },
    es: {
      translation: common_es,
    },
  }

  i18next.use(LanguageDetector).init({
    resources,
    lng: 'es',
  })
}
initTranslations()
const sharedStores = initStores()

const Main = observer(() => {
  return (
    <StoresContext.Provider value={sharedStores}>
      <BrowserRouter>
        <I18nextProvider i18n={i18next}>
          <App />
        </I18nextProvider>
      </BrowserRouter>
    </StoresContext.Provider>
  )
})
const root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(<Main />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
