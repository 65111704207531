import React from 'react'
import { useTranslation } from 'react-i18next'
import Paginator from '../../../stores/Paginator'
import Pager from '../Pager'

type ResultsPagerProps = {
  paginator: Paginator
  query: URLSearchParams
}

const ResultsPager = ({ paginator = Paginator.empty(), query }: ResultsPagerProps) => {
  const { t } = useTranslation()
  const { pageCount, page, itemCount, take } = paginator

  const skip = (page - 1) * take

  const getFrom = () => {
    return skip + 1
  }

  const to = () => {
    const from = getFrom()
    const to = from + (take - 1)

    if (to > itemCount) {
      return itemCount
    }
    return to
  }

  return (
    <div className="py-3 flex items-center justify-between border-t border-gray-200 w-full">
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            {` ${t('Showing')} `}
            <span className="font-medium">{getFrom()}</span>
            {` ${t('to')} `}
            <span className="font-medium">{to()}</span>
            {` ${t('of')} `}
            <span className="font-medium">{itemCount}</span>
            {` ${t('results')} `}
          </p>
        </div>
        {pageCount > 1 && (
          <div>
            <Pager totalPages={pageCount} currentPage={page} query={query} />
          </div>
        )}
      </div>
    </div>
  )
}

export default ResultsPager
