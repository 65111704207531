import React, { useContext } from 'react'
import Login from './Login'
import { withTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

const Auth = () => {
  return (
    <div className="container-fluid w-screen flex justify-center content-center p-10">
      <Login />
    </div>
  )
}

export default withTranslation('common')(observer(Auth))
