import axios from './axios'
import * as paths from './Paths'
import { AxiosResponse } from 'axios'
import { CodeDiscount } from '../models/CodeDiscount'
import { parseAxiosErrorResponse } from '../utility'

class DiscountService {
  async getDiscountByCode(code: string, sellerId: string, token: string): Promise<CodeDiscount> {
    return axios
      .get(paths.discountByCode(code, sellerId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  //ADMIN REQUESTS

  fetchAdminDiscounts(token: string, page: number) {
    return axios
      .get(`${paths.ADMIN_DISCOUNTS}?page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }: AxiosResponse) => {
        return {
          discounts: data.data,
          paginator: data.meta,
        }
      })
      .catch(parseAxiosErrorResponse)
  }

  createCodeDiscount(token: string, discountData: CodeDiscount) {
    return axios
      .post(paths.DISCOUNT, discountData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  editCodeDiscount(token: string, discountData: CodeDiscount) {
    return axios
      .put(paths.updateDiscountByCode(discountData.id), discountData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  deleteDiscount(discountId: string, token: string) {
    return axios
      .delete(paths.deleteDiscount(discountId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  suspendDiscount(token: string, discountId: string) {
    return axios
      .post(paths.suspendDiscount(discountId), discountId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  activateDiscount(token: string, discountId: string) {
    return axios
      .post(paths.activateDiscount(discountId), discountId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }
}

export default DiscountService
