import moment from 'moment'

export const parseAxiosErrorResponse = (error: any) => {
  if (error.response) {
    if (error.response.status === 401) {
      throw new Error('Debes iniciar sesión.')
    }
    // Request made and server responded
    throw new Error(error.response.data.message)
  } else if (error.request) {
    // The request was made but no response was received
    throw new Error('Service Unavailable')
  } else {
    // Something happened in setting up the request that triggered an Error
    throw new Error(`Unexpected Error: ${JSON.stringify(error)}`)
  }
}

export const formatDate = (date: Date) => {
  return moment.utc(date).format('dddd DD/MM/YYYY').toUpperCase()
}

export const formatShortDate = (date: Date) => {
  return moment.utc(date).format('DD/MM/YYYY').toUpperCase()
}

const limitChars = (value: string, max: number) => {
  return value?.length > max ? `${value.substring(0, max)}...` : value
}

export default limitChars

export function mapStringifyReviver(value: {
  dataType: string
  value: Iterable<readonly [unknown, unknown]> | null | undefined
}) {
  if (typeof value === 'object' && value !== null) {
    if (value.dataType === 'Map') {
      return new Map(value.value)
    }
  }
  return value
}

export function onlyUnique(value, index, array) {
  return array.indexOf(value) === index
}

export function onlyUniqueById(value, index, array) {
  const isDuplicate =
    index ===
    array.findIndex((obj) => {
      return obj.id === value.id
    })
  return isDuplicate
}
