export const PURCHASE = '/purchases'
export const PURCHASED_PHOTOGRAPHS = '/purchases/:id/photographs'
export const fetchPurchase = (purchaseId: string) => {
  return `/purchases/${purchaseId}`
}
export const purchasedPhotographById = (purchaseId: string, photographId: string) => {
  return `/purchases/${purchaseId}/photographs/${photographId}`
}
export const purchasedPhotographDownloadUrl = (purchaseId: string, photographId: string) => {
  return `/purchases/${purchaseId}/photographs/${photographId}/download-url`
}

//ADMIN ROUTES
const prefix = '/purchases'
export const ADMIN_PURCHASES = `${prefix}/admin`
export const SPOTTED_REVENUE = `${ADMIN_PURCHASES}/spotted-revenue`
export const PHOTOGRAPHERS_EARNINGS = `${ADMIN_PURCHASES}/photographer-earnings`
export const DASHBOARD_SPOTTED_EARNINGS = `${ADMIN_PURCHASES}/spotted-earnings`
export const OWNERS = `${ADMIN_PURCHASES}/owners`
export const SELLERS = `${ADMIN_PURCHASES}/sellers`

export const adminPhotographDownloadUrl = (photographId: string) => {
  return `${ADMIN_PURCHASES}/download/${photographId}`
}

export const approvePurchaseById = (purchaseId: string) => {
  return `${prefix}/${purchaseId}/approve`
}
export const rejectPurchaseById = (purchaseId: string) => {
  return `${prefix}/${purchaseId}/reject`
}

export const deletePurchaseById = (purchaseId: string) => {
  return `${prefix}/${purchaseId}`
}
