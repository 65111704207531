import React from 'react'
import { observer } from 'mobx-react'
import { User } from 'models/User'
import limitChars from 'utility'

type RefereedUsersRowProps = {
  user: User
}

const RefereedUsersRow = ({ user }: RefereedUsersRowProps) => {
  return (
    <tr className="even:bg-gray-100 odd:white text-gray-600 hover:bg-gray-200 transition-colors">
      <td className="px-4 py-4 text-sm font-medium text-start">{limitChars(user.alias, 25)}</td>
      <td className="px-4 py-4 text-sm font-medium text-start">
        {limitChars(user.firstName + ' ' + user.lastName, 25)}
      </td>
      <td className="px-4 py-4 text-sm font-medium text-start">{limitChars(user.email, 50)}</td>
    </tr>
  )
}

export default observer(RefereedUsersRow)
