import { makeAutoObservable, runInAction } from 'mobx'
import * as yup from 'yup'
import AuthStore from '../../../../stores/AuthStore'
import InputStore from '../../../../components/UI/Forms/InputWrapper/InputStore'
import LocationService from '../../../../services/LocationService'
import { Location } from '../../../../models/Location'

class LocationFormModalStore {
  public id: string
  public spotName: InputStore<string>
  public locationName: InputStore<string>
  public latitude: InputStore<string>
  public longitude: InputStore<string>
  public isLoading: boolean
  public error: any
  private serverError: string
  private authStore: AuthStore
  private locationService: LocationService
  constructor(authStore) {
    this.authStore = authStore
    this.reset()
    makeAutoObservable(this)
    this.locationService = new LocationService()
  }

  reset() {
    // this.id = null
    this.spotName = new InputStore(yup.string().required('El nombre del spot es requerido'))
    this.latitude = new InputStore(yup.number().required('La latitud es requerida'))
    this.longitude = new InputStore(yup.number().required('La longitud es requerida'))
    this.isLoading = false
    this.error = null
  }

  populateLocationToEdit(spot: Location) {
    this.id = spot.id
    this.changeSpotName(spot.spotName)
    this.changeLatitude(spot.latitude)
    this.changeLongitude(spot.longitude)
  }

  changeSpotName(val) {
    this.spotName.setValue(val)
  }

  changeLocationName(val) {
    this.locationName.setValue(val)
  }

  changeLatitude(val) {
    this.latitude.setValue(val)
  }

  changeLongitude(val) {
    this.longitude.setValue(val)
  }

  isLatitude(latitude) {
    return isFinite(latitude) && Math.abs(latitude) <= 90
  }

  isLongitude(longitude) {
    return isFinite(longitude) && Math.abs(longitude) <= 180
  }

  clearErrors() {
    this.spotName.clearError()
    this.longitude.clearError()
    this.latitude.clearError()
    this.error = null
  }

  async validate() {
    this.clearErrors()
    let isValid = true

    if (!(await this.spotName.validate())) {
      isValid = false
    }

    if (!(await this.longitude.validate()) || !this.isLongitude(this.longitude.value)) {
      this.longitude.error = true
      this.longitude.errorMessage = 'La longitud no es valido'
      isValid = false
    }

    if (!(await this.latitude.validate()) || !this.isLatitude(this.latitude.value)) {
      this.latitude.error = true
      this.latitude.errorMessage = 'La latitud no es valido'
      isValid = false
    }

    return isValid
  }

  async saveSpot() {
    if (await this.validate()) {
      runInAction(() => {
        this.isLoading = true
      })

      try {
        const location: Location = new Location()
        location.spotName = this.spotName.value
        location.latitude = parseFloat(this.latitude.value)
        location.longitude = parseFloat(this.longitude.value)

        const newSpot = await this.locationService.saveLocation(this.authStore.getToken(), location)

        runInAction(() => {
          this.isLoading = false
        })

        return newSpot
      } catch (e: any) {
        runInAction(() => {
          const displayedError = this.parseRequestErrors(e.response?.data?.errors || {})

          if (!displayedError) {
            this.serverError = 'Something went wrong, please check the provided data and try again.'
          }

          this.isLoading = false
        })
      }
    }

    return false
  }

  async editSpot() {
    if (!(await this.validate())) {
      return
    }

    runInAction(() => {
      this.isLoading = true
    })

    try {
      const spotData: Location = {
        id: this.id,
        spotName: this.spotName.value,
        longitude: parseFloat(this.longitude.value),
        latitude: parseFloat(this.latitude.value),
      }

      const spotUpdated = await this.locationService.editLocation(
        this.authStore.getAdminToken(),
        spotData
      )

      runInAction(() => {
        this.isLoading = false
      })

      return spotUpdated
    } catch (e: any) {
      runInAction(() => {
        this.parseRequestErrors(e.response?.data?.errors || {})
        this.isLoading = false
        if (e?.message) {
          this.error = e?.message
        }
      })
    }

    return false
  }

  parseRequestErrors(messages) {
    const keys = Object.keys(messages)
    let displayedError = false

    keys.forEach((key) => {
      const [error] = messages[key]

      switch (key) {
        case 'spotName':
          this.spotName.setError(true, error)
          displayedError = true
          break

        case 'locationName':
          this.locationName.setError(true, error)
          displayedError = true
          break

        case 'longitude':
          this.longitude.setError(true, error)
          displayedError = true
          break

        case 'latitude':
          this.latitude.setError(true, error)
          displayedError = true
          break

        default:
          break
      }
    })

    return displayedError
  }
}

export default LocationFormModalStore
