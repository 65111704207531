import React from 'react'
import Modal, { ModalButtonsDisplay, ModalSize } from '../../../components/UI/Modal/Modal'

type PurchaseDeleteModalProps = {
  handleDelete: any
  handleClose: any
  open: boolean
  isDeleting: boolean
  error: any
  t: any
}

const PurchaseDeleteModal = ({
  handleDelete,
  handleClose,
  open,
  isDeleting = false,
  error,
  t,
}: PurchaseDeleteModalProps) => {
  return (
    <Modal
      onSuccess={handleDelete}
      onCancel={handleClose}
      loadingOk={isDeleting}
      okMessage={t('Delete')}
      opened={open}
      modalSize={ModalSize.SMALL}
      buttonsDisplay={ModalButtonsDisplay.ONLY_OK_BUTTON}
      isDeleteConfirmation
    >
      <>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3 className="text-lg leading-6 font-bold text-gray-800 text-center" id="modal-title">
            {t('Delete Purchase')}
          </h3>
          <div className="mt-4">
            <p className="text-gray-600 text-center font-normal">
              {`${t('Are you sure that you want to delete purchase')}?`}
            </p>
            {error && <p className="text-red-400 text-center font-normal">{`${error}`}</p>}
          </div>
        </div>
      </>
    </Modal>
  )
}

export default PurchaseDeleteModal
