import React from 'react'
import { useTranslation } from 'react-i18next'
import Album from '../../../models/Album'
import Modal, { ModalButtonsDisplay, ModalSize } from '../../../components/UI/Modal/Modal'

type SuspendAlbumModalProps = {
  album: Album
  handleSuspend: () => void
  handleClose: () => void
  opened: boolean
  isLoading: boolean
}

const SuspendAlbumModal = ({
  album,
  handleSuspend,
  handleClose,
  opened,
  isLoading,
}: SuspendAlbumModalProps) => {
  const { t } = useTranslation('common')
  return (
    <Modal
      onSuccess={handleSuspend}
      onCancel={handleClose}
      loadingOk={isLoading}
      okMessage={t('Suspend').toString()}
      isDeleteConfirmation
      opened={opened}
      modalSize={ModalSize.SMALL}
    >
      <>
        <div className="my-3">
          <h3 className="text-lg leading-6 font-bold text-gray-800" id="modal-title">
            {t('Suspend album')}
          </h3>
          <div className="mt-4">
            <p className="text-gray-600 font-normal">
              {`${t('Are you sure that you want to suspend the album:')} 
              "${album?.description}"?`}
            </p>
          </div>
        </div>
      </>
    </Modal>
  )
}

export default SuspendAlbumModal
