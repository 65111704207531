export enum PurchaseKpiName {
  Revenue = 'Revenue',
  SpottedEarnings = 'Spotted Earnings',
  PhotographerEarnings = 'Photographers Earnings',
}

export class PurchaseDashboardKpi {
  name: PurchaseKpiName

  value: string // kpi value

  constructor(name?) {
    this.name = name
  }
}
