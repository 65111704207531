import axios from './axios'
import * as paths from '../services/Paths'
import { AxiosResponse } from 'axios'
import { parseAxiosErrorResponse } from '../utility'
import SystemVariable from '../models/SystemVariable'

class SystemVariableService {
  fetchSystemVariables(token: string, page: number) {
    return axios
      .get(`${paths.ADMIN_SYSTEM_VARIABLES}?page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }: AxiosResponse) => {
        return {
          systemVariables: data.data,
          paginator: data.meta,
        }
      })
      .catch(parseAxiosErrorResponse)
  }

  editSystemVariable(token: string, systemVariableData: SystemVariable) {
    return axios
      .put(paths.updateSystemVariableById(systemVariableData.id), systemVariableData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }
}

export default SystemVariableService
