import React, { useState, useEffect, useContext } from 'react'
import { observer } from 'mobx-react'
import { withTranslation, useTranslation } from 'react-i18next'
import SystemVariableFormModalStore from './SystemVariableFormModalStore'
import StoresContext from '../../../../providers/storesContext'
import SystemVariable from '../../../../models/SystemVariable'
import Modal, { ModalButtonsDisplay, ModalSize } from '../../../../components/UI/Modal/Modal'
import ServerError from '../../../../components/UI/ServerError'
import InputWrapper from '../../../../components/UI/Forms/InputWrapper'
import Spinner from '../../../../components/UI/Spinner/Spinner'

type SystemVariableFormModalProps = {
  open: boolean
  handleClose: () => void
  afterSaveSystemVariable: (systemVariable: SystemVariable) => void
  editionAdminSystemVariable?: any
}

const SystemVariableFormModal = ({
  open,
  handleClose,
  afterSaveSystemVariable,
  editionAdminSystemVariable = null,
}: SystemVariableFormModalProps) => {
  const { authStore } = useContext(StoresContext)!
  const [systemVariableFormModalStore] = useState(() => new SystemVariableFormModalStore(authStore))

  const { t } = useTranslation('common')

  useEffect(() => {
    if (!open) {
      // waits until modal is hidden to avoid showing empty data / validation errors
      setTimeout(() => systemVariableFormModalStore.reset(), 300)
    }
  }, [open])

  useEffect(() => {
    if (editionAdminSystemVariable) {
      systemVariableFormModalStore.fillEditionAdminSystemVariable(editionAdminSystemVariable)
    }
  }, [editionAdminSystemVariable])

  const handleChangeSystemVariableValue = (val: string) =>
    systemVariableFormModalStore.changeSystemVariableValue(val)

  const { key, systemVariableValue } = systemVariableFormModalStore

  const handleSave = () => {
    systemVariableFormModalStore.editSystemVariable().then((systemVariable) => {
      if (systemVariable) {
        afterSaveSystemVariable(systemVariable)
      }
    })
  }

  if (systemVariableFormModalStore.isLoading) {
    return (
      <div className="container flex justify-center h-screen w-screen">
        <div className="mt-32 mr-32 h-16 w-16 ">
          <Spinner size={11} color="text-spotted-gold" />
        </div>
      </div>
    )
  }

  return (
    <Modal
      okMessage={editionAdminSystemVariable === null ? t('Add') : t('Save')}
      loadingOk={systemVariableFormModalStore.isLoading}
      onCancel={handleClose}
      onSuccess={handleSave}
      opened={open}
      buttonsDisplay={ModalButtonsDisplay.ONLY_OK_BUTTON}
      modalSize={ModalSize.SMALL}
    >
      <div className="w-full grid grid-rows-1 gap-2">
        <div className="text-lg text-gray-800 font-bold text-center">
          {editionAdminSystemVariable === null
            ? t('New System Variable')
            : t('Edit System Variable')}
        </div>
        {systemVariableFormModalStore.error && (
          <ServerError message={t(systemVariableFormModalStore.error)} />
        )}
        <InputWrapper
          placeholder={t('Key').toString()}
          inputStore={key}
          label={t('Key').toString()}
          disabled={true}
          onChange={() => {}}
        />
        <InputWrapper
          label={t('Value').toString()}
          inputStore={systemVariableValue}
          onChange={handleChangeSystemVariableValue}
        />
      </div>
    </Modal>
  )
}

export default withTranslation('common')(observer(SystemVariableFormModal))
