import ConfigInterface from './interfaces/config.interface'

const API_URL = 'https://api.staging.spotted.com.uy'
// const API_URL = 'http://localhost:8080'

const ADMIN_API_URL = 'http://localhost:8082'
// const ADMIN_API_URL = 'https://admin-api-23hcuwlija-as.a.run.app'

const WEB_URL = 'https://www.staging.lumepic.com'

const local: ConfigInterface = {
  API_URL,
  ADMIN_API_URL,
  WEB_URL,
}

export default local
