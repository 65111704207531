import { makeAutoObservable, runInAction } from 'mobx'
import * as yup from 'yup'
import moment from 'moment'
import InputStore from '../../../../components/UI/Forms/InputWrapper/InputStore'
import AlbumService from '../../../../services/AlbumService'
import UserService from '../../../../services/UserService'
import AuthStore from '../../../../stores/AuthStore'
import Album from '../../../../models/Album'
import { Location } from '../../../../models/Location'
import { User } from '../../../../models/User'
import { isNil } from 'lodash'
import { Activity } from 'models/Activity'
import { Event } from 'models/Event'

class AlbumFormModalStore {
  public id: string
  public description: InputStore<string>
  public currency?: InputStore<string | null>
  public defaultImagePrice?: InputStore<number | null>
  public defaultPackagePrice?: InputStore<number | null>
  public commission?: InputStore<number | null>
  public takenDate: InputStore<Date>
  public location: InputStore<string>
  public activity: InputStore<string>
  public albumOwner: InputStore<string>
  public eventId?: InputStore<string | null>
  public quantityDiscountId?: InputStore<string | null>
  public isFree: boolean
  public isLoading: boolean
  public error: any
  private readonly albumService: AlbumService
  private readonly userService: UserService
  private albumToEdit: Album
  constructor(private readonly authStore: AuthStore) {
    this.reset()
    makeAutoObservable(this)
    this.albumService = new AlbumService()
    this.userService = new UserService()
  }

  reset() {
    this.description = new InputStore(yup.string().required('La descripcion es requerida'))
    this.currency = new InputStore(yup.string().required())
    this.defaultImagePrice = new InputStore(
      yup.number().positive('El precio debe ser mayor a 0').required()
    )
    this.defaultPackagePrice = new InputStore(
      yup.number().optional().positive('El precio por defecto debe ser positivo')
    )
    this.commission = new InputStore(yup.number().min(0).required())
    this.takenDate = new InputStore(yup.string().required('La fecha del album es requerida'))
    this.location = new InputStore(
      yup.string().typeError('Spot no valido').required('Spot no valido')
    )
    this.activity = new InputStore(
      yup.string().typeError('Actividad no válida').required('Actividad no válida')
    )
    this.albumOwner = new InputStore(
      yup
        .object()
        .shape({
          label: yup.string().required('Usuario no valido'),
          value: yup.string().required('Usuario no valido'),
        })
        .typeError('Usuario no valido')
        .required('Usuario no valido')
    )
    this.eventId = new InputStore(yup.string().optional().nullable())
    this.quantityDiscountId = new InputStore(yup.string().optional())
    this.isFree = false
    this.isLoading = false
    this.error = null
  }

  async populateAlbumToEdit(album: Album) {
    this.id = album.id
    this.changeDescription(album.description)
    if (!isNil(album.commission)) {
      this.changeCommission(album.commission)
    }
    if (!isNil(album.currency)) {
      this.changeCurrency(album.currency)
    }
    if (!isNil(album.defaultImagePrice)) {
      this.changeDefaultImagePrice(album.defaultImagePrice)
    }
    if (!isNil(album.defaultPackagePrice)) {
      this.changeDefaultPackagePrice(album.defaultPackagePrice)
    }
    this.changeTakenDate(moment.utc(album.takenDate).toDate())
    this.changeLocation(album.location)
    this.changeActivity(album.activity)
    this.changeAlbumOwner(album.owner)
    if (!isNil(album.event)) {
      this.changeAlbumEvent(album.event)
    }
    if (!isNil(album.quantityDiscount)) {
      this.changeQuantityDiscountId(album.quantityDiscount.id)
    }
    if (!isNil(album.event)) {
      this.isFree = album.event.isFree
    } else if (!isNil(album.isFree)) {
      this.isFree = album.isFree
    } else {
      this.isFree = false
    }
    this.albumToEdit = await this.albumService.fetchAlbum(this.id!, this.authStore.getToken())
  }

  changeDescription(val: string) {
    this.description.setValue(val)
  }

  changeCommission(val: number) {
    this.commission?.setValue(val)
  }

  changeCurrency(val: string) {
    this.currency?.setValue(val)
  }

  changeDefaultImagePrice(val: number) {
    this.defaultImagePrice?.setValue(val)
  }

  changeDefaultPackagePrice(val: number) {
    this.defaultPackagePrice?.setValue(val)
  }

  changeTakenDate(val: Date) {
    this.takenDate.setValue(val)
  }

  changeLocation(val: Location) {
    this.location.setValue(val.id)
  }

  changeActivity(val: Activity) {
    this.activity.setValue(val.id)
  }

  changeAlbumEvent(val?: Event) {
    if (!isNil(val)) {
      this.eventId?.setValue(val.id)
      this.changeTakenDate(val.date)
      if (!isNil(val.activity)) {
        this.changeActivity(val.activity)
      }
      if (!isNil(val.location)) {
        this.changeLocation(val.location)
      }
      if (!isNil(val.commission)) {
        this.changeCommission(val.commission)
      }
      if (!isNil(val.currency)) {
        this.changeCurrency(val.currency)
      }
      if (!isNil(val.defaultImagePrice)) {
        this.changeDefaultImagePrice(val.defaultImagePrice)
      }
      if (!isNil(val.defaultPackagePrice)) {
        this.changeDefaultPackagePrice(val.defaultPackagePrice)
      }
      if (!isNil(val.quantityDiscountId)) {
        this.changeQuantityDiscountId(val.quantityDiscountId)
      }
      if (!isNil(val.isFree)) {
        this.isFree = val.isFree
        this.defaultImagePrice?.setValue(null)
        this.defaultPackagePrice?.setValue(null)
      } else {
        this.isFree = false
      }
    } else {
      this.eventId?.setValue(null)
    }
  }

  changeAlbumOwner(user: User) {
    this.albumOwner.setValue(user.id)
  }

  changeQuantityDiscountId(val: string) {
    this.quantityDiscountId?.setValue(val)
  }

  changeAlbumIsFree = () => {
    this.isFree = !this.isFree
  }

  clearErrors() {
    this.description.clearError()
    this.currency?.clearError()
    this.commission?.clearError()
    this.defaultImagePrice?.clearError()
    this.defaultPackagePrice?.clearError()
    this.takenDate.clearError()
    this.location.clearError()
    this.activity.clearError()
    this.albumOwner.clearError()
    this.eventId?.clearError()
    this.quantityDiscountId?.clearError()
    this.error = null
  }

  async validate(isEditing?: boolean) {
    this.clearErrors()
    let isValid = true

    const promisesValidation = [
      this.description.validate(),
      this.location.validate(),
      this.activity.validate(),
      this.takenDate.validate(),
    ]

    if (!this.takenDate.value || this.takenDate.value.toString().length <= 0) {
      this.takenDate.error = true
      this.takenDate.errorMessage = 'La fecha no es valida'
      isValid = false
    }

    const now = new Date()
    const takenDateDate = new Date(this.takenDate.value)
    if (takenDateDate > now) {
      this.takenDate.error = true
      this.takenDate.errorMessage = 'La fecha tiene que ser anterior a hoy'
      isValid = false
    }

    if (!this.isFree) {
      if (!isNil(this.defaultImagePrice)) {
        promisesValidation.push(this.defaultImagePrice?.validate())
      }
      if (!isNil(this.defaultPackagePrice)) {
        promisesValidation.push(this.defaultPackagePrice?.validate())
      }
      if (!isNil(this.currency)) {
        promisesValidation.push(this.currency?.validate())
      }
      if (!isNil(this.eventId)) {
        promisesValidation.push(this.eventId?.validate())
      }
      if (!isNil(this.quantityDiscountId)) {
        promisesValidation.push(this.quantityDiscountId?.validate())
      }
    }

    if (!isEditing) {
      if (!(await this.albumOwner.validate())) {
        isValid = false
      }
    }

    const validationResults = await Promise.all(promisesValidation)

    validationResults.forEach((result) => {
      if (!result) {
        isValid = false
      }
    })

    return isValid
  }

  async fetchPhotographerUsers() {
    runInAction(() => {
      this.isLoading = true
    })

    try {
      const photographers = await this.userService.fetchPhotographersUsers(
        this.authStore.getToken()
      )
      runInAction(() => {
        this.isLoading = false
      })
      return photographers
    } catch (e) {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  async saveAlbum() {
    if (await this.validate()) {
      runInAction(() => {
        this.isLoading = true
      })

      const newAlbumData: Album = new Album()
      newAlbumData.description = this.description.value
      newAlbumData.defaultImagePrice = this.defaultImagePrice?.value ?? null
      newAlbumData.defaultPackagePrice = this.defaultPackagePrice?.value ?? null
      newAlbumData.commission = this.commission?.value ?? null
      newAlbumData.currency = this.currency?.value ?? null
      newAlbumData.takenDate = this.takenDate.value
      newAlbumData.locationId = this.location.value
      newAlbumData.activityId = this.activity.value
      newAlbumData.ownerId = this.albumOwner.value
      newAlbumData.eventId = this.eventId?.value ?? null
      newAlbumData.quantityDiscountId = this.quantityDiscountId?.value ?? null
      newAlbumData.isFree = this.isFree
      try {
        const newAlbum = await this.albumService.createAlbum(
          newAlbumData,
          this.authStore.getToken()
        )

        runInAction(() => {
          this.isLoading = false
        })

        return newAlbum
      } catch (e: any) {
        runInAction(() => {
          const displayedError = this.parseRequestErrors(e.response?.data?.errors || {})

          if (!displayedError) {
            this.error = 'Something went wrong, please check the provided data and try again.'
          }

          this.isLoading = false
        })
      }
    }

    return false
  }

  async editAlbum() {
    if (!(await this.validate(true))) {
      return
    }

    runInAction(() => {
      this.isLoading = true
    })
    const newAlbumData: Album = new Album()
    newAlbumData.id = this.id
    newAlbumData.description = this.description.value
    newAlbumData.defaultImagePrice = this.defaultImagePrice?.value ?? null
    newAlbumData.defaultPackagePrice = this.defaultPackagePrice?.value ?? null
    newAlbumData.commission = this.commission?.value ?? null
    newAlbumData.currency = this.currency?.value ?? null
    newAlbumData.takenDate = this.takenDate.value
    newAlbumData.locationId = this.location.value
    newAlbumData.activityId = this.activity.value
    newAlbumData.ownerId = this.albumOwner.value
    newAlbumData.eventId = this.eventId?.value ?? null
    newAlbumData.quantityDiscountId = this.quantityDiscountId?.value ?? null
    newAlbumData.isFree = this.isFree
    try {
      const albumUpdated = await this.albumService.editAlbum(
        this.authStore.getAdminToken(),
        newAlbumData
      )

      runInAction(() => {
        this.isLoading = false
      })

      return albumUpdated
    } catch (e: any) {
      runInAction(() => {
        this.parseRequestErrors(e.response?.data?.errors || {})
        this.isLoading = false
        if (e?.message) {
          this.error = e?.message
        }
      })
    }

    return false
  }

  parseRequestErrors(messages: any) {
    const keys = Object.keys(messages)
    let displayedError = false

    keys.forEach((key) => {
      const [error] = messages[key]

      switch (key) {
        case 'description':
          this.description.setError(true, error)
          displayedError = true
          break

        case 'defaultImagePrice':
          this.defaultImagePrice?.setError(true, error)
          displayedError = true
          break

        case 'takenDate':
          this.takenDate.setError(true, error)
          displayedError = true
          break

        case 'location':
          this.location.setError(true, error)
          displayedError = true
          break

        case 'event':
          this.eventId?.setError(true, error)
          displayedError = true
          break

        default:
          break
      }
    })

    return displayedError
  }
}

export default AlbumFormModalStore
