import React, { useState, useEffect, useContext } from 'react'
import { observer } from 'mobx-react'
import StoresContext from '../../../../providers/storesContext'
import { useTranslation, withTranslation } from 'react-i18next'
import AlbumFormModalStore from './AlbumFormModalStore'
import { User } from '../../../../models/User'
import Modal, { ModalButtonsDisplay, ModalSize } from '../../../../components/UI/Modal/Modal'
import ServerError from '../../../../components/UI/ServerError'
import InputWrapper from '../../../../components/UI/Forms/InputWrapper'
import Spinner from '../../../../components/UI/Spinner/Spinner'
import { Location } from '../../../../models/Location'
import FormSelect from '../../../../components/UI/Forms/FormSelect'
import DatePicker from '../../../../components/UI/Forms/DatePicker'
import Album from '../../../../models/Album'
import { Checkbox } from '@mui/material'
import { isNil } from 'lodash'
import { Activity } from 'models/Activity'
import { Event } from 'models/Event'
import LocationStore from 'containers/Locations/LocationStore'
import ActivityStore from 'containers/Activities/ActivityStore'
import EventStore from 'containers/Events/EventsStore'

type AlbumFormModalProps = {
  open: boolean
  handleClose: () => void
  afterSaveAlbum: () => void
  albumToEdit?: Album | null
}

const AlbumFormModal = ({
  open,
  handleClose,
  afterSaveAlbum,
  albumToEdit,
}: AlbumFormModalProps) => {
  const { t } = useTranslation('common')
  const { authStore } = useContext(StoresContext)!
  const [albumFormModalStore] = useState(() => new AlbumFormModalStore(authStore))
  const [locationStore] = useState(() => new LocationStore(authStore))
  const [activityStore] = useState(() => new ActivityStore(authStore))
  const [eventStore] = useState(() => new EventStore(authStore))

  const [locations, setLocations] = useState<Location[]>([])
  const [activities, setActivities] = useState<Activity[]>([])
  const [events, setEvents] = useState<Event[]>([])

  useEffect(() => {
    loadLocations()
    loadActivities()
    loadEvents()
    if (!albumToEdit) {
      loadPhotographerUsersDropDown()
    }
  }, [])

  useEffect(() => {
    getActivityName()
    getLocationName()
  }, [albumFormModalStore])

  useEffect(() => {
    if (!open) {
      // waits until modal is hidden to avoid showing empty data / validation errors
      setTimeout(() => albumFormModalStore.reset(), 300)
    }
  }, [open])

  useEffect(() => {
    if (albumToEdit) {
      albumFormModalStore.populateAlbumToEdit(albumToEdit)
    }
  }, [albumToEdit])

  const handleChangeDescription = (val: string) => albumFormModalStore.changeDescription(val)
  const handleChangeDefaultImagePrice = (val: number) =>
    albumFormModalStore.changeDefaultImagePrice(val)
  const handleChangeDefaultPackagePrice = (val: number) =>
    albumFormModalStore.changeDefaultPackagePrice(val)
  const handleChangeTakenDate = (val: Date) => albumFormModalStore.changeTakenDate(val)
  const handleChangeLocation = (val: Location) => albumFormModalStore.changeLocation(val)
  const handleChangeActivity = (val: Activity) => albumFormModalStore.changeActivity(val)
  const handleChangeAlbumOwner = (val: User) => albumFormModalStore.changeAlbumOwner(val)
  const handleChangeFreeAlbum = () => {
    albumFormModalStore.changeAlbumIsFree()
  }
  const handleChangeQuantityDiscountId = (val: string) => {
    albumFormModalStore.changeQuantityDiscountId(val)
  }

  const handleChangeEvent = (val) => {
    if (!isNil(val)) {
      const event = events.find((event) => event.id === val.id)
      albumFormModalStore.changeAlbumEvent(event)
    } else {
      albumFormModalStore.changeAlbumEvent()
    }
  }

  const [photographersUsers, setPhotographersUsers] = useState([])

  const {
    description,
    defaultImagePrice,
    defaultPackagePrice,
    takenDate,
    location,
    activity,
    albumOwner,
    quantityDiscountId,
    eventId,
    isFree,
  } = albumFormModalStore

  const loadPhotographerUsersDropDown = async () => {
    const photographers = await albumFormModalStore.fetchPhotographerUsers()
    setPhotographersUsers(photographers)
  }

  const loadLocations = () => {
    locationStore.fetchAllAdminLocations().then((response) => {
      if (!isNil(response)) {
        setLocations(response)
      }
    })
  }

  const loadActivities = () => {
    activityStore.fetchAllAdminActivities().then((response) => {
      if (!isNil(response)) {
        setActivities(response)
      }
    })
  }

  const loadEvents = () => {
    eventStore.searchAdminEvents(1, 100).then((response) => {
      if (!isNil(response)) {
        setEvents(response.events)
      }
    })
  }

  const handleSave = () => {
    if (!albumToEdit) {
      albumFormModalStore.saveAlbum().then((album) => {
        if (album) {
          afterSaveAlbum()
        }
      })
    } else {
      albumFormModalStore.editAlbum().then((response) => {
        if (!isNil(response)) {
          afterSaveAlbum()
        }
      })
    }
  }

  const getLocationName = () => {
    const findLocation = locations.find((l) => l.id === location.value)
    if (findLocation) {
      return findLocation.spotName || ''
    } else {
      return ''
    }
  }

  const getActivityName = () => {
    const findActivity = activities.find((a) => a.id === activity.value)
    if (findActivity) {
      return findActivity.name || ''
    } else {
      return ''
    }
  }
  const getEventName = () => {
    if (events.length > 0) {
      const findEvent = events.find((e) => e.id === eventId?.value)
      if (findEvent) {
        return findEvent.name || ''
      } else {
        return ''
      }
    }
  }

  if (albumFormModalStore.isLoading) {
    return (
      <div className="w-full flex flex-col justify-center">
        <div className="mt-14">
          <Spinner size={11} color="text-spotted-gold" />
        </div>
      </div>
    )
  }

  return (
    <Modal
      okMessage={albumToEdit === null ? t('Add').toString() : t('Save').toString()}
      loadingOk={albumFormModalStore.isLoading}
      onCancel={handleClose}
      onSuccess={handleSave}
      opened={open}
      modalSize={ModalSize.MEDIUM}
      buttonsDisplay={ModalButtonsDisplay.ONLY_OK_BUTTON}
    >
      <div className="w-full grid grid-rows-1 gap-2">
        <div className="text-lg text-gray-800 font-bold text-center">
          {albumToEdit === null ? t('New Album') : t('Edit Album')}
        </div>
        {albumFormModalStore.error && (
          <ServerError message={t(albumFormModalStore.error).toString()} />
        )}
        <InputWrapper
          onChange={handleChangeDescription}
          inputStore={description}
          label={t('Description').toString()}
        />
        <InputWrapper
          onChange={handleChangeTakenDate}
          value={takenDate.value}
          as={DatePicker}
          label={t('Date').toString()}
          inputStore={takenDate}
        />
        <InputWrapper
          onChange={handleChangeEvent}
          placeholder={getEventName()}
          inputStore={eventId}
          as={FormSelect}
          label="Event"
          options={[
            { id: null, label: t('None') },
            ...events.map((event: Event) => ({
              id: event.id,
              label: `${event.name}`,
            })),
          ]}
        />
        <InputWrapper
          onChange={handleChangeLocation}
          placeholder={getLocationName()}
          inputStore={location}
          as={FormSelect}
          label="Spot"
          options={locations.map((location: Location) => ({
            id: location.id,
            label: `${location.spotName}`,
          }))}
        />

        <InputWrapper
          onChange={handleChangeActivity}
          placeholder={getActivityName()}
          inputStore={activity}
          as={FormSelect}
          label="Activity"
          options={activities.map((activity: Activity) => ({
            id: activity.id,
            label: `${activity.name}`,
          }))}
        />

        {isNil(albumToEdit?.event) && (
          <div className="w-1/4 flex items-center gap-1 mt-1">
            <p className="text-sm font-bold text-gray-400">{t('Free Album')}</p>
            <Checkbox onChange={handleChangeFreeAlbum} checked={isFree} />
          </div>
        )}
        {!isFree && (
          <>
            <InputWrapper
              onChange={handleChangeDefaultImagePrice}
              inputStore={defaultImagePrice}
              label={t('Default Image Price').toString()}
              onlyNumeric
            />
            <InputWrapper
              onChange={handleChangeDefaultPackagePrice}
              inputStore={defaultPackagePrice}
              label={t('Default Package Price').toString()}
              onlyNumeric
            />
            <InputWrapper
              onChange={handleChangeQuantityDiscountId}
              inputStore={quantityDiscountId}
              label={t('Quantity Discount Id').toString()}
            />
          </>
        )}

        {albumToEdit ? (
          <InputWrapper
            onChange={handleChangeAlbumOwner}
            placeholder={t('Album Owner').toString()}
            inputStore={albumOwner}
            label={t('Album Owner').toString()}
            disabled={true}
          />
        ) : (
          <InputWrapper
            onChange={handleChangeAlbumOwner}
            placeholder={t('Select user').toString()}
            inputStore={albumOwner}
            as={FormSelect}
            label={t('Album Owner').toString()}
            options={photographersUsers?.map((user: User) => ({
              value: user.id,
              label: `${user.alias}`,
            }))}
          />
        )}
      </div>
    </Modal>
  )
}

export default withTranslation('common')(observer(AlbumFormModal))
