import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation, withTranslation } from 'react-i18next'
import { PlusCircleIcon } from '@heroicons/react/20/solid'
import AdminPhotographRow from './AdminPhotographRow'
import SearchResultListNoResults from '../../../components/SearchResultListNoResults/SearchResultListNoResults'
import { ArrowLeftIcon } from '@heroicons/react/20/solid'
import Album from '../../../models/Album'
import { Photograph } from '../../../models/Photograph'
import Spinner from '../../../components/UI/Spinner/Spinner'
import { useNavigate } from 'react-router-dom'
import { ALBUMS } from '../../../routing/Paths'

type AdminPhotographListProps = {
  album: Album
  photographs: Photograph[]
  isLoading: boolean
  handleClickDownload: (photographId: string) => void
  handleClickEdit: (photograph: Photograph) => void
  handleClickSuspend: (photographId: string) => void
  handleClickActivate: (photographId: string) => void
  handleClickDelete: (photographId: string) => void
}

const AdminPhotographList = ({
  album,
  photographs = [],
  isLoading = false,
  handleClickDownload,
  handleClickEdit,
  handleClickSuspend,
  handleClickActivate,
  handleClickDelete,
}: AdminPhotographListProps) => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const goToAlbums = () => {
    navigate(ALBUMS)
  }

  if (isLoading) {
    return (
      <div className="container flex justify-center">
        <div className="mt-14">
          <Spinner size={11} color="text-spotted-gold" />
        </div>
      </div>
    )
  }

  if (photographs.length === 0) {
    return (
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <ArrowLeftIcon
          className="text-primary-light h-6 w-6 cursor-pointer transition-colors hover:text-primary-dark"
          onClick={goToAlbums}
        />
        <div className="flex flex-col items-center justify-center my-10">
          <SearchResultListNoResults />
          <div className="bg-teal-100 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md w-1/2 flex justify-center mt-8">
            <div className="flex">
              <span className="font-bold mr-4">{t('Add Photograph')}</span>
              <PlusCircleIcon className="text-primary-light h-6 w-6 cursor-pointer transition-colors hover:text-primary-dark" />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="flex py-2 min-w-full">
        <ArrowLeftIcon
          className="text-primary-light h-6 w-6 cursor-pointer transition-colors hover:text-primary-dark"
          onClick={goToAlbums}
        />
        <div className="flex m-auto justify-center">
          <p className="font-bold">{album?.location.spotName}</p>
        </div>
      </div>

      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr className="font-bold">
              <th scope="col" className="px-6 py-3 text-left text-xs text-gray-500 uppercase">
                {t('Photograph')}
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                {t('Status')}
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                {t('Price')}
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                {t('Type')}
              </th>
              <th scope="col" className="relative px-6 py-3 flex justify-end">
                <PlusCircleIcon className="text-primary-light h-6 w-6 cursor-pointer transition-colors hover:text-primary-dark" />
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {photographs.map((photograph, index) => (
              <AdminPhotographRow
                key={index}
                photograph={photograph}
                handleClickDownload={handleClickDownload}
                handleClickDelete={handleClickDelete}
                handleClickEdit={handleClickEdit}
                handleClickSuspend={handleClickSuspend}
                handleClickActivate={handleClickActivate}
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default withTranslation('common')(observer(AdminPhotographList))
