export const GET_ACTIVITIES = '/activities'

//ADMIN ROUTES
const prefix = '/activities'
export const ADMIN_ACTIVITIES = `${prefix}/admin`
export const deleteActivityById = (activityId: string) => {
  return `${prefix}/${activityId}`
}
export const updateActivityById = (activityId: string) => {
  return `${ADMIN_ACTIVITIES}/${activityId}`
}
export const albumsCountByActivity = (activityId: string) => {
  return `${ADMIN_ACTIVITIES}/albums/${activityId}`
}
