export const GET_EVENTS = '/events'

//ADMIN ROUTES
const prefix = '/events'
export const ADMIN_EVENTS = `${prefix}/admin`
export const ADMIN_EVENTS_WITH_ACCREDITATIONS = `${prefix}/admin/accreditationsEnabled`
export const deleteEventById = (eventId: string) => {
  return `${prefix}/${eventId}`
}
export const updateEventById = (eventId: string) => {
  return `${ADMIN_EVENTS}/${eventId}`
}

export const suspendEventById = (eventId: string) => {
  return `${ADMIN_EVENTS}/${eventId}/suspend`
}
