export const PROFILE = '/users/profile'
export const LOGIN = '/auth/login'
export const LOGOUT = '/logout'
export const GOOGLE_LOGIN = '/auth/google/login'
export const REGISTER = '/auth/register'
export const GOOGLE_REGISTER = '/auth/google/register'
export const FORGOT_PASSWORD = 'auth/forgotPassword'
export const RESET_PASSWORD = 'auth/resetPassword'
export const updateUserRoleById = (userId: string) => {
  return `/users/${userId}/roles`
}
export const VALIDATE_MERCADOPAGO_CREDENTIALS = '/mercadopago/credentials'
