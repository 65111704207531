import { observer } from 'mobx-react'
import React, { useState } from 'react'
import PhotographersEarningsStore from '../containers/PhotographersEarnings/PhotographersEarningsStore'
import RevenueStore from '../containers/Revenue/RevenueStore'
import SpottedEarningsStore from '../containers/SpottedEarnings/SpottedEarningsStore'
import Spinner from '../../../components/UI/Spinner/Spinner'
import { useTranslation, withTranslation } from 'react-i18next'

type DashboardValueComponentProps = {
  formStore: PhotographersEarningsStore | RevenueStore | SpottedEarningsStore
}

const DashboardValueComponent = ({ formStore }: DashboardValueComponentProps) => {
  const [store] = useState(formStore)
  const { t } = useTranslation()

  const { loading, kpi } = store

  return (
    <div className="bg-white p-5 rounded-lg">
      <div className="flex flex-row justify-between items-center text-gray-800">
        <div>
          <span>
            <b>{t(kpi.name)}</b>
          </span>
        </div>
      </div>
      <div className={'text-3xl'}>
        <span>
          {loading ? (
            <div className="pt-4">
              <Spinner color="text-primary-light" size={10} />
            </div>
          ) : (
            <b>{kpi.value}</b>
          )}
        </span>
      </div>
    </div>
  )
}

export default withTranslation()(observer(DashboardValueComponent))
