import React, { MouseEventHandler } from 'react'
import { observer } from 'mobx-react'
import { useTranslation, withTranslation } from 'react-i18next'
import { PlusCircleIcon } from '@heroicons/react/20/solid'
import EventRow from './EventRow'
import SearchResultListNoResults from '../../../components/SearchResultListNoResults/SearchResultListNoResults'
import Spinner from '../../../components/UI/Spinner/Spinner'
import { Event } from '../../../models/Event'
import EventStore from '../EventsStore'

type EventListProps = {
  store: EventStore
  handleClickSuspend: (event: Event) => void
  handleClickEdit: (event: Event) => void
  handleAddEvent: MouseEventHandler<any>
}

const EventList = ({
  store,
  handleClickSuspend,
  handleClickEdit,
  handleAddEvent,
}: EventListProps) => {
  const { t } = useTranslation()
  const { events, isLoading } = store

  if (isLoading) {
    return (
      <div className="container flex justify-center">
        <div className="mt-14">
          <Spinner size={11} color="text-spotted-gold" />
        </div>
      </div>
    )
  }

  if (events?.length === 0) {
    return (
      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <div className="flex flex-col items-center justify-center my-10">
          <SearchResultListNoResults />
          <div className="flex justify-center w-1/2 px-4 py-3 mt-8 text-teal-900 bg-teal-100 border-teal-500 rounded-b shadow-md">
            <div className="flex">
              <span className="mr-4 font-bold">{t('Add Event')}</span>
              <PlusCircleIcon
                className="w-6 h-6 transition-colors cursor-pointer text-primary-light hover:text-primary-dark"
                onClick={handleAddEvent}
                title="Add Event"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr className="font-bold">
            <th
              scope="col"
              className="px-3 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
            >
              {t('Cover photo')}
            </th>
            <th scope="col" className="px-3 py-3 text-xs text-left text-gray-500 uppercase">
              {t('Event Name')}
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
            >
              {t('Number of Participants')}
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
            >
              {t('Event Date')}
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
            >
              {t('Location')}
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
            >
              {t('Photo price')}
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
            >
              {t('Package price')}
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
            >
              {t('Commission')}
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
            >
              {t('Discount')}
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
            >
              {t('Description')}
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
            >
              {t('Activity')}
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
            >
              {t('Free Event')}
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
            >
              {t('Display in feed')}
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
            >
              {t('Accreditations enabled')}
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
            >
              {t('Custom banner')}
            </th>
            <th scope="col" className="relative flex justify-end px-3 py-3">
              <PlusCircleIcon
                className="w-6 h-6 transition-colors cursor-pointer text-primary-light hover:text-primary-dark"
                onClick={handleAddEvent}
                title="Add Event"
              />
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {events.map((event, index) => (
            <EventRow
              store={store}
              key={index}
              event={event}
              handleClickSuspend={handleClickSuspend}
              handleClickEdit={handleClickEdit}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default withTranslation('common')(observer(EventList))
