import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { useTranslation, withTranslation } from 'react-i18next'
import { Photograph } from '../../../../models/Photograph'
import Modal, { ModalButtonsDisplay, ModalSize } from '../../../../components/UI/Modal/Modal'
import ServerError from '../../../../components/UI/ServerError'
import InputWrapper from '../../../../components/UI/Forms/InputWrapper'

type EditPhotographModalProps = {
  open: boolean
  store: any
  photograph: Photograph
  handleClose: () => void
  handleEdit: Function
}

const EditPhotographModal = ({
  open,
  handleClose,
  store,
  photograph,
  handleEdit,
}: EditPhotographModalProps) => {
  const { t } = useTranslation('common')
  const handleChangeImagePrice = (val: number): void => store.changePrice(val)

  const onHandleEdit = () => {
    handleEdit(photograph.id)
  }

  useEffect(() => {
    if (open) {
      store.changePrice(photograph?.price)
    } else {
      store.reset()
    }
  }, [open])

  const { price, isSaving } = store

  return (
    <Modal
      onSuccess={onHandleEdit}
      onCancel={handleClose}
      loadingOk={isSaving}
      okMessage={t('Update price').toString()}
      opened={open}
      redCancel
      modalSize={ModalSize.MEDIUM}
      buttonsDisplay={ModalButtonsDisplay.FULL}
    >
      <div className="w-full grid grid-rows-1 gap-2">
        <div className="text-lg text-gray-800 font-bold text-center">{t('Edit Photograph')}</div>
        {store.error && <ServerError message={t(store.error).toString()} />}
        <InputWrapper
          placeholder={t('Enter price').toString()}
          onPressEnter={onHandleEdit}
          inputStore={price}
          onChange={handleChangeImagePrice}
          label={t('Price').toString()}
          onlyNumeric
          autoFocus
        />
      </div>
    </Modal>
  )
}

export default withTranslation('common')(observer(EditPhotographModal))
