import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation, useTranslation } from 'react-i18next'
import AdminSystemVariableRow from './AdminSystemVariableRow'
import SearchResultListNoResults from '../../../components/SearchResultListNoResults/SearchResultListNoResults'
import SystemVariable from '../../../models/SystemVariable'
import Spinner from '../../../components/UI/Spinner/Spinner'

type AdminSystemVariableListProps = {
  systemVariables: SystemVariable[]
  isLoading: boolean
  handleClickEdit: (systemVariable: SystemVariable) => void
  formatSystemVariableKey: (key: string) => string
}

const AdminSystemVariableList = ({
  systemVariables = [],
  isLoading = false,
  handleClickEdit,
  formatSystemVariableKey,
}: AdminSystemVariableListProps) => {
  const { t } = useTranslation()

  if (isLoading) {
    return (
      <div className="container flex justify-center">
        <div className="mt-14">
          <Spinner size={11} color="text-spotted-gold" />
        </div>
      </div>
    )
  }

  if (systemVariables.length === 0) {
    return (
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className="flex flex-col items-center justify-center my-10">
          <SearchResultListNoResults />
        </div>
      </div>
    )
  }

  return (
    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr className="font-bold">
            <th scope="col" className="px-6 py-3 text-left text-xs text-gray-500 uppercase">
              {t('Key')}
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {t('Value')}
            </th>
            <th
              scope="col"
              className="relative px-6 py-3 flex justify-end text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {t('Actions')}
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {systemVariables.map((systemVariable, index) => (
            <AdminSystemVariableRow
              key={index}
              systemVariable={systemVariable}
              handleClickEdit={handleClickEdit}
              formatSystemVariableKey={formatSystemVariableKey}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default withTranslation()(observer(AdminSystemVariableList))
