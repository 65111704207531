import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router-dom'

import SideBar from '../components/SideBar/SideBar'
import {
  USERS,
  HOME,
  ALBUMS,
  ALBUMS_PHOTOGRAPHS,
  LOCATIONS,
  EVENTS,
  PURCHASES,
  DISCOUNTS,
  SYSTEM_VARIABLES,
  ACTIVITIES,
  ACCREDITATIONS,
  REFEREED_USERS,
} from '../routing/Paths'
import AdminUsers from './Users'
import AdminAlbums from './Albums/Albums'
import AdminAlbumsPhotographs from './AdminAlbumsPhotographs/AdminAlbumsPhotographs'
import Locations from './Locations'
import Activities from './Activities'
import AdminPurchases from './Purchases/AdminPurchases'
import Dashboard from './Dashboard'
import AdminDiscounts from './AdminDiscounts/AdminDiscounts'
import AdminSystemVariables from './AdminSystemVariables'
import Events from './Events'
import Accreditations from './Accreditations'
import RefereedUsers from './RefereedUsers/RefereedUsers'

const Admin = () => {
  return (
    <div className="min-h-100 flex flex-grow">
      <div className="flex-grow flex flex-col md:flex-row min-h-full">
        <SideBar />
        <div className="flex-grow p-3 xl:p-5 2xl:p-10 bg-gray-50">
          <div className="p-2 w-full">
            <Routes>
              <Route path={HOME} Component={Dashboard} />
              <Route path={USERS} Component={AdminUsers} />
              <Route path={ALBUMS} Component={AdminAlbums} />
              <Route path={ALBUMS_PHOTOGRAPHS} Component={AdminAlbumsPhotographs} />
              <Route path={LOCATIONS} Component={Locations} />
              <Route path={ACTIVITIES} Component={Activities} />
              <Route path={EVENTS} Component={Events} />
              <Route path={ACCREDITATIONS} Component={Accreditations} />
              <Route path={PURCHASES} Component={AdminPurchases} />
              <Route path={DISCOUNTS} Component={AdminDiscounts} />
              <Route path={SYSTEM_VARIABLES} Component={AdminSystemVariables} />
              <Route path={REFEREED_USERS} Component={RefereedUsers} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTranslation('common')(observer(Admin))
