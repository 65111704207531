import React, { useState } from 'react'
import Modal, { ModalSize } from '../../../components/UI/Modal/Modal'
import { Activity } from '../../../models/Activity'
import { useTranslation } from 'react-i18next'
import Album from 'models/Album'
import AlbumAttributeReassigned from 'components/ActivityOrLocationReassignerAfterDelete/ActivityOrLocationReassignerAfterDelete'
import Spinner from 'components/UI/Spinner'
import { isNil } from 'lodash'

type ActivityDeleteModalProps = {
  activityToDelete: Activity
  handleDelete: (activity: Activity) => void
  handleClose: () => void
  albums: Album[]
  activities: Activity[]
  opened: boolean
  isDeleting: boolean
  error: any
}

const ActivityDeleteModal = ({
  activityToDelete,
  handleDelete,
  handleClose,
  albums,
  activities,
  opened,
  isDeleting = false,
  error,
}: ActivityDeleteModalProps) => {
  const { t } = useTranslation('common')

  const [newActivity, setNewActivity] = useState<Activity>()

  const handleActivityChange = (activity: Activity) => {
    setNewActivity(activity)
  }

  const performDelete = () => {
    if (!isNil(newActivity)) {
      handleDelete(newActivity)
    } else {
      handleDelete(activityToDelete)
    }
  }

  return (
    <Modal
      onSuccess={performDelete}
      onCancel={handleClose}
      loadingOk={isDeleting}
      okMessage={t('Delete').toString()}
      isDeleteConfirmation
      opened={opened}
      modalSize={ModalSize.MEDIUM}
    >
      <>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3 className="text-lg leading-6 font-bold text-gray-800 text-center" id="modal-title">
            {t('Delete Activity')}
          </h3>
          <div className="mt-4">
            <p className="text-gray-600 text-center font-normal">
              {`${t('Are you sure that you want to delete the activity: ')} 
              ${activityToDelete?.name}?`}
            </p>
            {error && <p className="text-red-400 text-center font-normal">{`${error}`}</p>}
          </div>
          {!isNil(albums) && albums?.length > 0 ? (
            <AlbumAttributeReassigned
              albums={albums}
              activities={activities}
              activityToDelete={activityToDelete}
              isDeletingLocation={false}
              onActivityChange={handleActivityChange}
            />
          ) : (
            <div className="container flex justify-center">
              <div className="mt-14">
                <Spinner size={11} color="text-spotted-gold" />
              </div>
            </div>
          )}
        </div>
      </>
    </Modal>
  )
}

export default ActivityDeleteModal
