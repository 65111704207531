export const DISCOUNT = '/discounts'
export const APPLY_DISCOUNT = '/discounts/for-purchase'
export const ALBUMS_QUANTITY_DISCOUNTS = `${DISCOUNT}/quantity-discounts`
export const discountDetail = (discountId: string) => {
  return `discounts/${discountId}`
}
export const discountByCode = (code: string, sellerId: string) => {
  return `discounts/purchase-code/${code}?sellerId=${sellerId}`
}

//ADMIN ROUTES
const prefix = '/discounts'
export const ADMIN_DISCOUNTS = `${prefix}/admin`
export const updateDiscountByCode = (discountCode: string) => {
  return `${prefix}/${discountCode}`
}
export const deleteDiscount = (discountId: string) => {
  return `${prefix}/${discountId}`
}
export const suspendDiscount = (discountId: string) => {
  return `${prefix}/${discountId}/suspend`
}
export const activateDiscount = (discountId: string) => {
  return `${prefix}/${discountId}/activate`
}
