import axios from 'axios'
import withErrorHandler from 'hoc/withErrorHandler/withErrorHandler'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { useQuery } from 'hooks/useQuery'
import storesContext from 'providers/storesContext'
import ResultsPager from 'components/UI/ResultsPager'
import Spinner from 'components/UI/Spinner'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import UserStore from 'containers/Users/UserStore'
import RefereedUsersList from './RefereedUsersList'
import AddRefereedUserModal from './AddRefereedUserModal/AddRefereedUserModal'

type AutoCompleteOption = {
  label: string
  id: string
}

const RefereedUsers = () => {
  const { t } = useTranslation()
  const query = useQuery()
  const { authStore } = useContext(storesContext)!
  const [userStore] = useState(() => new UserStore(authStore))
  const [addRefereedUserModalVisible, setAddRefereedUserModalVisible] = useState<boolean>(false)
  const [userId, setUserId] = useState<string | undefined>(undefined)

  // Add refereed user
  const handleClickAddRefereedUser = () => {
    setAddRefereedUserModalVisible(true)
  }
  const handleCloseAddRefereedUser = () => {
    setAddRefereedUserModalVisible(false)
    userStore.clearError()
  }

  useEffect(() => {
    userStore.fetchReferentUsers().then(() => {
      const referentUserId = userStore.referentUsers[0]?.id
      userStore.fetchRefereedUsers(referentUserId)
    })
  }, [])

  function handleUserDropdownChange(event, value) {
    const userId = value === null ? undefined : value.id
    setUserId(userId)
    userStore.fetchRefereedUsers(userId)
  }

  const userOptions: AutoCompleteOption[] = userStore.referentUsers
    ? userStore.referentUsers.map((user) => {
        return {
          label: `${user.alias.toUpperCase()}`,
          id: user.id,
        }
      })
    : []

  if (userStore.isLoading) {
    return (
      <div className="container flex justify-center h-screen w-screen">
        <div className="mt-32 mr-32 h-16 w-16 ">
          <Spinner size={11} color="text-spotted-gold" />
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="sm:px-6 lg:px-8 py-3">
          <h2 className="text-lg font-bold text-start text-spotted-light_black mb-4">
            {t('Referent Users')}
          </h2>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={userOptions}
            onChange={handleUserDropdownChange}
            defaultValue={userOptions[0]}
            value={userOptions.find((option) => option.id === userId)}
            renderInput={(params) => <TextField {...params} label={t('Referent Users')} />}
          />
        </div>
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <RefereedUsersList store={userStore} handleAddRefereedUser={handleClickAddRefereedUser} />
          {userStore.refereedUsers.length > 0 && !userStore.isLoading && (
            <ResultsPager paginator={userStore.paginator} query={query} />
          )}
        </div>
      </div>
      {addRefereedUserModalVisible && (
        <AddRefereedUserModal
          store={userStore}
          handleClose={handleCloseAddRefereedUser}
          open={addRefereedUserModalVisible}
        />
      )}
    </div>
  )
}

export default withErrorHandler(withTranslation('common')(observer(RefereedUsers)), axios)
