import { makeAutoObservable, runInAction } from 'mobx'
import AuthStore from 'stores/AuthStore'
import DashboardService from '../../../../services/DashboardService'

class AlbumsLocationStore {
  public data: any
  public albumsLocation: any
  public isLoading: boolean
  private authStore: AuthStore
  private dashboardService: DashboardService

  constructor(authStore: AuthStore) {
    this.data = null
    this.albumsLocation = null
    this.isLoading = true

    makeAutoObservable(this)
    this.authStore = authStore
    this.dashboardService = new DashboardService()
  }

  get labels() {
    const chartLabels: string[] = []
    this.albumsLocation.forEach((location: { spotName: string; locationName: string }) => {
      chartLabels.push(`${location.spotName} - ${location.locationName}`)
    })
    return chartLabels
  }

  get dataSets() {
    const dataArray: any[] = []
    this.albumsLocation.forEach((location: { count: any }) => {
      dataArray.push(location.count)
    })
    return dataArray
  }

  setData() {
    this.data = {
      labels: this.labels,
      datasets: [
        {
          label: 'Albums Location',
          data: this.dataSets,
          backgroundColor: ['#093E35', '#065F46', '#059669', '#34D399', '#6EE7B7', '#ECFDF5'],
          borderColor: ['#093E35', '#065F46', '#059669', '#34D399', '#6EE7B7', '#ECFDF5'],
          borderWidth: 1,
        },
      ],
    }
  }

  async fetchData() {
    this.isLoading = true

    try {
      const albumsLocation = await this.dashboardService.getAlbumsLocation(
        this.authStore.getToken()
      )

      runInAction(() => {
        this.albumsLocation = albumsLocation
        this.isLoading = false
      })
      this.setData()
    } catch (e) {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }
}

export default AlbumsLocationStore
