import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation, withTranslation } from 'react-i18next'
import LoginStore from '../LoginStore'
import InputWrapper from '../../../components/UI/Forms/InputWrapper'

type LoginRegisterFormProps = {
  loginRegisterStore: LoginStore
}

const LoginRegisterForm = ({ loginRegisterStore }: LoginRegisterFormProps) => {
  const { t } = useTranslation('common')

  const handleChangeEmail = (val: string) => loginRegisterStore.changeEmail(val)
  const handleChangeLoginPassword = (val: string) => loginRegisterStore.changeLoginPassword(val)

  const { email, password } = loginRegisterStore

  return (
    <div className="d-flex flex-column">
      <InputWrapper
        onChange={handleChangeEmail}
        inputStore={email}
        label="Correo Electrónico"
        placeholder={t('Enter email...').toString()}
      />

      <InputWrapper
        onChange={handleChangeLoginPassword}
        inputStore={password}
        label={t('Password').toString()}
        placeholder={t('Enter Password...').toString()}
        password
      />
    </div>
  )
}

export default withTranslation('common')(observer(LoginRegisterForm))
