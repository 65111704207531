import { makeAutoObservable, runInAction } from 'mobx'
import AuthStore from '../../stores/AuthStore'
import { Location } from '../../models/Location'
import LocationService from '../../services/LocationService'
import Paginator from '../../stores/Paginator'

class LocationStore {
  public locations: Location[]
  public allLocations: Location[]
  public isLoading: boolean
  public isDeleting: boolean
  public error: any
  public paginator: Paginator
  private authStore: AuthStore
  private locationService: LocationService
  constructor(authStore: AuthStore) {
    this.reset()
    makeAutoObservable(this)
    this.locationService = new LocationService()
    this.authStore = authStore
  }

  reset() {
    this.locations = []
    this.isLoading = false
    this.isDeleting = false
    this.error = false
  }

  async fetchAdminLocations(page: number, search?: string) {
    //TODO: search is locationSpotName ?
    runInAction(() => {
      this.isLoading = true
    })
    try {
      const response = await this.locationService.searchAdminLocations(
        //TODO: change search for fetch?
        this.authStore.getAdminToken(),
        page,
        search
      )
      runInAction(() => {
        this.isLoading = false
        this.locations = response.locations
        this.paginator = response.paginator
        this.error = null
      })
      return response
    } catch (e) {
      this.error = e
      this.isLoading = false
    }
  }

  async fetchAllAdminLocations() {
    runInAction(() => {
      this.isLoading = true
    })
    try {
      const response = await this.locationService.fetchLocations(this.authStore.getAdminToken())
      runInAction(() => {
        this.isLoading = false
        this.allLocations = response
        this.error = null
      })
      return response
    } catch (e) {
      this.error = e
      this.isLoading = false
    }
  }

  async mergeLocation(locationToDelete: Location | null, newLocation: Location) {
    runInAction(() => {
      this.isDeleting = true
    })
    try {
      await this.locationService.mergeLocation(
        locationToDelete ? locationToDelete.id : '',
        newLocation.id,
        this.authStore.getAdminToken()
      )
      runInAction(() => {
        this.isDeleting = false
      })
    } catch (e: any) {
      this.error = e
      this.isDeleting = false
      return e?.message
    }
  }

  //TODO: add type to param
  async deleteLocation(locationToDelete) {
    runInAction(() => {
      this.isDeleting = true
    })
    try {
      const numberOfAlbums = await this.locationService.getAlbumsCountByLocation(
        locationToDelete.id,
        this.authStore.getAdminToken()
      )

      if (numberOfAlbums === 0) {
        await this.locationService.deleteLocation(
          locationToDelete.id,
          this.authStore.getAdminToken()
        )
      } else {
        const errorMessage =
          numberOfAlbums > 1
            ? `Cannot delete location ${locationToDelete.spotName}. It has ${numberOfAlbums} albums.`
            : `Cannot delete location ${locationToDelete.spotName}. It has an album.`
        throw new Error(errorMessage)
      }
      runInAction(() => {
        this.isDeleting = false
        this.fetchAdminLocations(0)
      })
    } catch (e: any) {
      this.error = e
      this.isDeleting = false
      return e?.message
    }
  }

  async updateLocation(activityToUpate: Location) {
    try {
      await this.locationService.editLocation(this.authStore.getAdminToken(), activityToUpate)
      runInAction(() => {
        this.fetchAdminLocations(1)
      })
    } catch (e: any) {
      this.error = e
      return e?.message
    }
  }
}

export default LocationStore
