export const GET_LOCATIONS = '/locations'

//ADMIN ROUTES
const prefix = '/locations'
export const ADMIN_LOCATIONS = `${prefix}/admin`
export const deleteLocationById = (locationId: string) => {
  return `${ADMIN_LOCATIONS}/${locationId}`
}
export const updateLocationById = (locationId: string) => {
  return `${ADMIN_LOCATIONS}/${locationId}`
}
export const albumsCountByLocation = (locationId: string) => {
  return `${ADMIN_LOCATIONS}/albums/${locationId}`
}
