import React from 'react'
import {
  TrashIcon,
  PencilSquareIcon,
  NoSymbolIcon,
  CheckCircleIcon,
  ArrowDownTrayIcon,
} from '@heroicons/react/20/solid'
import { useTranslation } from 'react-i18next'
import { Photograph } from '../../../models/Photograph'

type AdminPhotographRowProps = {
  photograph: Photograph
  handleClickDownload: (photographId: string) => void
  handleClickEdit: (photograph: Photograph) => void
  handleClickSuspend: (photographId: string) => void
  handleClickActivate: (photographId: string) => void
  handleClickDelete: (photographId: string) => void
}

const AdminPhotographRow = ({
  photograph,
  handleClickDownload,
  handleClickEdit,
  handleClickSuspend,
  handleClickActivate,
  handleClickDelete,
}: AdminPhotographRowProps) => {
  const { t } = useTranslation('common')
  return (
    <tr className="even:bg-gray-100 odd:white text-gray-600 hover:bg-gray-200 transition-colors">
      <td className="px-6 py-4 text-sm font-medium">
        <div className="w-full text-center flex justify-center max-h-36 max-w-30">
          <img loading="lazy" className="" src={photograph.thumbnailUrl} alt="Photograph" />
        </div>
      </td>
      <td className="px-6 py-4 text-sm font-medium">
        {!photograph.isSuspended ? (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
            {t('Active')}
          </span>
        ) : (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
            {t('Suspended')}
          </span>
        )}
      </td>
      <td className="px-6 py-4 text-sm font-medium">$ {photograph.price}</td>
      <td className="px-6 py-4 text-sm font-medium">{photograph.type}</td>

      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
        <div className="flex justify-center items-center">
          <ArrowDownTrayIcon
            className="text-gray-500 h-5 w-5 cursor-pointer transition-colors hover:text-gray-400"
            onClick={() => handleClickDownload(photograph.id)}
          />
          <PencilSquareIcon
            className="text-gray-500 h-5 w-5 cursor-pointer transition-colors hover:text-gray-400"
            onClick={() => handleClickEdit(photograph)}
          />
          {!photograph.isSuspended ? (
            <NoSymbolIcon
              className="text-gray-500 h-5 w-5 cursor-pointer transition-colors hover:text-gray-400"
              onClick={() => handleClickSuspend(photograph.id)}
            />
          ) : (
            <CheckCircleIcon
              className="text-gray-500 h-5 w-5 cursor-pointer transition-colors hover:text-gray-400"
              onClick={() => handleClickActivate(photograph.id)}
            />
          )}

          <TrashIcon
            className="text-gray-500 h-5 w-5 cursor-pointer transition-colors hover:text-gray-400"
            onClick={() => handleClickDelete(photograph.id)}
          />
        </div>
      </td>
    </tr>
  )
}

export default AdminPhotographRow
