import axios from 'axios'
import withErrorHandler from 'hoc/withErrorHandler/withErrorHandler'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import AccreditationList from './components/AccreditationList'
import { useQuery } from 'hooks/useQuery'
import storesContext from 'providers/storesContext'
import AccreditationStore from './AccreditationStore'
import ResultsPager from 'components/UI/ResultsPager'
import Spinner from 'components/UI/Spinner'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import AddAccreditationModal from './components/AddAccreditationModal/AddAccreditationModal'

type AutoCompleteOption = {
  label: string
  id: string
}

const Accreditations = () => {
  const { t } = useTranslation()
  const query = useQuery()
  const { authStore } = useContext(storesContext)!
  const [accreditationStore] = useState(() => new AccreditationStore(authStore))
  const [eventId, setEventId] = useState<string | undefined>(undefined)
  const [addAccreditationModalVisible, setAddAccreditationModalVisible] = useState<boolean>(false)

  // Add accreditation
  const handleClickAddAccreditation = () => {
    setAddAccreditationModalVisible(true)
  }
  const handleCloseAddAccreditationModal = () => {
    setAddAccreditationModalVisible(false)
    accreditationStore.clearError()
  }

  // Approve accreditation
  const handleApproveAccreditation = (id: string) => {
    accreditationStore.approveAccreditation(id)
  }

  // Cancel accreditation
  const handleCancelAccreditation = (id: string) => {
    accreditationStore.cancelAccreditation(id)
  }

  const page = Number(query.get('page')) || 1

  useEffect(() => {
    accreditationStore.fetchAdminAccreditations(page, eventId)
  }, [page, eventId])

  useEffect(() => {
    accreditationStore.fetchEventsWithAccreditationsEnabled()
  }, [])

  function handleEventDropdownChange(event, value) {
    const eventId = value === null ? undefined : value.id
    setEventId(eventId)
  }

  const eventOptions: AutoCompleteOption[] = accreditationStore.events
    ? accreditationStore.events.map((event) => {
        return {
          label: `${event.name.toUpperCase()}`,
          id: event.id,
        }
      })
    : []

  if (accreditationStore.isLoading) {
    return (
      <div className="container flex justify-center h-screen w-screen">
        <div className="mt-32 mr-32 h-16 w-16 ">
          <Spinner size={11} color="text-spotted-gold" />
        </div>
      </div>
    )
  }

  return (
    <div>
      <h1 className="text-xl font-bold text-spotted-light_black mb-3 text-start uppercase">
        {t('Accreditations')}
      </h1>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="sm:px-6 lg:px-8 py-3">
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={eventOptions}
            onChange={handleEventDropdownChange}
            value={eventOptions.find((option) => option.id === eventId)}
            renderInput={(params) => <TextField {...params} label={t('Events')} />}
          />
        </div>
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <AccreditationList
            store={accreditationStore}
            handleApproveAccreditation={handleApproveAccreditation}
            handleCancelAccreditation={handleCancelAccreditation}
            handleAddAccreditation={handleClickAddAccreditation}
          />
          {accreditationStore.accreditations.length > 0 && !accreditationStore.isLoading && (
            <ResultsPager paginator={accreditationStore.paginator} query={query} />
          )}
        </div>
      </div>
      {addAccreditationModalVisible && (
        <AddAccreditationModal
          store={accreditationStore}
          handleClose={handleCloseAddAccreditationModal}
          open={addAccreditationModalVisible}
        />
      )}
    </div>
  )
}

export default withErrorHandler(withTranslation('common')(observer(Accreditations)), axios)
