import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { authenticatedRoutes, unAuthenticatedRoutes, ApplicationRoute } from './Routes'

type RouterProps = {
  isLoggedIn?: boolean
}

const Router = ({ isLoggedIn = false }: RouterProps) => {
  let routes = unAuthenticatedRoutes
  if (isLoggedIn) {
    routes = authenticatedRoutes
  }
  return (
    <>
      <Routes>
        {routes.map((route: ApplicationRoute) => (
          <Route
            Component={(props) => {
              const Component = route.component

              return <Component {...props} />
            }}
            path={route.path}
            key={route.path}
          />
        ))}
        {!isLoggedIn && <Route path="/" element={<Navigate to="/login" />} />}
      </Routes>
    </>
  )
}

export default Router
