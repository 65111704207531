import React, { useState, useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useQuery } from '../../hooks/useQuery'
import StoresContext from '../../providers/storesContext'
import LocationStore from './LocationStore'
import Spinner from '../../components/UI/Spinner/Spinner'
import LocationList from './components/LocationList'
import { Location } from '../../models/Location'
import ResultsPager from '../../components/UI/ResultsPager'
import LocationFormModal from './components/LocationFormModal/LocationFormModal'
import LocationDeleteModal from './components/LocationDeleteModal'
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler'
import { withTranslation } from 'react-i18next'
import axios from '../../services/axios'
import AdminAlbumsStore from 'containers/Albums/AlbumsStore'
import LocationSearchBox from './components/LocationSearchBox'
import { isNil } from 'lodash'

const Locations = () => {
  const query = useQuery()
  const { authStore } = useContext(StoresContext)!
  const [locationStore] = useState(() => new LocationStore(authStore))
  const [albumStore] = useState(() => new AdminAlbumsStore(authStore))
  const [search, setSearch] = useState<string | undefined>()
  const [visibleAddSpotFormModal, setVisibleAddSpotFormModal] = useState(false)
  const [locationToEdit, setLocationToEdit] = useState<Location | null>(null)
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)
  const [locationToDelete, setLocationToDelete] = useState<Location | null>(null)
  const [deletionAdminSpotError, setDeletionAdminSpotError] = useState<string>()

  const page = Number(query.get('page')) || 1

  const hideAddSpotModal = () => {
    setVisibleAddSpotFormModal(false)
    // avoids showing undefined name while modal is fading out
    setTimeout(() => setLocationToEdit(null), 300)
  }

  const afterSaveSpot = () => {
    locationStore.fetchAdminLocations(page)
    hideAddSpotModal()
  }

  //Delete Spot
  const handleClickDelete = (location: Location) => {
    albumStore.reset()
    handleGetAlbums(location)
    showDeleteConfirm(location)
  }

  //Delete Spot
  const handleUndoDelete = (location: Location) => {
    location.deletedAt = null
    locationStore.updateLocation(location).then((error) => {
      if (error) {
        setDeletionAdminSpotError(error)
      } else {
        hideDeleteConfirm()
        locationStore.fetchAdminLocations(page)
      }
    })
  }

  //Get locations albums
  const handleGetAlbums = (location: Location) => {
    albumStore.fetchAdminAlbumsByLocationId(location.id, 1)
  }

  const showDeleteConfirm = (location: Location) => {
    setVisibleDeleteModal(true)
    setLocationToDelete(location)
  }

  const hideDeleteConfirm = () => {
    setVisibleDeleteModal(false)
    setDeletionAdminSpotError('')
    // avoids showing undefined name while modal is fading out
    setTimeout(() => setLocationToDelete(null), 300)
  }

  const handleDeleteAdminLocation = (location: Location) => {
    if (!isNil(location)) {
      locationStore.mergeLocation(locationToDelete, location).then((error) => {
        if (error) {
          setDeletionAdminSpotError(error)
        } else {
          hideDeleteConfirm()
          locationStore.fetchAdminLocations(page)
        }
      })
    } else {
      if (albumStore.albums?.length > 0) {
        setDeletionAdminSpotError('Please select a location to transfer the albums')
      } else {
        locationStore.deleteLocation(locationToDelete).then((error) => {
          if (error) {
            setDeletionAdminSpotError(error)
          } else {
            hideDeleteConfirm()
            locationStore.fetchAllAdminLocations().then(() => {})
            locationStore.fetchAdminLocations(page)
          }
        })
      }
    }
  }

  //Edit Spot
  const handleClickEdit = (location: Location) => {
    setLocationToEdit(location)
    setVisibleAddSpotFormModal(true)
  }

  //TODO: textToSearch change to locationSpotName ??
  const handleSearch = (textToSearch: string | undefined) => {
    locationStore.fetchAdminLocations(1, textToSearch)
    setSearch(textToSearch)
  }

  useEffect(() => {
    //TODO: search change to locationSpotName ??
    if (search) locationStore.fetchAdminLocations(page, search).then(() => {})
    else locationStore.fetchAdminLocations(page).then(() => {})

    locationStore.fetchAllAdminLocations().then(() => {})
  }, [page])

  if (locationStore.isLoading) {
    return (
      <div className="container flex justify-center h-screen w-screen">
        <div className="mt-32 mr-32 h-16 w-16 ">
          <Spinner size={11} color="text-spotted-gold" />
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col">
      <LocationSearchBox handleSearch={handleSearch} search={search} />
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <LocationList
            locations={locationStore.locations}
            albums={albumStore.albums}
            isLoading={locationStore.isLoading}
            handleClickEdit={handleClickEdit}
            handleClickDelete={handleClickDelete}
            handleUndoDelete={handleUndoDelete}
            handleGetAlbums={handleGetAlbums}
            handleAddLocation={() => setVisibleAddSpotFormModal(true)}
          />
          {locationStore.locations.length > 0 && !locationStore.isLoading && (
            <ResultsPager paginator={locationStore.paginator} query={query} />
          )}
        </div>
      </div>
      <LocationFormModal
        locationToEdit={locationToEdit}
        afterSaveSpot={afterSaveSpot}
        handleClose={hideAddSpotModal}
        open={visibleAddSpotFormModal}
      />
      <LocationDeleteModal
        locationToDelete={locationToDelete!}
        locations={locationStore.allLocations}
        albums={albumStore.albums}
        handleDelete={handleDeleteAdminLocation}
        handleClose={hideDeleteConfirm}
        opened={visibleDeleteModal}
        isDeleting={locationStore.isDeleting}
        error={deletionAdminSpotError}
      />
    </div>
  )
}

export default withErrorHandler(withTranslation('common')(observer(Locations)), axios)
