import * as paths from '../Paths'
import Admin from '../../containers/Admin'
import Auth from '../../containers/Auth/Auth'
import Logout from '../../containers/Auth/Logout/Logout'

export type ApplicationRoute = {
  path: string
  name: string
  component: any
}

export const authenticatedRoutes: ApplicationRoute[] = [
  {
    path: paths.HOME,
    name: 'home',
    component: Admin,
  },
  {
    path: paths.LOGIN,
    name: 'login',
    component: Auth,
  },
  {
    path: paths.LOGOUT,
    name: 'login',
    component: Logout,
  },
]

export const unAuthenticatedRoutes: ApplicationRoute[] = [
  {
    path: paths.HOME,
    name: 'login',
    component: Auth,
  },
]
