import React, { useState } from 'react'
import FormSelect from 'components/UI/Forms/FormSelect'
import InputWrapper from 'components/UI/Forms/InputWrapper'
import { Activity } from 'models/Activity'
import Album from 'models/Album'
import { Location } from 'models/Location'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

type ActivityOrLocationReassignerAfterDelete = {
  albums: Album[]
  locations?: Location[]
  locationToDelete?: Location
  activities?: Activity[]
  activityToDelete?: Activity
  isDeletingLocation?: boolean
  onActivityChange?: (activity: Activity) => void
  onLocationChange?: (location: Location) => void
}

const ActivityOrLocationReassignerAfterDelete = ({
  albums,
  locations,
  locationToDelete,
  activities,
  activityToDelete,
  isDeletingLocation,
  onActivityChange,
  onLocationChange,
}: ActivityOrLocationReassignerAfterDelete) => {
  const { t } = useTranslation('common')

  const [newLocation, setNewLocation] = useState<Location>()
  const [newActivity, setNewActivity] = useState<Activity>()

  const handleChangeActivity = (activity: Activity) => {
    setNewActivity(activity)
    onActivityChange?.(activity)
  }

  const handleChangeLocation = (location: Location) => {
    setNewLocation(location)
    onLocationChange?.(location)
  }

  return (
    <>
      <div className="w-full flex flex-col justify-center content-center">
        <h1 className="text-gray-600 text-center font-normal">
          {isDeletingLocation ? (
            <b>{t('Albums related to location')} </b>
          ) : (
            <b>{t('Albums related to activity')} </b>
          )}
        </h1>
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr className="font-bold">
                <th scope="col" className="px-6 py-3 text-left text-xs text-gray-500 uppercase">
                  {t('Album Description')}
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {t('Status')}
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {t('Take date')}
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {!isDeletingLocation ? <b>{t('Activity name')}</b> : <b>{t('Spot name')}</b>}
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {t('Owner name')}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {albums.map((album) => (
                <>
                  <tr className="even:bg-gray-100 odd:white text-gray-600 hover:bg-gray-200 transition-colors hover:cursor-pointer">
                    <td className="px-6 py-4 text-sm font-medium"> {album.description} </td>
                    <td className="px-6 py-4 text-sm font-medium">
                      {!album.isSuspended ? (
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                          {t('Active')}
                        </span>
                      ) : (
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                          {t('Suspended')}
                        </span>
                      )}
                    </td>
                    <td className="px-6 py-4 text-sm font-medium">
                      {moment.utc(album.takenDate).format('DD.MM.YYYY').toUpperCase()}
                    </td>
                    {isDeletingLocation ? (
                      <td className="px-6 py-4 text-sm font-medium"> {album.location.spotName} </td>
                    ) : (
                      <td className="px-6 py-4 text-sm font-medium"> {album.activity.name} </td>
                    )}

                    <td className="px-6 py-4 text-sm font-medium">
                      {album.owner.firstName} {album.owner.lastName}
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <InputWrapper
        as={FormSelect}
        label={isDeletingLocation ? t('Location').toString() : t('Activity').toString()}
        onChange={isDeletingLocation ? handleChangeLocation : handleChangeActivity}
        options={
          isDeletingLocation
            ? locations
                ?.filter((location: Location) => location.id !== locationToDelete?.id)
                .map((location: Location) => ({
                  id: location.id,
                  label: `${location.spotName}`,
                }))
            : activities
                ?.filter((activity: Activity) => activity.id !== activityToDelete?.id)
                .map((activity: Activity) => ({
                  id: activity.id,
                  label: `${activity.name}`,
                }))
        }
      />
    </>
  )
}

export default ActivityOrLocationReassignerAfterDelete
