import { makeAutoObservable, runInAction } from 'mobx'
import AuthStore from 'stores/AuthStore'
import DashboardService from '../../../../services/DashboardService'
import { ChartData } from 'chart.js'

class UsersTypeRoleStore {
  data: ChartData<'doughnut'>
  userRoleTypeCountDataset: { count: number; type: string }[]
  isLoading: boolean
  authStore: AuthStore
  dashboardService: DashboardService

  constructor(authStore: AuthStore) {
    this.data = { labels: [], datasets: [] }
    this.userRoleTypeCountDataset = []
    this.isLoading = true

    makeAutoObservable(this)
    this.authStore = authStore
    this.dashboardService = new DashboardService()
  }

  get labels() {
    const chartLabels: string[] = []
    this.userRoleTypeCountDataset.forEach((typeRole) => {
      chartLabels.push(typeRole.type)
    })
    return chartLabels
  }

  get dataSets() {
    const dataArray: any[] = []
    this.userRoleTypeCountDataset.forEach((typeRole) => {
      dataArray.push(typeRole.count)
    })
    return dataArray
  }

  setData() {
    this.data = {
      labels: this.labels,
      datasets: [
        {
          label: 'Users Type Role',
          data: this.dataSets,
          backgroundColor: ['#2F4E73', '#99BDDC', '#C5E6EF'],
          borderColor: ['#2F4E73', '#99BDDC', '#C5E6EF'],
          borderWidth: 1,
        },
      ],
    }
  }

  async fetchData() {
    this.isLoading = true

    try {
      const dataset = await this.dashboardService.getUsersTypeRole(this.authStore.getToken())

      runInAction(() => {
        this.userRoleTypeCountDataset = dataset
        this.isLoading = false
      })
      this.setData()
    } catch (e) {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }
}

export default UsersTypeRoleStore
