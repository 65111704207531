import Modal, { ModalButtonsDisplay, ModalSize } from 'components/UI/Modal/Modal'
import React from 'react'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { InputAdornment, TextField } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faXmark } from '@fortawesome/free-solid-svg-icons'
import Button from 'components/UI/Button'
import { observer } from 'mobx-react'
import UserStore from 'containers/Users/UserStore'
import { addRefereedUsersValidationSchema } from './AddRefereedUsersValidationSchema'
import { isNil } from 'lodash'

type AddRefereedUsersModalProps = {
  store: UserStore
  handleClose: () => void
  open: boolean
}

const AddRefereedUsersModal = ({ store, handleClose, open }: AddRefereedUsersModalProps) => {
  const { t } = useTranslation()

  const initialValues = {
    referentAlias: '',
    referredAlias: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: addRefereedUsersValidationSchema,
    onSubmit: async (values) => {
      const { referentAlias, referredAlias } = values
      if (referentAlias && referredAlias) {
        await store.addRefereedUser(referentAlias, referredAlias).then(() => {
          if (isNil(store.error)) {
            handleClose()
            store.fetchRefereedUsers()
          }
        })
      } else {
        store.setError(t('Referent Alias or Referred Alias are missing'))
      }
    },
  })

  const userIcon = <FontAwesomeIcon icon={faUser} className="ml-1 text-xl" />

  return (
    <Modal
      okMessage={t('Save')}
      loadingOk={store.isLoading}
      onCancel={handleClose}
      opened={open}
      buttonsDisplay={ModalButtonsDisplay.NO_BUTTONS}
      modalSize={ModalSize.MEDIUM}
    >
      <div className="flex flex-col gap-2">
        <h1 className="text-lg font-bold text-spotted-light_black mb-4">
          {t('Add Refereed Photographers to User')}
        </h1>
        <form className="flex flex-col gap-4 w-full" onSubmit={formik.handleSubmit}>
          <div className="flex gap-2 items-center w-full">
            <TextField
              required
              fullWidth
              id="referent-alias"
              name="referentAlias"
              label={t('Referent Alias')}
              value={formik.values.referentAlias}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.referentAlias && Boolean(formik.errors.referentAlias)}
              helperText={formik.touched.referentAlias && formik.errors.referentAlias}
              InputProps={{
                startAdornment: <InputAdornment position="start">{userIcon}</InputAdornment>,
              }}
            />
            <TextField
              required
              fullWidth
              id="referred-alias"
              name="referredAlias"
              label={t('Refereed Alias')}
              value={formik.values.referredAlias}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.referredAlias && Boolean(formik.errors.referredAlias)}
              helperText={formik.touched.referredAlias && formik.errors.referredAlias}
              InputProps={{
                startAdornment: <InputAdornment position="start">{userIcon}</InputAdornment>,
              }}
            />
          </div>
          {store.error?.message && (
            <p className="w-full p-2 rounded-md bg-spotted-light_grey flex justify-between items-center">
              <span className="text-red-700 text-sm font-medium">{store.error.message}</span>
              <FontAwesomeIcon
                icon={faXmark}
                className="mr-1 cursor-pointer"
                onClick={() => store.clearError()}
              />
            </p>
          )}
          <div className="w-full flex justify-end mt-2">
            <Button onClick={formik.handleSubmit} goldInverted extraStyle={'w-min'}>
              {t('Save')}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default observer(AddRefereedUsersModal)
