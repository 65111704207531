import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import InputWrapper from '../../../../components/UI/Forms/InputWrapper'
import FormSelect from '../../../../components/UI/Forms/FormSelect'

const PurchaseCodeDiscountForm = ({ discountFormModalStore, t }) => {
  const handleChangeDiscountCode = (val) => discountFormModalStore.changeDiscountCode(val)
  const handleChangeDiscountApplied = (val) => discountFormModalStore.changeDiscountApplied(val)
  const handleChangeDiscountUnit = (val) => discountFormModalStore.changeDiscountCodeUnit(val)

  const { discountCode, discountApplied, unit } = discountFormModalStore

  return (
    <>
      <InputWrapper
        onChange={handleChangeDiscountUnit}
        placeholder={t('Select discount unit type')}
        inputStore={unit}
        label={t('Discount unit')}
        as={FormSelect}
        options={discountFormModalStore.purchaseCodeDiscountUnit.map((unit) => ({
          value: unit.value,
          label: t(unit.label),
        }))}
      />

      <InputWrapper
        onChange={handleChangeDiscountApplied}
        type="number"
        inputStore={discountApplied}
        label={t('Discount Applied')}
      />

      <InputWrapper
        onChange={handleChangeDiscountCode}
        inputStore={discountCode}
        label={t('Discount Code')}
      />
    </>
  )
}

export default withTranslation('common')(observer(PurchaseCodeDiscountForm))
