import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react'
import { withTranslation, useTranslation } from 'react-i18next'
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler'
import StoresContext from '../../providers/storesContext'
import DashboardValueComponent from './components/DashboardValueComponent'
import AlbumsStore from './containers/Albums/AlbumsStore'
import AlbumsLocation from './containers/AlbumsLocation'
import UsersTypeRole from './containers/UsersTypeRole'
import UploadedPhotographsStore from './containers/UploadedPhotographs/UploadedPhotographsStore'
import axios from '../../services/axios'
import PurchasesDashboard from './components/PurchasesDashboard'
import RevenueStore from './containers/Revenue/RevenueStore'
import { PurchaseDashboardKpi } from 'models/PurchaseDashboardKpi'
import SpottedEarningsStore from './containers/SpottedEarnings/SpottedEarningsStore'
import PhotographersEarningsStore from './containers/PhotographersEarnings/PhotographersEarningsStore'
import PurchaseListFilters from 'containers/Purchases/components/PurchaseListFilters'
import AdminPurchasesStore from 'containers/Purchases/AdminPurchasesStore'
import { PurchaseStatus } from 'models/Purchase'

const Dashboard = () => {
  const { t } = useTranslation()
  const { authStore } = useContext(StoresContext)!
  const [adminPurchaseStore] = useState(() => new AdminPurchasesStore(authStore))
  const [revenueStore] = useState(() => new RevenueStore(authStore, new PurchaseDashboardKpi()))
  const [earningsStore] = useState(
    () => new SpottedEarningsStore(authStore, new PurchaseDashboardKpi())
  )
  const [photographersStore] = useState(
    () => new PhotographersEarningsStore(authStore, new PurchaseDashboardKpi())
  )
  const [purchaseDashboardKpi] = useState(() => [
    revenueStore.kpi,
    earningsStore.kpi,
    photographersStore.kpi,
  ])

  const onSearch = (
    dateFrom?: Date,
    dateTo?: Date,
    ownerId?: string,
    sellerId?: string,
    status?: PurchaseStatus
  ): void => {
    revenueStore.updateData(ownerId, sellerId, dateFrom, dateTo, status)
    earningsStore.updateData(ownerId, sellerId, dateFrom, dateTo, status)
    photographersStore.updateData(ownerId, sellerId, dateFrom, dateTo, status)
    adminPurchaseStore.fetchPurchasesOwners().then(() => {})
    adminPurchaseStore.fetchPurchasesSellers().then(() => {})
  }

  const dashboardDaysOptions = [
    {
      value: 1,
      label: `${t('Today')}`,
    },
    {
      value: 7,
      label: `${t('Last 7 days')}`,
    },
    {
      value: 30,
      label: `${t('Last 30 days')}`,
    },
    {
      value: 60,
      label: `${t('Last 60 days')}`,
    },
    {
      value: 365,
      label: `${t('Last year')}`,
    },
  ]
  return (
    <div>
      <h1 className="mb-5 text-center text-2xl">
        <b>{t('Dashboard')}</b>
      </h1>
      <PurchasesDashboard
        revenueStore={revenueStore}
        spottedEarningsStore={earningsStore}
        photographersStore={photographersStore}
      />
      <PurchaseListFilters
        store={adminPurchaseStore}
        kpis={purchaseDashboardKpi}
        onSearch={onSearch}
        showStatus={false}
      />
      <hr />
      <div className="mb-5 grid grid-cols-2 gap-5 auto-rows-fr">
        <DashboardValueComponent
          title={t('Created Albums')}
          formStore={new AlbumsStore(dashboardDaysOptions, authStore)}
        />
        <DashboardValueComponent
          title={t('Uploaded Photographs')}
          formStore={new UploadedPhotographsStore(dashboardDaysOptions, authStore)}
        />
      </div>
      <div className="grid grid-cols-2 gap-5 auto-rows-fr">
        <div className="bg-white p-2 rounded-lg">
          <UsersTypeRole />
        </div>
        <div className="bg-white p-2 rounded-lg">
          <AlbumsLocation />
        </div>
      </div>
    </div>
  )
}

export default withErrorHandler(withTranslation('common')(observer(Dashboard)), axios)
