import React from 'react'
import {
  CheckCircleIcon,
  NoSymbolIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/20/solid'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { CodeDiscount, CodeDiscountUnit } from '../../../models/CodeDiscount'
import { QuantityDiscount } from '../../../models/QuantityDiscount'
import { DiscountType } from '../../../models/BaseDiscount'
import limitChars from '../../../utility'
import { isNil } from 'lodash'

type AdminDiscountRowProps = {
  discount: CodeDiscount | QuantityDiscount
  handleClickEdit: (
    e: React.MouseEvent<SVGSVGElement>,
    discount: CodeDiscount | QuantityDiscount
  ) => void
  handleClickSuspend: (
    e: React.MouseEvent<SVGSVGElement>,
    discount: CodeDiscount | QuantityDiscount
  ) => void
  handleClickDelete: (
    e: React.MouseEvent<SVGSVGElement>,
    discount: CodeDiscount | QuantityDiscount
  ) => void
  handleClickActivate: (
    e: React.MouseEvent<SVGSVGElement>,
    discount: CodeDiscount | QuantityDiscount
  ) => void
}

const AdminDiscountRow = ({
  discount,
  handleClickEdit,
  handleClickSuspend,
  handleClickDelete,
  handleClickActivate,
}: AdminDiscountRowProps) => {
  const { t } = useTranslation()

  let parsedDiscount
  if (discount.type === DiscountType.PURCHASE_QUANTITY) {
    parsedDiscount = discount as QuantityDiscount
  } else {
    parsedDiscount = discount as CodeDiscount
  }
  const discountAmountPrefix = () => {
    if (!isNil(parsedDiscount?.amount)) {
      if (parsedDiscount.unit === CodeDiscountUnit.CURRENCY) {
        return `$${parsedDiscount.amount}`
      } else {
        return `${parsedDiscount.amount}%`
      }
    } else {
      return 'N/A'
    }
  }

  const loadTypeLabel = () => {
    switch (discount.type) {
      case DiscountType.PURCHASE_CODE:
        return 'Code'

      case DiscountType.PURCHASE_QUANTITY:
        return 'Quantity'

      default:
        return discount.type
    }
  }

  return (
    <tr className="even:bg-gray-100 odd:white text-gray-600 hover:bg-gray-200 transition-colors">
      <td className="px-6 py-4 text-sm font-medium">
        {parsedDiscount.code ? limitChars(parsedDiscount.code, 25) : ''}
      </td>
      <td className="px-6 py-4 text-sm font-medium">
        {!parsedDiscount.isSuspended ? (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
            {t('Active')}
          </span>
        ) : (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
            {t('Suspended')}
          </span>
        )}
      </td>
      <td className="px-6 py-4 text-sm font-medium">{t(`${loadTypeLabel()}`)}</td>
      <td className="px-6 py-4 text-sm font-medium">{discountAmountPrefix()}</td>
      <td className="px-6 py-4 text-sm font-medium">
        {limitChars(moment(discount.expirationDate).format('DD.MM.YYYY').toUpperCase(), 12)}
      </td>
      <td className="px-6 py-4 text-sm font-medium">{discount.ownerId}</td>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium flex justify-end">
        <>
          <PencilSquareIcon
            className="text-gray-500 h-5 w-5 cursor-pointer transition-colors hover:text-gray-400"
            onClick={(e) => handleClickEdit(e, discount)}
          />
          {!discount.isSuspended ? (
            <NoSymbolIcon
              className="text-gray-500 h-5 w-5 cursor-pointer transition-colors hover:text-gray-400"
              onClick={(e) => handleClickSuspend(e, discount)}
            />
          ) : (
            <CheckCircleIcon
              className="text-gray-500 h-5 w-5 cursor-pointer transition-colors hover:text-gray-400"
              onClick={(e) => handleClickActivate(e, discount)}
            />
          )}
          <TrashIcon
            className="text-gray-500 h-5 w-5 cursor-pointer transition-colors hover:text-gray-400"
            onClick={(e) => handleClickDelete(e, discount)}
          />
        </>
      </td>
    </tr>
  )
}

export default AdminDiscountRow
