import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation, withTranslation } from 'react-i18next'
import { PlusCircleIcon } from '@heroicons/react/20/solid'
import UserStore from 'containers/Users/UserStore'
import RefereedUsersRow from './RefereedUsersRow'
import Spinner from 'components/UI/Spinner'
import SearchResultListNoResults from 'components/SearchResultListNoResults/SearchResultListNoResults'

type RefereedUsersListProps = {
  store: UserStore
  handleAddRefereedUser: () => void
}

const RefereedUsersList = ({ store, handleAddRefereedUser }: RefereedUsersListProps) => {
  const { t } = useTranslation()
  const { isLoading, refereedUsers } = store

  if (isLoading) {
    return (
      <div className="container flex justify-center">
        <div className="mt-14">
          <Spinner size={11} color="text-spotted-gold" />
        </div>
      </div>
    )
  }

  if (refereedUsers.length === 0) {
    return (
      <div className="align-middle inline-block min-w-full px-2">
        <div className="flex flex-col items-center justify-center my-2">
          <SearchResultListNoResults />
        </div>
      </div>
    )
  }

  return (
    <>
      <h2 className="text-lg font-bold text-start text-spotted-light_black mb-4">
        {t('Refereed photographers')}
      </h2>
      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr className="font-bold">
              <th scope="col" className="px-4 py-3 text-left text-xs text-gray-500 uppercase">
                {t('Alias')}
              </th>
              <th scope="col" className="px-4 py-3 text-left text-xs text-gray-500 uppercase">
                {t('Name')}
              </th>
              <th scope="col" className="px-4 py-3 text-left text-xs text-gray-500 uppercase">
                {t('Email')}
              </th>
              <th scope="col" className="relative flex justify-end px-4 py-3">
                <PlusCircleIcon
                  className="w-6 h-6 transition-colors cursor-pointer text-primary-light hover:text-primary-dark"
                  onClick={handleAddRefereedUser}
                />
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {refereedUsers.map((user, index) => (
              <RefereedUsersRow key={index} user={user} />
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default withTranslation('common')(observer(RefereedUsersList))
