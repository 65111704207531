import { makeAutoObservable, runInAction } from 'mobx'
import * as yup from 'yup'
import AuthStore from '../../../../stores/AuthStore'
import InputStore from '../../../../components/UI/Forms/InputWrapper/InputStore'
import ActivityService from '../../../../services/ActivityService'
import { Activity } from '../../../../models/Activity'

class ActivityFormModalStore {
  public activity: Activity
  public activityName: InputStore<string>
  public isLoading: boolean
  public error: any
  private serverError: string
  private authStore: AuthStore
  private activityService: ActivityService
  constructor(authStore) {
    this.authStore = authStore
    this.reset()
    makeAutoObservable(this)
    this.activityService = new ActivityService()
  }

  reset() {
    // this.id = null
    this.activityName = new InputStore(
      yup.string().required('El nombre del actividad es requerido')
    )
    this.isLoading = false
    this.error = null
  }

  changeActivityName(val) {
    this.activityName.setValue(val)
  }

  clearErrors() {
    this.activityName.clearError()
    this.error = null
  }

  async validate() {
    this.clearErrors()
    let isValid = true

    if (!(await this.activityName.validate())) {
      isValid = false
    }

    return isValid
  }

  async saveActivity() {
    if (await this.validate()) {
      runInAction(() => {
        this.isLoading = true
      })

      try {
        const activityToSend: Activity = new Activity()
        activityToSend.name = this.activityName.value
        activityToSend.creatorId = this.authStore.getLoggedUser().id

        const newSpot = await this.activityService.saveActivity(
          this.authStore.getAdminToken(),
          activityToSend
        )

        runInAction(() => {
          this.isLoading = false
        })

        return newSpot
      } catch (e: any) {
        runInAction(() => {
          const displayedError = this.parseRequestErrors(e.response?.data?.errors || {})

          if (!displayedError) {
            this.serverError = 'Something went wrong, please check the provided data and try again.'
          }

          this.isLoading = false
        })
      }
    }

    return false
  }

  async editSpot() {
    if (!(await this.validate())) {
      return
    }

    runInAction(() => {
      this.isLoading = true
    })

    try {
      this.activity.name = this.activityName.value

      const activityUpdated = await this.activityService.editActivity(
        this.activity,
        this.authStore.getAdminToken()
      )

      runInAction(() => {
        this.isLoading = false
      })

      return activityUpdated
    } catch (e: any) {
      runInAction(() => {
        this.parseRequestErrors(e.response?.data?.errors || {})
        this.isLoading = false
        if (e?.message) {
          this.error = e?.message
        }
      })
    }

    return false
  }

  parseRequestErrors(messages) {
    const keys = Object.keys(messages)
    let displayedError = false

    keys.forEach((key) => {
      const [error] = messages[key]

      switch (key) {
        case 'activityName':
          this.activityName.setError(true, error)
          displayedError = true
          break

        default:
          break
      }
    })

    return displayedError
  }
}

export default ActivityFormModalStore
