import { observer } from 'mobx-react'
import storesContext from 'providers/storesContext'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import UploadCoverPhotographStore, { ProgressInfo } from './UploadCoverPhotographStore'
import { isNil } from 'lodash'
import { ImageUploader } from './components/ImageUploader'
import ImageUploaded from './components/ImageUploaded'
import { Event } from 'models/Event'

type UploadCoverPhotographProps = {
  event: Event
}

const UploadCoverPhotograph = ({ event }: UploadCoverPhotographProps) => {
  const { authStore } = useContext(storesContext)!
  const [store] = useState(() => new UploadCoverPhotographStore(authStore))

  const auxSetProgressInfo = useCallback((pginfo: ProgressInfo) => {
    store.changeProgressInfo(pginfo)
  }, [])

  const onSubmitImage = (image: File) => {
    store.changeImageSubmitted(true)
    store.changeImage(image)
    const progressInfo = {
      percentage: 0,
      fileName: image.name,
      requestSent: false,
      error: false,
      size: image.size,
      type: image.type,
    }
    auxSetProgressInfo(progressInfo)
  }

  useEffect(() => {
    if (!isNil(event.coverPhotographSrc)) {
      store.changeCoverPhotographUrl(event.coverPhotographSrc)
    }
  }, [])

  useEffect(() => {
    if (store.imageSubmitted && !isNil(store.image)) {
      store.uploadCoverPhotograph(event.id)
    } else if (store.imageSubmitted && isNil(store.image)) {
      store.changeImageSubmitted(false)
    }
  }, [auxSetProgressInfo, store.image])

  let imageDisplayView
  !store.coverPhotographUrl
    ? (imageDisplayView = <ImageUploader onUpload={onSubmitImage} />)
    : (imageDisplayView = <ImageUploaded onUpload={onSubmitImage} store={store} />)

  return <>{imageDisplayView}</>
}

export default observer(UploadCoverPhotograph)
