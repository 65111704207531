import React, { memo } from 'react'
import { XMarkIcon } from '@heroicons/react/20/solid'
import ReactModal from 'react-modal'
import Button from 'components/UI/Button'
import { useTranslation, withTranslation } from 'react-i18next'

ReactModal.setAppElement('#root')

export enum ModalSize {
  SMALL,
  MEDIUM,
  LARGE,
}

const baseClasses = [
  'modal-base',
  'outline-none',
  'bg-white',
  'outline-none',
  'rounded-lg',
  'shadow-xl',
  'w-full',
  'sm:mx-0',
  'sm:my-8',
  'absolute left-1/2 transform -translate-x-2/4',
  'shadow-xl',
  'p-2',
  'sm:p-10',
  'overflow-scroll',
  'border',
  'border-black',
]

const ModalClasses = new Map<ModalSize, string[]>()
ModalClasses.set(ModalSize.SMALL, ['sm:max-w-sm sm:max-h-sm top-1/2 transform -translate-y-2/4'])
ModalClasses.set(ModalSize.MEDIUM, [
  'sm:max-w-6xl sm:max-h-xl top-1/2 transform -translate-y-2/4',
  'max-h-screen',
])
ModalClasses.set(ModalSize.LARGE, ['sm:w-11/12', 'h-600', 'max-h-screen', 'mb-100'])

export enum ModalButtonsDisplay {
  FULL,
  ONLY_OK_BUTTON,
  NO_BUTTONS,
}

type ModalProps = {
  opened: boolean
  children: React.ReactNode
  buttonsDisplay?: ModalButtonsDisplay
  modalSize?: ModalSize
  onCancel: () => void
  onSuccess?: () => void
  okMessage?: string | null
  cancelMessage?: string
  autoClose?: boolean
  displayCloseIcon?: boolean
  zIndex?: number
  isDeleteConfirmation?: boolean
  loadingOk?: boolean
  redCancel?: boolean
  noOverlay?: boolean
  scroll?: boolean
}

export const Modal = ({
  opened,
  children,
  buttonsDisplay = ModalButtonsDisplay.ONLY_OK_BUTTON,
  modalSize = ModalSize.MEDIUM,
  onSuccess = Function,
  onCancel = Function,
  autoClose = true,
  okMessage = 'Ok',
  cancelMessage,
  displayCloseIcon = true,
  zIndex = 100,
  loadingOk = false,
  redCancel = false,
  scroll = false,
}: ModalProps) => {
  const { t } = useTranslation()
  const okClick = () => {
    if (onSuccess) {
      onSuccess()
    }
  }

  const cancelClick = () => {
    if (onCancel) {
      onCancel()
    }
  }

  let modalActions

  if (buttonsDisplay !== ModalButtonsDisplay.NO_BUTTONS) {
    if (buttonsDisplay === ModalButtonsDisplay.FULL) {
      modalActions = (
        <div className="flex flex-row justify-between p-4">
          <Button onClick={cancelClick} redCancel={redCancel} cancel={!redCancel}>
            {cancelMessage ? cancelMessage : t('Cancel')}
          </Button>
          <Button isLoading={loadingOk} onClick={okClick}>
            {okMessage}
          </Button>
        </div>
      )
    }
    if (buttonsDisplay === ModalButtonsDisplay.ONLY_OK_BUTTON) {
      modalActions = (
        <div className="flex justify-end p-4">
          <Button isLoading={loadingOk} onClick={okClick}>
            {okMessage}
          </Button>
        </div>
      )
    }
  }

  const modalStyles = [...baseClasses, ...ModalClasses.get(modalSize)!].join(' ')
  return (
    <ReactModal
      shouldCloseOnOverlayClick
      overflow={scroll}
      style={{
        content: { zIndex },
        overlay: { zIndex: zIndex - 1 },
      }}
      className={modalStyles}
      onRequestClose={() => {
        if (autoClose) {
          cancelClick()
        }
      }}
      closeTimeoutMS={300}
      isOpen={opened}
    >
      <div className="container w-full h-full flex flex-col align-between">
        {displayCloseIcon && cancelClick && (
          <div className="absolute t-0.5 right-4" onClick={cancelClick}>
            <XMarkIcon className="w-7 h-7 text-gray-800 cursor-pointer" />
          </div>
        )}
        <div id="modal-content" className="row w-full h-full">
          {children}
        </div>
        <div className="relative bottom-0 left-0 w-full">{modalActions}</div>
      </div>
    </ReactModal>
  )
}

export default withTranslation()(memo(Modal))
