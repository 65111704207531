import React, { useEffect, useState } from 'react'
import { TrashIcon, PencilSquareIcon, CheckBadgeIcon } from '@heroicons/react/20/solid'
import limitChars from '../../../utility'
import { Location } from 'models/Location'
import Modal, { ModalButtonsDisplay, ModalSize } from 'components/UI/Modal/Modal'
import Album from 'models/Album'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

type AdminLocationRowProps = {
  location: Location
  albums: Album[]
  handleClickEdit: any
  handleClickDelete: any
  handleUndoDelete: any
  handleGetAlbums: any
}

const LocationRow = ({
  location,
  albums,
  handleClickEdit,
  handleClickDelete,
  handleUndoDelete,
  handleGetAlbums,
}: AdminLocationRowProps) => {
  const { t } = useTranslation()

  const [showLocationAlbumsDetails, setShowLocationAlbumsDetails] = useState(false)

  const detailsModal = showLocationAlbumsDetails ? (
    <Modal
      modalSize={ModalSize.LARGE}
      buttonsDisplay={ModalButtonsDisplay.NO_BUTTONS}
      onSuccess={() => setShowLocationAlbumsDetails(false)}
      onCancel={() => setShowLocationAlbumsDetails(false)}
      opened={showLocationAlbumsDetails}
    >
      <div className="w-full flex flex-col justify-center content-center">
        <h1 className="mb-5 text-center text-2xl">
          <b>{t('Albums related to location ') + location.spotName} </b>
        </h1>
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr className="font-bold">
                <th scope="col" className="px-6 py-3 text-left text-xs text-gray-500 uppercase">
                  {t('Album Description')}
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {t('Status')}
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {t('Take date')}
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {t('Spot name')}
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {t('Owner name')}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {albums.map((album, index) => (
                <>
                  <tr className="even:bg-gray-100 odd:white text-gray-600 hover:bg-gray-200 transition-colors hover:cursor-pointer">
                    <td className="px-6 py-4 text-sm font-medium"> {album.description} </td>
                    <td className="px-6 py-4 text-sm font-medium">
                      {!album.isSuspended ? (
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                          {t('Active')}
                        </span>
                      ) : (
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                          {t('Suspended')}
                        </span>
                      )}
                    </td>
                    <td className="px-6 py-4 text-sm font-medium">
                      {moment.utc(album.takenDate).format('DD.MM.YYYY').toUpperCase()}
                    </td>
                    <td className="px-6 py-4 text-sm font-medium"> {album.location.spotName} </td>
                    <td className="px-6 py-4 text-sm font-medium">
                      {album.owner.firstName} {album.owner.lastName}
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  ) : (
    <></>
  )
  return (
    <>
      <tr
        onClick={() => {
          setShowLocationAlbumsDetails(true), handleGetAlbums(location)
        }}
        className="even:bg-gray-100 odd:white text-gray-600 hover:bg-gray-200 transition-colors"
      >
        <td className="px-6 py-4 text-sm font-medium">{limitChars(location.spotName, 25)}</td>
        {location.deletedAt ? (
          <td className="px-2 text-left inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
            {t('Yes')}
          </td>
        ) : (
          <td className="px-2  inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
            {t('No')}
          </td>
        )}

        <td className="px-6 py-4 text-sm font-medium">{limitChars('' + location.latitude, 10)}</td>
        <td className="px-6 py-4 text-sm font-medium">{limitChars('' + location.longitude, 10)}</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium flex justify-end">
          <>
            <PencilSquareIcon
              className="text-gray-500 h-5 w-5 cursor-pointer transition-colors hover:text-gray-400"
              onClick={(event) => {
                event.stopPropagation()
                handleClickEdit(location)
              }}
            />
            {location.deletedAt ? (
              <CheckBadgeIcon
                className="text-gray-500 h-5 w-5 cursor-pointer transition-colors hover:text-gray-400"
                onClick={(event) => {
                  event.stopPropagation()
                  handleUndoDelete(location)
                }}
                title="Undo delete"
              />
            ) : (
              <TrashIcon
                className="text-gray-500 h-5 w-5 cursor-pointer transition-colors hover:text-gray-400"
                onClick={(event) => {
                  event.stopPropagation()
                  handleClickDelete(location)
                }}
              />
            )}
          </>
        </td>
      </tr>
      {detailsModal}
    </>
  )
}

export default LocationRow
