import { observer } from 'mobx-react'
import React, { useState, useEffect } from 'react'
import AlbumsStore from '../containers/Albums/AlbumsStore'
import PhotographersEarningsStore from '../containers/PhotographersEarnings/PhotographersEarningsStore'
import RevenueStore from '../containers/Revenue/RevenueStore'
import SpottedEarningsStore from '../containers/SpottedEarnings/SpottedEarningsStore'
import UploadedPhotographsStore from '../containers/UploadedPhotographs/UploadedPhotographsStore'
import AthletePurchasesStore from '../containers/AthletePurchases/AthletePurchasesStore'
import InputWrapper from '../../../components/UI/Forms/InputWrapper'
import FormSelect from '../../../components/UI/Forms/FormSelect'
import Spinner from '../../../components/UI/Spinner/Spinner'

type DashboardValueComponentProps = {
  formStore: AlbumsStore | AthletePurchasesStore | UploadedPhotographsStore
  title: string
}

const DashboardValueComponent = ({ formStore, title }: DashboardValueComponentProps) => {
  const [store] = useState(formStore)

  const { loading, days, value, options } = store

  useEffect(() => {
    store.updateData(days)
  }, [days])

  return (
    <div className="bg-white p-5 rounded-lg">
      <div className="flex flex-row justify-between items-center text-gray-800">
        <div>
          <span>
            <b>{title}</b>
          </span>
        </div>
        <div className="w-40">
          <InputWrapper
            options={options}
            onChange={(days: { value: number }) => {
              store.setDays(days)
            }}
            as={FormSelect}
            value={days}
          />
        </div>
      </div>
      <div className={'text-3xl'}>
        <span>
          {loading ? (
            <div className="pt-4">
              <Spinner color="text-primary-light" size={10} />
            </div>
          ) : (
            <b>{value}</b>
          )}
        </span>
      </div>
    </div>
  )
}

export default observer(DashboardValueComponent)
