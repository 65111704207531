import { adminApiInstance } from './axios'
import * as paths from '../services/Paths'
import { AxiosResponse } from 'axios'
import { Activity } from '../models/Activity'
import { parseAxiosErrorResponse } from '../utility'

class ActivityService {
  fetchActivities(token: string): Promise<Activity[]> {
    return adminApiInstance
      .get(paths.ADMIN_ACTIVITIES, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  //ADMIN REQUESTS

  searchAdminActivities(token: string, page: number, search?: string) {
    //TODO: search is activityName ?
    const path = search
      ? `${paths.ADMIN_ACTIVITIES}/search?page=${page}&name=${search}`
      : `${paths.ADMIN_ACTIVITIES}/search?page=${page}`
    return adminApiInstance
      .get(path, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }: AxiosResponse) => {
        return {
          activities: data.data,
          paginator: data.meta,
        }
      })
      .catch(parseAxiosErrorResponse)
  }

  saveActivity(token: string, activityData: Activity) {
    return adminApiInstance
      .post(paths.ADMIN_ACTIVITIES, activityData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  mergeActivity(activityId: string | null, newActivityId: string, token: string) {
    return adminApiInstance
      .delete(`${paths.ADMIN_ACTIVITIES}/${newActivityId}/${activityId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  deleteActivity(activityId: string, token: string) {
    return adminApiInstance
      .delete(paths.updateActivityById(activityId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  editActivity(activity: Activity, token: string) {
    return adminApiInstance
      .put(paths.updateActivityById(activity.id), activity, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  getAlbumsCountByActivity(activityId: string, token: string) {
    return adminApiInstance
      .get(paths.albumsCountByActivity(activityId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }: AxiosResponse) => {
        return data.data
      })
      .catch(parseAxiosErrorResponse)
  }
}

export default ActivityService
