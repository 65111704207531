import React from 'react'
import { NavLink } from 'react-router-dom'

type SiteBarLinkProps = {
  to: string
  label: string
  disabled?: boolean
}

const SideBarLink = ({ to, label, disabled = false }: SiteBarLinkProps) => {
  if (disabled) {
    return (
      <div className="px-7 py-3 border rounded-t-md font-bold border-gray-300 bg-gray-50 text-gray-500">
        {label}
      </div>
    )
  }

  return (
    <NavLink
      className="block px-4 py-2 mt-2 text-sm font-semibold text-spotted-black bg-gray-200 rounded-lg hover:bg-primary-light hover:text-white hover:border-primary-light hover:no-underline"
      // style="bg-primary-light border-primary-light text-white"
      to={to}
      // exact
    >
      {label}
    </NavLink>
  )
}

export default SideBarLink
