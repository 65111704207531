import { User } from './User'
import { Photograph } from './Photograph'
import { Payment, PaymentStatus } from './Payment'
import Album from './Album'

export enum PurchaseStatus {
  Pending = 'pending',
  Rejected = 'rejected',
  Approved = 'approved',
  Cancelled = 'cancelled',
}

export class Purchase {
  id: string

  value: number // totalPrice without discounts.

  totalPrice: number // value - discounts.

  // appliedDiscounts: BaseDiscountModel[]

  discountTotal: number

  status: PurchaseStatus

  owner: User

  ownerId: string

  seller: User

  sellerId: string

  photographs: Photograph[]

  album: Album

  paymentUrl?: string

  preferenceId?: string

  payments: Payment[]

  createdAt: Date

  updatedAt: Date

  deletedAt?: Date

  get approvedPayments(): Payment[] {
    return this.payments.filter((payment) => payment.status !== PaymentStatus.APPROVED)
  }
}
