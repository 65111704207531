import * as yup from 'yup'
export const addRefereedUsersValidationSchema = yup
  .object({
    referentAlias: yup.string().required('Referent Alias is required'),
    referredAlias: yup.string().required('Referred Alias is required'),
  })
  .test(
    'aliases-not-same',
    'Referent Alias and Referred Alias cannot be the same',
    function (values) {
      const { referentAlias, referredAlias } = values
      return referentAlias !== referredAlias
    }
  )
