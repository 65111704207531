import React, { MouseEventHandler } from 'react'
import { observer } from 'mobx-react'
import { useTranslation, withTranslation } from 'react-i18next'
import { PlusCircleIcon } from '@heroicons/react/20/solid'
import LocationRow from './LocationRow'
import SearchResultListNoResults from '../../../components/SearchResultListNoResults/SearchResultListNoResults'
import Spinner from '../../../components/UI/Spinner/Spinner'
import { Location } from '../../../models/Location'
import Album from 'models/Album'

type LocationListProps = {
  locations: Location[]
  albums: Album[]
  isLoading: boolean
  handleClickEdit: (location: Location) => void
  handleClickDelete: (location: Location) => void
  handleAddLocation: MouseEventHandler<any>
  handleGetAlbums: (location: Location) => void
  handleUndoDelete: (location: Location) => void
}

const LocationList = ({
  locations = [],
  albums,
  isLoading = false,
  handleClickEdit,
  handleClickDelete,
  handleAddLocation,
  handleUndoDelete,
  handleGetAlbums,
}: LocationListProps) => {
  const { t } = useTranslation()

  if (isLoading) {
    return (
      <div className="container flex justify-center">
        <div className="mt-14">
          <Spinner size={11} color="text-spotted-gold" />
        </div>
      </div>
    )
  }

  if (locations.length === 0) {
    return (
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className="flex flex-col items-center justify-center my-10">
          <SearchResultListNoResults />
          <div className="bg-teal-100 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md w-1/2 flex justify-center mt-8">
            <div className="flex">
              <span className="font-bold mr-4">{t('Add Spot')}</span>
              <PlusCircleIcon
                className="text-primary-light h-6 w-6 cursor-pointer transition-colors hover:text-primary-dark"
                onClick={handleAddLocation}
                title="Add Spot"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
      <table className="min-w-full text-left divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr className="font-bold">
            <th scope="col" className="px-6 py-3 text-left text-xs text-gray-500 uppercase">
              {t('Spot Name')}
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {t('Is deleted?')}
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {t('Latitude')}
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {t('Longitude')}
            </th>
            <th scope="col" className="relative px-6 py-3 flex justify-end">
              <PlusCircleIcon
                className="text-primary-light h-6 w-6 cursor-pointer transition-colors hover:text-primary-dark"
                onClick={handleAddLocation}
                title="Add Spot"
              />
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y text-left divide-gray-200">
          {locations.map((spot, index) => (
            <LocationRow
              key={index}
              location={spot}
              albums={albums}
              handleGetAlbums={handleGetAlbums}
              handleClickDelete={handleClickDelete}
              handleUndoDelete={handleUndoDelete}
              handleClickEdit={handleClickEdit}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default withTranslation()(observer(LocationList))
