import React from 'react'
import c from 'classnames'

const inputBaseClasses = [
  'p-2.5',
  'rounded-md',
  'w-full',
  'focus:ring-2',
  'focus:outline-none',
  'transition-shadow',
]

const inputDefaultClasses = [
  'text-gray-800',
  'focus:ring-gray-600',
  'focus:ring-opacity-20',
  'bg-gray-50',
]

const inputInvertedClasses = [
  'bg-gray-600',
  'text-white',
  'focus:ring-gray-800',
  'focus:ring-opacity-80',
]

const inputErrorClasses = ['text-red-400', 'bg-red-50', 'focus:ring-red-500']

type InputProps = {
  password?: boolean
  onChange: Function
  value: any
  onPressEnter?: Function | null
  inputRef: string | null
  inverted: boolean
  error: boolean
  onlyNumeric?: boolean
  onlyInteger?: boolean
  extraClassNames?: string
}

const Input = ({
  password = false,
  onChange,
  value,
  onPressEnter = null,
  inputRef = null,
  inverted = false,
  error = false,
  onlyNumeric = false,
  onlyInteger = false,
  extraClassNames = '',
  ...props
}: InputProps) => {
  const inputStyleClasses = !inverted ? inputDefaultClasses : inputInvertedClasses
  const errorClasses = error ? inputErrorClasses : []
  const inputClasses = [...inputBaseClasses, ...inputStyleClasses, ...errorClasses, extraClassNames]

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter' && onPressEnter !== null) {
      onPressEnter()
    }
  }

  const handleChange = (e: any) => {
    if (onlyNumeric && !/^-?\d*[.]?\d{0,2}$/.test(e.target.value)) {
      return false
    }

    if (onlyInteger && !/^-?\d*$/.test(e.target.value)) {
      return false
    }

    onChange(e.target.value)
  }

  return (
    <input
      {...props}
      onKeyPress={handleKeyPress}
      className={c(inputClasses)}
      onChange={handleChange}
      ref={inputRef}
      value={value}
      type={!password ? 'text' : 'password'}
    />
  )
}

export default Input
