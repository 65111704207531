import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation, withTranslation } from 'react-i18next'
import AdminPurchaseRow from './AdminPurchaseRow'
import SearchResultListNoResults from '../../../components/SearchResultListNoResults/SearchResultListNoResults'
import { Purchase } from '../../../models/Purchase'
import Spinner from '../../../components/UI/Spinner/Spinner'

type AdminPurchaseListProps = {
  purchases: Purchase[]
  isLoading?: boolean
  handleClickDownload: any
  handleClickApprove: any
  handleClickReject: any
  handleClickDelete: any
}

const AdminPurchaseList = ({
  purchases = [],
  isLoading = false,
  handleClickDownload,
  handleClickApprove,
  handleClickReject,
  handleClickDelete,
}: AdminPurchaseListProps) => {
  const { t } = useTranslation()

  if (isLoading) {
    return (
      <div className="container flex justify-center">
        <div className="mt-14">
          <Spinner size={11} color="text-spotted-gold" />
        </div>
      </div>
    )
  }

  if (purchases.length === 0) {
    return (
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className="flex flex-col items-center justify-center my-10">
          <SearchResultListNoResults />
        </div>
      </div>
    )
  }

  return (
    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr className="font-bold">
            <th scope="col" className="px-6 py-3 text-left text-xs text-gray-500 uppercase">
              {t('Buyer')}
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {t('Seller')}
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {t('# Items')}
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {t('Total Price')}
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {t('Discount')}
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {t('MercadoPago Fee')}
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {t('Spotted Fee')}
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {t('Status')}
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {t('Created At')}
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {t('Actions')}
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {purchases.map((purchase, index) => (
            <AdminPurchaseRow
              key={index}
              purchase={purchase}
              handleClickDelete={handleClickDelete}
              handleClickDownload={handleClickDownload}
              handleClickApprove={handleClickApprove}
              handleClickReject={handleClickReject}
              t={t}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default withTranslation()(observer(AdminPurchaseList))
