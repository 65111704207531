import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import {
  NoSymbolIcon,
  CheckCircleIcon,
  TrashIcon,
  ArrowDownTrayIcon,
  EyeIcon,
} from '@heroicons/react/20/solid'
import { Purchase, PurchaseStatus } from '../../../models/Purchase'
import Modal, { ModalButtonsDisplay, ModalSize } from '../../../components/UI/Modal/Modal'
import { Fee } from '../../../models/Fee'
import ConfigService, { ConfigKeys } from '../../../config'
import moment from 'moment'
import StoresContext from '../../../providers/storesContext'
import { Photograph } from '../../../models/Photograph'
import limitChars from '../../../utility'
import Button from '../../../components/UI/Button'
import { Payment } from '../../../models/Payment'

enum FeeType {
  SPOTTED = 'application_fee',
  MERCADOPAGO = 'mercadopago_fee',
}

type AdminPurchaseRowProps = {
  purchase: Purchase
  handleClickDownload: any
  handleClickApprove: any
  handleClickReject: any
  handleClickDelete: any
  t: any
}

const AdminPurchaseRow = ({
  purchase,
  handleClickDownload,
  handleClickApprove,
  handleClickReject,
  handleClickDelete,
  t,
}: AdminPurchaseRowProps) => {
  const [showPurchaseDetails, setShowPurchaseDetails] = useState(false)

  const goToAlbum = () => {
    const url = `${ConfigService.getValue(ConfigKeys.WEB_URL)}/feed/albums/${purchase.album.id}`
    window.open(url, '_blank', 'noreferrer')
  }

  const goToPhotograph = (url: string) => {
    window.open(url, '_blank', 'noreferrer')
  }

  const getPaymentFee = (type: FeeType) => {
    if (purchase?.approvedPayments?.length > 0) {
      const firstPayment = purchase.approvedPayments[0]
      if (firstPayment.fees.length > 0) {
        const feeObject = firstPayment.fees.find((fee: Fee) => fee.type === type)
        return feeObject?.amount || 0
      }
    }
    return 0
  }

  const detailsModal = showPurchaseDetails ? (
    <Modal
      modalSize={ModalSize.LARGE}
      buttonsDisplay={ModalButtonsDisplay.NO_BUTTONS}
      onSuccess={() => setShowPurchaseDetails(false)}
      onCancel={() => setShowPurchaseDetails(false)}
      opened={showPurchaseDetails}
    >
      <div className="w-full flex flex-col justify-center content-center">
        <h1 className="mb-5 text-center text-2xl">
          <b>{t('Purchase Details')}</b>
        </h1>
        <div className="grid grid-cols-2 gap-2">
          <div>
            <p className="font-bold mt-3 mb-0">Purchase</p>
            <p>ID: {purchase.id}</p>
            <p>Value: {purchase.value}</p>
            <p>Total Price: {purchase.totalPrice}</p>
            <p>Status: {purchase.status}</p>
            <p>Discount Total: {purchase.discountTotal}</p>
            <p>Created At: {moment(purchase.createdAt).format('d/MM/yyyy HH:mm:ss')}</p>
            <p>Created At (UTC): {moment.utc(purchase.createdAt).format('d/MM/yyyy HH:mm:ss')}</p>
          </div>
          <div>
            <p className="font-bold mt-3 mb-0">Buyer</p>
            <p className="mb-0">ID: {purchase.owner?.id}</p>
            <p className="mb-0">
              {purchase.owner?.firstName} {purchase.owner?.lastName}
            </p>
            <p className="mb-0">{purchase.owner?.email}</p>
          </div>
          <div>
            <p className="font-bold mt-3 mb-0">Photograph ({purchase.photographs?.length})</p>
            {purchase.photographs.map((photograph: Photograph) => {
              const photographUrl = `${ConfigService.getValue(ConfigKeys.WEB_URL)}/feed/albums/${
                photograph.albumId
              }/${photograph.id}`
              return (
                <div className="flex items-center" key={photograph.id}>
                  ID: {photograph.id}{' '}
                  <div className="flex gap-1 ml-3">
                    <EyeIcon
                      className="text-gray-500 h-5 w-5 cursor-pointer transition-colors hover:text-gray-400"
                      title="Ver"
                      onClick={() => goToPhotograph(photographUrl)}
                    />
                    <ArrowDownTrayIcon
                      className="text-gray-500 h-5 w-5 cursor-pointer transition-colors hover:text-gray-400"
                      title="Descargar"
                      onClick={() => handleClickDownload(photograph.id)}
                    />
                  </div>
                </div>
              )
            })}
          </div>
          <div>
            <p className="font-bold mt-3 mb-0">Payments ({purchase.payments?.length})</p>
            <div className="overflow-scroll">
              {purchase.payments.map((payment: Payment) => (
                <>
                  <p>ID: {payment.uuid}</p>
                  <p>Mercadopago ID: {payment.id}</p>
                  <p>Currency: {payment.currencyId}</p>
                  <p>Currency: {moment(payment.dateApproved).format('DD/MM/YYYY h:mm a')}</p>
                  <p>Currency: {moment(payment.dateCreated).format('DD/MM/YYYY h:mm a')}</p>
                  <p>status: {payment.status}</p>
                  <p>transactionNetReceivedAmount: {payment.transactionNetReceivedAmount}</p>
                  <p>totalPayedAmount: {payment.totalPayedAmount}</p>
                  <p>
                    Fees:{' '}
                    {payment.fees?.map((fee) => {
                      return (
                        <span key={fee.type} className="ml-2">
                          <p>Type: {fee.type}</p>
                          <p>Payer: {fee.amount}</p>
                          <p>Amount {fee.payer}</p>
                        </span>
                      )
                    })}{' '}
                  </p>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  ) : (
    <></>
  )
  return (
    <>
      <tr
        onClick={() => setShowPurchaseDetails(true)}
        className="even:bg-gray-100 odd:white text-gray-600 hover:bg-gray-200 transition-colors"
      >
        {purchase.owner ? (
          <td className="px-6 py-4 text-sm font-medium">
            {limitChars(purchase.owner?.firstName, 25)} {limitChars(purchase.owner?.lastName, 25)}
          </td>
        ) : (
          <td className="px-6 py-4 text-sm font-medium"></td>
        )}
        {purchase.seller ? (
          <td className="px-6 py-4 text-sm font-medium">
            {limitChars(purchase.seller?.firstName, 25)} {limitChars(purchase.seller?.lastName, 25)}
          </td>
        ) : (
          <td className="px-6 py-4 text-sm font-medium"></td>
        )}
        <td className="px-6 py-4 text-sm font-medium">{purchase.photographs?.length}</td>
        <td className="px-6 py-4 text-sm font-medium">$ {purchase.totalPrice}</td>
        <td className="px-6 py-4 text-sm font-medium">$ {purchase.discountTotal}</td>
        <td className="px-6 py-4 text-sm font-medium">$ {getPaymentFee(FeeType.MERCADOPAGO)}</td>
        <td className="px-6 py-4 text-sm font-medium">$ {getPaymentFee(FeeType.SPOTTED)}</td>
        <td className="px-6 py-4 text-sm font-medium">
          {purchase.status === PurchaseStatus.Approved ? (
            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
              {t('Approved')}
            </span>
          ) : (
            <>
              {purchase.status === PurchaseStatus.Rejected ? (
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                  {t('Rejected')}
                </span>
              ) : (
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                  {t('Pending')}
                </span>
              )}
            </>
          )}
        </td>
        <td className="px-6 py-4 text-sm font-medium">
          {moment(purchase.createdAt).format('DD/MM/YYYY h:mm a').toUpperCase()}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium flex justify-end">
          <>
            {purchase.status === PurchaseStatus.Approved ? (
              <NoSymbolIcon
                className="text-gray-500 h-5 w-5 cursor-pointer transition-colors hover:text-gray-400"
                onClick={() => handleClickReject(purchase)}
              />
            ) : (
              <CheckCircleIcon
                className="text-gray-500 h-5 w-5 cursor-pointer transition-colors hover:text-gray-400"
                onClick={() => handleClickApprove(purchase)}
              />
            )}

            <TrashIcon
              className="text-gray-500 h-5 w-5 cursor-pointer transition-colors hover:text-gray-400"
              onClick={() => handleClickDelete(purchase)}
            />
          </>
        </td>
      </tr>
      {detailsModal}
    </>
  )
}

export default observer(AdminPurchaseRow)
