import { makeAutoObservable, runInAction } from 'mobx'
import DiscountService from '../../services/DiscountService'
import { CodeDiscount } from '../../models/CodeDiscount'
import { QuantityDiscount } from '../../models/QuantityDiscount'
import AuthStore from '../../stores/AuthStore'

class AdminDiscountsStore {
  private discountService: DiscountService
  public discounts: (CodeDiscount | QuantityDiscount)[]
  public paginator: null
  public isLoading: boolean
  public isDeleting: boolean
  private error: any
  constructor(private readonly authStore: AuthStore) {
    this.reset()
    makeAutoObservable(this)
    this.discountService = new DiscountService()
    this.authStore = authStore
  }

  reset() {
    this.discounts = []
    this.paginator = null
    this.isLoading = false
    this.isDeleting = false
    this.error = false
  }

  async fetchDiscounts(page) {
    runInAction(() => {
      this.isLoading = true
    })
    try {
      const { discounts, paginator } = await this.discountService.fetchAdminDiscounts(
        this.authStore.getToken(),
        page
      )
      runInAction(() => {
        this.isLoading = false
        this.discounts = discounts
        this.paginator = paginator
        this.error = null
      })
    } catch (e) {
      this.error = e
      this.isLoading = false
    }
  }

  async deleteDiscount(discountToDelete) {
    runInAction(() => {
      this.isDeleting = true
    })
    try {
      await this.discountService.deleteDiscount(discountToDelete.id, this.authStore.getToken())
      runInAction(() => {
        this.isDeleting = false
        this.fetchDiscounts(0)
      })
    } catch (e: any) {
      this.error = e
      this.isDeleting = false
      return e?.message
    }
  }

  async suspendDiscount(discount) {
    runInAction(() => {
      this.isLoading = true
    })
    try {
      const discountSuspended = await this.discountService.suspendDiscount(
        this.authStore.getToken(),
        discount
      )
      runInAction(() => {
        this.isLoading = false
      })
      return discountSuspended
    } catch (e) {
      this.error = e
      this.isLoading = false
    }
  }

  async activateDiscount(discount) {
    runInAction(() => {
      this.isLoading = true
    })
    try {
      const discountActivated = await this.discountService.activateDiscount(
        this.authStore.getToken(),
        discount
      )
      runInAction(() => {
        this.isLoading = false
      })
      return discountActivated
    } catch (e) {
      this.error = e
      this.isLoading = false
    }
  }
}

export default AdminDiscountsStore
