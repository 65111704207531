import React, { useState, useEffect, useContext } from 'react'
import { observer } from 'mobx-react'
import { useTranslation, withTranslation } from 'react-i18next'
import AddUserFormStore from './AddUserFormStore'
import { CheckIcon } from '@heroicons/react/20/solid'
import { isNil } from 'lodash'
import { toast } from 'react-toastify'
import StoresContext from '../../../../providers/storesContext'
import { User } from '../../../../models/User'
import Modal, { ModalButtonsDisplay, ModalSize } from '../../../../components/UI/Modal/Modal'
import InputWrapper from '../../../../components/UI/Forms/InputWrapper'
import ServerError from '../../../../components/UI/ServerError'

type UserFormModalProps = {
  open: boolean
  handleClose: () => void
  afterSaveUser: () => void
  editionAdminUser: User | null
}

const UserFormModal = ({
  open,
  handleClose,
  afterSaveUser,
  editionAdminUser = null,
}: UserFormModalProps) => {
  const { t } = useTranslation('common')
  const { authStore } = useContext(StoresContext)!
  const [addUserFormStore] = useState(
    () => new AddUserFormStore(authStore, !isNil(editionAdminUser))
  )

  useEffect(() => {
    if (!open) {
      // waits until modal is hidden to avoid showing empty data / validation errors
      setTimeout(() => addUserFormStore.reset(), 300)
    }
  }, [open])

  useEffect(() => {
    if (editionAdminUser) {
      addUserFormStore.fillEditionAdminUser(editionAdminUser)
    }
  }, [editionAdminUser])

  const handleChangeEmail = (val: string) => addUserFormStore.changeEmail(val)
  const handleChangePassword = (val: string) => addUserFormStore.changePassword(val)
  const handleChangeRePassword = (val: string) => addUserFormStore.changeRePassword(val)
  const handleChangeFirstName = (val: string) => addUserFormStore.changeFirstName(val)
  const handleChangeLastName = (val: string) => addUserFormStore.changeLastName(val)
  const handleChangeIsPhotographer = () => addUserFormStore.changeIsPhotographer()
  const handleChangeIsAthlete = () => addUserFormStore.changeIsAthlete()
  const handleChangeIsAdmin = () => addUserFormStore.changeIsAdmin()

  const handleSave = () => {
    if (isNil(editionAdminUser)) {
      addUserFormStore.saveUser().then(() => {
        afterSaveUser()
        toast.success('El usuario ha sido creado correctamente.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
    } else {
      addUserFormStore.editUser().then(() => {
        afterSaveUser()
        toast.success('El usuario ha sido editado correctamente.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
    }
  }

  return (
    <Modal
      okMessage={editionAdminUser === null ? t('Add').toString() : t('Save').toString()}
      loadingOk={addUserFormStore.isLoading}
      onCancel={handleClose}
      onSuccess={handleSave}
      opened={open}
      buttonsDisplay={ModalButtonsDisplay.ONLY_OK_BUTTON}
      modalSize={ModalSize.MEDIUM}
    >
      <div className="w-full grid grid-rows-1 gap-2">
        <div className="text-lg text-gray-800 font-bold text-center">
          {editionAdminUser === null ? t('New User') : t('Edit User')}
        </div>
        {addUserFormStore.error && <ServerError message={t(addUserFormStore.error)} />}
        <InputWrapper
          inputStore={addUserFormStore.firstName}
          onChange={handleChangeFirstName}
          placeholder={`${t('Enter First Name')}...`}
          label={t('Enter First Name').toString()}
          autoFocus
        />
        <InputWrapper
          inputStore={addUserFormStore.lastName}
          onChange={handleChangeLastName}
          placeholder={`${t('Enter Last Name')}...`}
          label={t('Enter Last Name').toString()}
          autoFocus
        />
        <InputWrapper
          inputStore={addUserFormStore.email}
          placeholder={`${t('Enter Email')}...`}
          onChange={handleChangeEmail}
          label={t('Enter Email').toString()}
        />
        {!editionAdminUser && (
          <>
            <InputWrapper
              inputStore={addUserFormStore.password}
              placeholder={`${t('Enter Password')}...`}
              onChange={handleChangePassword}
              label={t('Enter Password').toString()}
              type="password"
            />

            <InputWrapper
              onChange={handleChangeRePassword}
              inputStore={addUserFormStore.rePassword}
              label={t('Confirm Password').toString()}
              placeholder={`${t('Confirm Password')}...`}
              password={true}
            />
          </>
        )}

        <div className="flex items-center my-4" onClick={handleChangeIsPhotographer}>
          <div className="w-6 h-6 border-2 border-spotted-gold rounded bg-white text-spotted-gold">
            {addUserFormStore.isPhotographer && <CheckIcon />}
          </div>
          <div className="ml-4">
            <span>{t('Photographer')}</span>
          </div>
        </div>

        <div className="flex items-center my-4" onClick={handleChangeIsAthlete}>
          <div className="w-6 h-6 border-2 border-spotted-gold rounded bg-white text-spotted-gold">
            {addUserFormStore.isAthlete && <CheckIcon />}
          </div>
          <div className="ml-4">
            <span>{t('Athlete')}</span>
          </div>
        </div>

        <div className="flex items-center my-4" onClick={handleChangeIsAdmin}>
          <div className="w-6 h-6 border-2 border-spotted-gold rounded bg-white text-spotted-gold">
            {addUserFormStore.isAdmin && <CheckIcon />}
          </div>
          <div className="ml-4">
            <span>{t('Admin')}</span>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default withTranslation('common')(observer(UserFormModal))
