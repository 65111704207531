import { makeAutoObservable, runInAction } from 'mobx'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import AuthStore from 'stores/AuthStore'
import { Role, RoleType, User } from '../../../../models/User'
import InputStore from '../../../../components/UI/Forms/InputWrapper/InputStore'
import UserService from '../../../../services/UserService'

class AddUserFormStore {
  private readonly userService: UserService
  public id: string | null
  public firstName: InputStore<string>
  public lastName: InputStore<string>
  public email: InputStore<string>
  public password: InputStore<string>
  public rePassword: InputStore<string>
  public isPhotographer: boolean
  public isAthlete: boolean
  public isAdmin: boolean
  public roleTypeErrors: boolean
  public acceptTermsErrors: boolean
  public isLoading: boolean
  private isEditingUser: boolean
  public error: any
  constructor(private readonly authStore: AuthStore, isEditingUser = false) {
    this.authStore = authStore
    this.isEditingUser = isEditingUser
    this.reset()
    makeAutoObservable(this)
    this.userService = new UserService()
  }

  reset() {
    this.id = null
    this.firstName = new InputStore(yup.string().required('El nombre es requerido').max(255))
    this.lastName = new InputStore(yup.string().required('El apellido es requerido').max(255))
    this.email = new InputStore(
      yup.string().required('El email es requerido').email('Email no válido').max(255)
    )
    if (this.isEditingUser) {
      this.password = new InputStore(
        yup.string().min(6, 'La contraseña debe tener al menos 6 caracteres')
      )
    } else {
      this.password = new InputStore(
        yup
          .string()
          .required('La contraseña es requerida')
          .min(6, 'La contraseña debe tener al menos 6 caracteres')
      )
    }
    this.rePassword = new InputStore(yup.string().required('La contraseña es requerida'))
    this.isPhotographer = false
    this.isAthlete = false
    this.isAdmin = false
    this.roleTypeErrors = false
    this.isLoading = false
    this.error = null
  }

  fillEditionAdminUser(user: User) {
    this.id = user.id
    this.changeFirstName(user.firstName)
    this.changeLastName(user.lastName)
    this.changeEmail(user.email)
    this.changePassword(user.password)
    this.loadRoleUserToEdit(user.roles)
  }

  loadRoleUserToEdit(roles: Role[]) {
    roles.forEach((role) => {
      switch (role.type) {
        case RoleType.ADMIN:
          this.changeIsAdmin()
          break

        case RoleType.ATHLETE:
          this.changeIsAthlete()
          break

        case RoleType.PHOTOGRAPHER:
          this.changeIsPhotographer()
          break

        default:
          break
      }
    })
  }

  changeEmail(val: string) {
    this.email.setValue(val)
  }

  changePassword(val: string) {
    this.password.setValue(val)
  }

  changeRePassword(val: string) {
    this.rePassword.setValue(val)
  }

  changeFirstName(val: string) {
    this.firstName.setValue(val)
  }

  changeLastName(val: string) {
    this.lastName.setValue(val)
  }

  changeIsPhotographer() {
    this.isPhotographer = !this.isPhotographer
    this.roleTypeErrors = false
  }

  changeIsAthlete() {
    this.isAthlete = !this.isAthlete
    this.roleTypeErrors = false
  }

  changeIsAdmin() {
    this.isAdmin = !this.isAdmin
    this.roleTypeErrors = false
  }

  clearErrors() {
    this.email.clearError()
    this.password.clearError()
    this.rePassword.clearError()
    this.firstName.clearError()
    this.lastName.clearError()
    this.acceptTermsErrors = false
    this.roleTypeErrors = false
    this.error = null
  }

  async validate(isEditing = false) {
    this.clearErrors()
    let isValid = true

    if (!(await this.firstName.validate())) {
      isValid = false
    }

    if (!(await this.lastName.validate())) {
      isValid = false
    }

    if (!(await this.email.validate())) {
      isValid = false
    }

    if (!isEditing) {
      if (!(await this.password.validate())) {
        isValid = false
      }

      if (!(await this.rePassword.validate())) {
        isValid = false
      }

      if (this.password.value !== this.rePassword.value) {
        this.rePassword.errorMessage = 'La contraseñas deben coincidir'
        isValid = false
      }
    }

    if (!this.isPhotographer && !this.isAthlete && !this.isAdmin) {
      runInAction(() => {
        isValid = false
        this.roleTypeErrors = true
      })
      toast.warning('Para crear un usuario debes seleccionarle al menos un tipo de perfil', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    }

    return isValid
  }

  async saveUser() {
    if (!(await this.validate())) {
      return
    }

    runInAction(() => {
      this.isLoading = true
    })

    try {
      const newUser = new User()
      newUser.roles = []
      if (this.isPhotographer) {
        const photographerRole = new Role()
        photographerRole.type = RoleType.PHOTOGRAPHER
        newUser.roles.push(photographerRole)
      }
      if (this.isAthlete) {
        const athleteRole = new Role()
        athleteRole.type = RoleType.ATHLETE
        newUser.roles.push(athleteRole)
      }
      if (this.isAdmin) {
        const adminRole = new Role()
        adminRole.type = RoleType.ADMIN
        newUser.roles.push(adminRole)
      }

      newUser.firstName = this.firstName.value
      newUser.lastName = this.lastName.value
      newUser.email = this.email.value
      newUser.password = this.password.value

      const user = await this.userService.saveUser(this.authStore.getToken(), newUser)

      runInAction(() => {
        this.isLoading = false
      })

      return user
    } catch (e: any) {
      runInAction(() => {
        this.parseRequestErrors(e.response?.data?.errors || {})
        this.isLoading = false
        if (e?.message) {
          this.error = e?.message
        }
      })
    }

    return false
  }

  async editUser() {
    if (!(await this.validate(true))) {
      return
    }

    runInAction(() => {
      this.isLoading = true
    })

    try {
      const newUser = new User()

      newUser.roles = []
      if (this.isPhotographer) {
        const photographerRole = new Role()
        photographerRole.type = RoleType.PHOTOGRAPHER
        newUser.roles.push(photographerRole)
      }
      if (this.isAthlete) {
        const athleteRole = new Role()
        athleteRole.type = RoleType.ATHLETE
        newUser.roles.push(athleteRole)
      }
      if (this.isAdmin) {
        const adminRole = new Role()
        adminRole.type = RoleType.ADMIN
        newUser.roles.push(adminRole)
      }

      newUser.id = this.id!
      newUser.firstName = this.firstName.value
      newUser.lastName = this.lastName.value
      newUser.email = this.email.value
      newUser.password = this.password.value

      const user = await this.userService.editUser(this.authStore.getToken(), newUser)

      runInAction(() => {
        this.isLoading = false
      })

      return user
    } catch (e: any) {
      runInAction(() => {
        this.parseRequestErrors(e.response?.data?.errors || {})
        this.isLoading = false
        if (e?.message) {
          this.error = e?.message
        }
      })
    }

    return false
  }

  parseRequestErrors(messages: any) {
    const keys = Object.keys(messages)
    let displayError = false

    keys.forEach((key) => {
      const [error] = messages[key]

      switch (key) {
        case 'firstName':
          this.firstName.setError(true, error)
          displayError = true
          break

        case 'lastName':
          this.lastName.setError(true, error)
          displayError = true
          break

        case 'email':
          this.email.setError(true, error)
          displayError = true
          break

        case 'password':
          this.password.setError(true, error)
          displayError = true
          break

        default:
          break
      }
    })

    return displayError
  }
}

export default AddUserFormStore
