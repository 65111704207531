import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import axios from '../../../services/axios'
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler'
import PurchasesDashboardValueComponent from './PurchasesDashboardValueComponent'

const PurchasesDashboard = ({ revenueStore, spottedEarningsStore, photographersStore }) => {
  return (
    <div className="mb-5 grid grid-cols-3 gap-5 auto-rows-fr">
      <PurchasesDashboardValueComponent formStore={revenueStore} />
      <PurchasesDashboardValueComponent formStore={spottedEarningsStore} />
      <PurchasesDashboardValueComponent formStore={photographersStore} />
    </div>
  )
}

export default withErrorHandler(withTranslation()(observer(PurchasesDashboard)), axios)
