import React from 'react'
import UploadCoverPhotograph from './UploadCoverPhotograph'
import { useTranslation } from 'react-i18next'
import Modal from '../Modal'
import { ModalButtonsDisplay, ModalSize } from '../Modal/Modal'
import { Event } from 'models/Event'

type UploadCoverPhotographModalProps = {
  handleClose: () => void
  open: boolean
  event: Event
}

const UploadCoverPhotographModal = ({
  handleClose,
  open,
  event,
}: UploadCoverPhotographModalProps) => {
  const { t } = useTranslation()
  return (
    <Modal
      okMessage={t('Save')}
      onCancel={handleClose}
      onSuccess={handleClose}
      opened={open}
      buttonsDisplay={ModalButtonsDisplay.ONLY_OK_BUTTON}
      modalSize={ModalSize.SMALL}
    >
      <div className="flex flex-col">
        <h1 className="font-bold text-lg">{t('Add cover photograph')}</h1>
        <UploadCoverPhotograph event={event} />
      </div>
    </Modal>
  )
}

export default UploadCoverPhotographModal
