import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation, withTranslation } from 'react-i18next'
import { UserPlusIcon } from '@heroicons/react/20/solid'
import AdminUserRow from './UserRow'
import SearchResultListNoResults from '../../../components/SearchResultListNoResults/SearchResultListNoResults'
import { User } from '../../../models/User'
import Spinner from '../../../components/UI/Spinner/Spinner'

type UsersListProps = {
  users: User[]
  isLoading: boolean
  handleClickEdit: (user: User) => void
  handleClickSuspend: (user: User) => void
  handleClickActivate: (user: User) => void
  handleClickDelete: (user: User) => void
  handleAddUser: () => void
}
const UsersList = ({
  users = [],
  isLoading = false,
  handleClickEdit,
  handleClickSuspend,
  handleClickActivate,
  handleClickDelete,
  handleAddUser,
}: UsersListProps) => {
  const { t } = useTranslation()
  if (isLoading) {
    return (
      <div className="container flex justify-center">
        <div className="mt-14">
          <Spinner size={11} color="text-spotted-gold" />
        </div>
      </div>
    )
  }

  if (users.length === 0) {
    return (
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className="flex flex-col items-center justify-center my-10">
          <SearchResultListNoResults />
          <div className="bg-teal-100 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md w-1/2 flex justify-center mt-8">
            <div className="flex">
              <span className="font-bold mr-4">{t('Add User')}</span>
              <UserPlusIcon
                className="text-primary-light h-6 w-6 cursor-pointer transition-colors hover:text-primary-dark"
                onClick={handleAddUser}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr className="font-bold">
            <th scope="col" className="px-6 py-3 text-left text-xs text-gray-500 uppercase">
              {t('Email')}
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {t('Name')}
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {t('Status')}
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {t('Roles')}
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              <div className="flex flex-col items-baseline">
                <span>MercadoPago</span>
                <span>Sync</span>
              </div>
            </th>
            <th scope="col" className="relative px-6 py-3 flex justify-end">
              <UserPlusIcon
                className="text-primary-light h-6 w-6 cursor-pointer transition-colors hover:text-primary-dark"
                onClick={handleAddUser}
              />
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {users.map((user, index) => (
            <AdminUserRow
              key={index}
              user={user}
              handleClickDelete={handleClickDelete}
              handleClickEdit={handleClickEdit}
              handleClickSuspend={handleClickSuspend}
              handleClickActivate={handleClickActivate}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default withTranslation()(observer(UsersList))
