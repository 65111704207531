import { makeAutoObservable, runInAction } from 'mobx'
import AuthStore from '../../stores/AuthStore'
import ActivityService from '../../services/ActivityService'
import Paginator from '../../stores/Paginator'
import { Activity } from 'models/Activity'
import { isNil } from 'lodash'

class ActivityStore {
  public activities: Activity[]
  public allActivities: Activity[]
  public isLoading: boolean
  public isDeleting: boolean
  public error: any
  public paginator: Paginator
  private authStore: AuthStore
  private activityService: ActivityService
  constructor(authStore: AuthStore) {
    this.reset()
    makeAutoObservable(this)
    this.activityService = new ActivityService()
    this.authStore = authStore
  }

  reset() {
    this.activities = []
    this.isLoading = false
    this.isDeleting = false
    this.error = false
  }

  async fetchAdminActivities(page: number, search?: string) {
    //TODO: search is activityName ?
    runInAction(() => {
      this.isLoading = true
    })
    try {
      const response = await this.activityService.searchAdminActivities(
        //TODO: change search for fetch?
        this.authStore.getAdminToken(),
        page,
        search
      )
      runInAction(() => {
        this.isLoading = false
        this.activities = response.activities
        this.paginator = response.paginator
        this.error = null
      })
      return response
    } catch (e) {
      this.error = e
      this.isLoading = false
    }
  }

  async fetchAllAdminActivities() {
    runInAction(() => {
      this.isLoading = true
    })
    try {
      const response = await this.activityService.fetchActivities(this.authStore.getAdminToken())
      runInAction(() => {
        this.isLoading = false
        this.allActivities = response
        this.error = null
      })
      return response
    } catch (e) {
      this.error = e
      this.isLoading = false
    }
  }

  async mergeActivity(activityToDelete: Activity | null, newActivity: Activity) {
    runInAction(() => {
      this.isDeleting = true
    })
    try {
      await this.activityService.mergeActivity(
        !isNil(activityToDelete) ? activityToDelete.id : null,
        newActivity?.id,
        this.authStore.getAdminToken()
      )
      runInAction(() => {
        this.isDeleting = false
      })
    } catch (e: any) {
      this.error = e
      this.isDeleting = false
      return e?.message
    }
  }

  //TODO: add type to param
  async deleteActivity(activityToDelete) {
    runInAction(() => {
      this.isDeleting = true
    })
    try {
      const numberOfAlbums = await this.activityService.getAlbumsCountByActivity(
        activityToDelete.id,
        this.authStore.getAdminToken()
      )
      if (numberOfAlbums === 0) {
        await this.activityService.deleteActivity(
          activityToDelete.id,
          this.authStore.getAdminToken()
        )
      } else {
        const errorMessage =
          //TODO: it must handle the case where numberOfAlbums === 1 instead ??
          numberOfAlbums > 1
            ? `Cannot delete activity ${activityToDelete.activityName}. It has ${numberOfAlbums} albums.`
            : `Cannot delete activity ${activityToDelete.activityName}. It has ${numberOfAlbums} album.`
        throw new Error(errorMessage)
      }
      runInAction(() => {
        this.isDeleting = false
        this.fetchAdminActivities(0)
      })
    } catch (e: any) {
      this.error = e
      this.isDeleting = false
      return e?.message
    }
  }

  async updateActivity(activityToUpate: Activity) {
    try {
      await this.activityService.editActivity(activityToUpate, this.authStore.getAdminToken())
      runInAction(() => {
        this.fetchAdminActivities(1)
      })
    } catch (e: any) {
      this.error = e
      return e?.message
    }
  }
}

export default ActivityStore
