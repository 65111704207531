import { makeAutoObservable, runInAction } from 'mobx'
import Album from '../../models/Album'
import { Photograph } from '../../models/Photograph'
import PhotographService from '../../services/PhotographService'
import AlbumService from '../../services/AlbumService'
import AuthStore from '../../stores/AuthStore'

class AdminAlbumsPhotographsStore {
  public album: Album | null
  public photographs: Photograph[]
  public paginator: any
  public isLoading: boolean
  public isDeleting: boolean
  public error: any
  private photographsService: PhotographService
  private albumService: AlbumService
  constructor(private readonly authStore: AuthStore) {
    this.reset()
    makeAutoObservable(this)
    this.photographsService = new PhotographService()
    this.albumService = new AlbumService()
  }

  reset() {
    this.album = null
    this.photographs = []
    this.paginator = null
    this.isLoading = false
    this.isDeleting = false
    this.error = false
  }

  async fetchAlbum(albumId: string) {
    runInAction(() => {
      this.isLoading = true
    })
    try {
      const album = await this.albumService.fetchAlbum(albumId, this.authStore.getToken())
      runInAction(() => {
        this.isLoading = false
        this.album = album
      })
      return album
    } catch (e) {
      this.error = e
      this.isLoading = false
    }
  }

  async fetchAdminAlbumsPhotographs(albumId: string, page: number) {
    runInAction(() => {
      this.isLoading = true
    })
    try {
      const response = await this.photographsService.fetchAdminAlbumsPhotographs(
        albumId,
        this.authStore.getToken(),
        page
      )
      runInAction(() => {
        this.isLoading = false
        this.photographs = response.photographs
        this.paginator = response.paginator
        this.error = null
      })
      return response
    } catch (e) {
      this.error = e
      this.isLoading = false
    }
  }

  async suspendPhotograph(photographId: string) {
    runInAction(() => {
      this.isLoading = true
    })
    try {
      await this.photographsService.suspendPhotograph(this.authStore.getToken(), photographId)
      runInAction(() => {
        this.isLoading = false
      })
    } catch (e) {
      this.error = e
      this.isLoading = false
    }
  }

  async downloadPhotograph(photographId: string) {
    runInAction(() => {
      this.isLoading = true
    })
    try {
      await this.photographsService.getAdminPhotographDownload(
        this.authStore.getAdminToken(),
        photographId
      )
      runInAction(() => {
        this.isLoading = false
      })
    } catch (e) {
      this.error = e
      this.isLoading = false
    }
  }

  async photographDownloadLink(photographId: string): Promise<string> {
    try {
      const photographLink = await this.photographsService.getAdminPhotographDownloadUrl(
        this.authStore.getAdminToken(),
        photographId
      )
      return photographLink
    } catch (e) {
      this.error = e
      this.isLoading = false
    }
    return ''
  }

  async activatePhotograph(photographId: string) {
    runInAction(() => {
      this.isLoading = true
    })
    try {
      const photographActivated = await this.photographsService.activatePhotograph(
        this.authStore.getToken(),
        photographId
      )
      runInAction(() => {
        this.isLoading = false
      })
      return photographActivated
    } catch (e) {
      this.error = e
      this.isLoading = false
    }
  }

  async deletePhotograph(photographId: string) {
    runInAction(() => {
      this.isLoading = true
      this.isDeleting = true
    })
    try {
      await this.photographsService.deletePhotograph(photographId, this.authStore.getToken())
      runInAction(() => {
        this.isDeleting = false
        this.isLoading = false
      })
    } catch (e) {
      this.error = e
      this.isDeleting = false
      this.isLoading = false
    }
  }
}

export default AdminAlbumsPhotographsStore
