import React, { useState, useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import AdminPurchasesStore from './AdminPurchasesStore'
import { useTranslation, withTranslation } from 'react-i18next'
import AdminPurchaseList from './components/AdminPurchaseList'
import PurchaseDeleteModal from './components/PurchaseDeleteModal'
import { isNil } from 'lodash'
import { useQuery } from '../../hooks/useQuery'
import StoresContext from '../../providers/storesContext'
import { Purchase, PurchaseStatus } from '../../models/Purchase'
import { PurchaseDashboardKpi } from '../../models/PurchaseDashboardKpi'
import Spinner from '../../components/UI/Spinner/Spinner'
import ResultsPager from '../../components/UI/ResultsPager'
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler'
import axios from '../../services/axios'
import PurchaseListFilters from './components/PurchaseListFilters'
import PurchasesDashboard from '../Dashboard/components/PurchasesDashboard'
import RevenueStore from 'containers/Dashboard/containers/Revenue/RevenueStore'
import SpottedEarningsStore from 'containers/Dashboard/containers/SpottedEarnings/SpottedEarningsStore'
import PhotographersEarningsStore from 'containers/Dashboard/containers/PhotographersEarnings/PhotographersEarningsStore'
import AdminAlbumsPhotographsStore from 'containers/AdminAlbumsPhotographs/AdminAlbumsPhotographsStore'

type AdminPurchasesProps = {
  t: any
}

const AdminPurchases = (props: AdminPurchasesProps) => {
  const { t } = useTranslation()
  const query = useQuery()
  const { authStore } = useContext(StoresContext)!
  const [adminAlbumPhotographsStore] = useState(() => new AdminAlbumsPhotographsStore(authStore))
  const [adminPurchaseStore] = useState(() => new AdminPurchasesStore(authStore))
  const [revenueStore] = useState(() => new RevenueStore(authStore, new PurchaseDashboardKpi()))
  const [earningsStore] = useState(
    () => new SpottedEarningsStore(authStore, new PurchaseDashboardKpi())
  )
  const [photographersStore] = useState(
    () => new PhotographersEarningsStore(authStore, new PurchaseDashboardKpi())
  )
  const [purchaseDashboardKpi] = useState(() => [
    revenueStore.kpi,
    earningsStore.kpi,
    photographersStore.kpi,
  ])
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)
  const [purchaseToDeleteId, setPurchaseToDeleteId] = useState<string | null>(null)

  const page = Number(query.get('page')) || 1

  const onSearch = (
    dateFrom?: Date,
    dateTo?: Date,
    ownerId?: string,
    sellerId?: string,
    status?: PurchaseStatus
  ): void => {
    revenueStore.updateData(ownerId, sellerId, dateFrom, dateTo, status)
    earningsStore.updateData(ownerId, sellerId, dateFrom, dateTo, status)
    photographersStore.updateData(ownerId, sellerId, dateFrom, dateTo, status)
    adminPurchaseStore.fetchPurchasesOwners()
    adminPurchaseStore.fetchPurchasesSellers()
    adminPurchaseStore.fetchAdminPurchases(1)
  }

  //Delete Purchase
  const handleClickDelete = (purchase: Purchase) => {
    showDeleteConfirm(purchase)
  }

  const showDeleteConfirm = (purchase: Purchase) => {
    setVisibleDeleteModal(true)
    setPurchaseToDeleteId(purchase.id)
  }

  const hideDeleteConfirm = () => {
    setVisibleDeleteModal(false)
  }

  const handleDeleteAdminPurchase = () => {
    if (!isNil(purchaseToDeleteId)) {
      adminPurchaseStore.deletePurchase(purchaseToDeleteId).then(() => {
        hideDeleteConfirm()
        adminPurchaseStore.fetchAdminPurchases(page)
      })
    }
  }

  //Download original Photograph
  const handleClickDownload = (photographId: string) => {
    adminAlbumPhotographsStore.downloadPhotograph(photographId)
  }

  //Approve Purchase
  const handleClickApprove = (purchase: Purchase) => {
    adminPurchaseStore.approvePurchase(purchase.id).then((result) => {
      if (result) {
        adminPurchaseStore.fetchAdminPurchases(page)
      }
    })
  }

  //Reject Purchase
  const handleClickReject = (purchase: Purchase) => {
    adminPurchaseStore.rejectPurchase(purchase.id).then((result) => {
      if (result) {
        adminPurchaseStore.fetchAdminPurchases(page)
      }
    })
  }

  useEffect(() => {
    adminPurchaseStore.fetchAdminPurchases(page)
  }, [page])

  return (
    <div className="flex flex-col">
      <h1 className="mb-5 text-center text-2xl">
        <b>{t('Purchases')}</b>
      </h1>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <PurchasesDashboard
            revenueStore={revenueStore}
            spottedEarningsStore={earningsStore}
            photographersStore={photographersStore}
          />
          <PurchaseListFilters
            store={adminPurchaseStore}
            kpis={purchaseDashboardKpi}
            onSearch={onSearch}
            showStatus={true}
          />
          <div className={'text-3xl'}>
            <span>
              {adminPurchaseStore.isLoading ? (
                <div className="container flex justify-center">
                  <div className="mt-14">
                    <Spinner size={10} color="text-spotted-gold" />
                  </div>
                </div>
              ) : (
                <AdminPurchaseList
                  purchases={adminPurchaseStore.purchases}
                  isLoading={adminPurchaseStore.isLoading}
                  handleClickDownload={handleClickDownload}
                  handleClickApprove={handleClickApprove}
                  handleClickReject={handleClickReject}
                  handleClickDelete={handleClickDelete}
                />
              )}
            </span>
          </div>
          {adminPurchaseStore.purchases.length > 0 && !adminPurchaseStore.isLoading && (
            <ResultsPager paginator={adminPurchaseStore.paginator} query={query} />
          )}
        </div>
      </div>
      <PurchaseDeleteModal
        handleDelete={handleDeleteAdminPurchase}
        handleClose={hideDeleteConfirm}
        open={visibleDeleteModal}
        isDeleting={adminPurchaseStore.isDeleting}
        error={adminPurchaseStore.error}
        t={props.t}
      />
    </div>
  )
}

export default withErrorHandler(withTranslation()(observer(AdminPurchases)), axios)
