import axios from './axios'
import * as paths from '../services/Paths'
import { AxiosResponse } from 'axios'
import { parseAxiosErrorResponse } from '../utility'

class DashboardService {
  getAthletePurchaseQuantity(days: number, token: string, athleteId: string) {
    return axios
      .get(paths.athletePurchaseQuantityIById(athleteId), {
        params: {
          days,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }: AxiosResponse) => data)
      .catch(parseAxiosErrorResponse)
  }

  getAlbums(days: number, token: string) {
    return axios
      .get(paths.DASHBOARD_ALBUMS, {
        params: {
          days,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }: AxiosResponse) => data)
      .catch(parseAxiosErrorResponse)
  }

  getUploadedPhotographs(days: number, token: string) {
    return axios
      .get(paths.DASHBOARD_UPLOADED_PHOTOGRAPHS, {
        params: {
          days,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => data)
      .catch(parseAxiosErrorResponse)
  }

  getUsersTypeRole(token: string) {
    return axios
      .get(paths.DASHBOARD_USERS_TYPE_ROLE, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }: AxiosResponse) => data)
      .catch(parseAxiosErrorResponse)
  }

  getAlbumsLocation(token: string) {
    return axios
      .get(paths.DASHBOARD_ALBUMS_LOCATION, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => data)
      .catch(parseAxiosErrorResponse)
  }
}

export default DashboardService
