import React, { useState, useEffect, useContext } from 'react'
import { observer } from 'mobx-react'
import { withTranslation, useTranslation } from 'react-i18next'
import StoresContext from '../../../../providers/storesContext'
import AlbumsLocationStore from './AlbumsLocationStore'
import Doughnut from '../../components/Doughnut'
import Spinner from '../../../../components/UI/Spinner/Spinner'

const AlbumsLocation = () => {
  const { t } = useTranslation()
  const { authStore } = useContext(StoresContext)!
  const [albumsLocationStore] = useState(() => new AlbumsLocationStore(authStore))

  const { data, albumsLocation } = albumsLocationStore

  const fetchAlbumsData = async () => {
    await albumsLocationStore.fetchData()
  }
  useEffect(() => {
    fetchAlbumsData()
  }, [])

  if (albumsLocationStore.isLoading) {
    return (
      <div className="bg-white p-5 rounded-lg">
        <div className="flex flex-col">
          <div className="flex flex-row justify-between items-center text-gray-800 pb-8">
            <p className="pb-6">
              <span className="font-bold">{t('Albums Location')}</span>
            </p>
          </div>
          <div className="mx-auto pt-4">
            <Spinner color="text-primary-light" size={10} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="bg-white p-5 rounded-lg">
      <div className="flex flex-col">
        <p className="pb-6">
          <span className="font-bold">{t('Albums Location')}</span>
        </p>
        {data && albumsLocation?.length > 0 ? (
          <Doughnut data={data} />
        ) : (
          <p>{t('No information for Albums Location')}</p>
        )}
      </div>
    </div>
  )
}

export default withTranslation('common')(observer(AlbumsLocation))
