import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation, withTranslation } from 'react-i18next'
import StoresContext from '../../../../providers/storesContext'
import Modal, { ModalButtonsDisplay, ModalSize } from '../../../../components/UI/Modal/Modal'
import ServerError from '../../../../components/UI/ServerError'
import Spinner from '../../../../components/UI/Spinner/Spinner'
import { Event } from '../../../../models/Event'
import { isNil } from 'lodash'
import LocationService from 'services/LocationService'
import ActivityService from 'services/ActivityService'
import { Activity } from 'models/Activity'
import { Location } from 'models/Location'
import { CurrencyEnum } from 'models/CurrencyEnum'
import { Autocomplete, Checkbox, TextField } from '@mui/material'
import EventStore from 'containers/Events/EventsStore'
import UploadCoverPhotograph from 'components/UI/ImageUploader/UploadCoverPhotograph'
import { useFormik } from 'formik'
import getEventValidationSchema from './EventValidationSchema'
import moment from 'moment'
import CreateOrUpdateEventRequestInterface from 'services/Interfaces/Event/CreateEventRequest.interface'

type EventFormModalType = {
  open: boolean
  handleClose: (event?: Event) => void
  eventToEdit?: Event | null
  eventStore: EventStore
}

const EventFormModal = ({
  open,
  handleClose,
  eventToEdit = null,
  eventStore,
}: EventFormModalType) => {
  const { authStore } = useContext(StoresContext)!
  const { t } = useTranslation()
  const locationService = new LocationService()
  const activityService = new ActivityService()
  const [locations, setLocations] = useState<Location[]>([])
  const [activities, setActivities] = useState<Activity[]>([])
  const [currencies, setCurrencies] = useState<CurrencyEnum[]>([])

  useEffect(() => {
    loadLocations()
    loadActivities()
    loadCurrencies()
  }, [])

  //TODO: Move this to LocationStore
  const loadLocations = async () => {
    const locationsList = await locationService.fetchLocations(authStore.getAdminToken())
    setLocations(locationsList)
  }
  //TODO: Move this to ActivityStore
  const loadActivities = async () => {
    const activitiesList = await activityService.fetchActivities(authStore.getAdminToken())
    setActivities(activitiesList)
  }
  const loadCurrencies = async () => {
    const currenciesList = Object.values(CurrencyEnum)
    setCurrencies(currenciesList)
  }

  const initialValues: CreateOrUpdateEventRequestInterface = !isNil(eventToEdit)
    ? {
        id: eventToEdit.id || '',
        name: eventToEdit?.name || '',
        description: eventToEdit?.description || '',
        landingPath: eventToEdit?.landingPath || '',
        activityId: eventToEdit?.activity?.id || '',
        locationId: eventToEdit?.location?.id || '',
        date: eventToEdit?.date || new Date(new Date().setHours(0, 0, 0, 0)),
        currency: eventToEdit?.currency || '',
        commission: eventToEdit?.commission || 20,
        defaultImagePrice: eventToEdit?.defaultImagePrice || 0,
        defaultPackagePrice: eventToEdit?.defaultPackagePrice || undefined,
        quantityDiscountId: eventToEdit?.quantityDiscountId || undefined,
        displayInFeed: eventToEdit?.displayInFeed || false,
        isFree: eventToEdit?.isFree || false,
        accreditationsEnabled: eventToEdit?.accreditationsEnabled || false,
        faceRecognitionFilter: eventToEdit?.faceRecognitionFilter || false,
        textFilter: eventToEdit?.textFilter || false,
        filtersView: eventToEdit?.filtersView || false,
        participants: eventToEdit?.participants || undefined,
        customBannerSrc: eventToEdit?.customBannerSrc || undefined,
      }
    : {
        name: '',
        description: '',
        landingPath: '',
        activityId: '',
        locationId: '',
        date: new Date(new Date().setHours(0, 0, 0, 0)),
        currency: undefined,
        commission: 20,
        defaultImagePrice: 0,
        defaultPackagePrice: undefined,
        quantityDiscountId: undefined,
        displayInFeed: true,
        isFree: false,
        accreditationsEnabled: false,
        faceRecognitionFilter: true,
        textFilter: true,
        filtersView: true,
        participants: undefined,
        customBannerSrc: undefined,
      }

  const formik = useFormik({
    initialValues,
    validationSchema: getEventValidationSchema(),
    onSubmit: (values) => {
      if (isNil(eventToEdit)) {
        if (typeof values.defaultPackagePrice === 'string') {
          values.defaultPackagePrice = undefined
        }
        eventStore.createEvent(values).then((newEvent) => {
          if (newEvent) {
            handleClose(newEvent)
          }
        })
      } else {
        eventStore.editEvent(values).then((updatedEvent) => {
          if (updatedEvent) {
            handleClose()
            eventStore.searchAdminEvents(1, 25)
          }
        })
      }
    },
  })

  const activityOptions = activities
    .map((activity) => {
      return { value: activity.id, label: t(activity.name).toUpperCase() }
    })
    .sort((a, b) => -b.label[0].localeCompare(a.label[0]))

  const locationOptions = locations
    .map((location) => {
      return { value: location.id, label: location.spotName.toUpperCase() }
    })
    .sort((a, b) => -b.label[0].localeCompare(a.label[0]))

  if (eventStore.isLoading) {
    return (
      <div className="container flex justify-center">
        <div className="mt-14">
          <Spinner size={11} color="text-spotted-gold" />
        </div>
      </div>
    )
  }

  return (
    <Modal
      okMessage={isNil(eventToEdit) ? t('Add') : t('Save')}
      loadingOk={eventStore.isLoading}
      onCancel={handleClose}
      onSuccess={formik.handleSubmit}
      opened={open}
      buttonsDisplay={ModalButtonsDisplay.ONLY_OK_BUTTON}
      modalSize={ModalSize.MEDIUM}
    >
      <div className="w-full grid grid-rows-1 gap-3">
        <div className="text-xl text-gray-800 font-bold text-start capitalize">
          {isNil(eventToEdit) ? t('New Event') : t('Edit Event')}
        </div>
        {eventStore.error && <ServerError message={t(eventStore.error?.message)} />}
        <div className="w-full flex flex-col md:flex-row items-center gap-2">
          <div className={`w-full ${!isNil(eventToEdit) ? 'md:w-1/3' : 'md:w-1/2'}`}>
            <TextField
              fullWidth
              variant="outlined"
              id="name"
              name="name"
              label={t('Name')}
              required
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </div>
          <div className={`w-full ${!isNil(eventToEdit) ? 'md:w-2/3' : 'md:w-1/2'}`}>
            <TextField
              fullWidth
              variant="outlined"
              id="description"
              name="description"
              label={t('Description')}
              required
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
            />
          </div>
        </div>
        <div className="w-full flex flex-col md:flex-row gap-3 items-top">
          {!isNil(eventToEdit) && (
            <div className="w-full md:w-1/3">
              <p className="text-gray-400 font-bold text-sm mb-1">{t('Cover photograph')}</p>
              <UploadCoverPhotograph event={eventToEdit} />
            </div>
          )}
          <div
            className={`w-full flex flex-col justify-between gap-3 ${
              !isNil(eventToEdit) && 'md:w-2/3'
            }`}
          >
            <div className={`w-full md:flex gap-3 ${!isNil(eventToEdit) && 'md:flex-col'}`}>
              <div className={`w-full mb-3 ${isNil(eventToEdit) ? 'md:w-1/2' : 'md:mt-6'}`}>
                <TextField
                  fullWidth
                  id="date"
                  name="date"
                  label={t('Date')}
                  required
                  InputLabelProps={{ shrink: true }}
                  type="Date"
                  value={moment.utc(formik.values.date).format('YYYY-MM-DD')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.date && Boolean(formik.errors.date)}
                  helperText={
                    formik.touched.date
                      ? typeof formik.errors.date === 'string'
                        ? formik.errors.date
                        : ''
                      : ''
                  }
                />
              </div>
              <div className={`w-full ${isNil(eventToEdit) && 'md:w-1/2'}`}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="landingPath"
                  name="landingPath"
                  label={t('Landing path')}
                  required
                  value={formik.values.landingPath}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.landingPath && Boolean(formik.errors.landingPath)}
                  helperText={formik.touched.landingPath && formik.errors.landingPath}
                />
              </div>
            </div>
            <div className={`w-full md:flex gap-3 ${!isNil(eventToEdit) && 'md:flex-col'}`}>
              <div className={`w-full mb-3 ${isNil(eventToEdit) ? 'md:w-1/2' : 'md:mb-3'}`}>
                <Autocomplete
                  options={activityOptions}
                  value={
                    activityOptions.find(
                      (activity) => activity.value === formik.values.activityId
                    ) || null
                  }
                  getOptionLabel={(option) => option.label}
                  onChange={(activity, newValue) => {
                    formik.setFieldValue('activityId', newValue ? newValue.value : '')
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      name="activityId"
                      id="activityId"
                      label={t('Sport')}
                      required
                      error={formik.touched.activityId && Boolean(formik.errors.activityId)}
                      helperText={formik.touched.activityId && formik.errors.activityId}
                    />
                  )}
                />
              </div>
              <div className={`w-full ${isNil(eventToEdit) && 'md:w-1/2'}`}>
                <Autocomplete
                  options={locationOptions}
                  value={
                    locationOptions.find(
                      (location) => location.value === formik.values.locationId
                    ) || null
                  }
                  getOptionLabel={(option) => option.label}
                  onChange={(location, newValue) => {
                    formik.setFieldValue('locationId', newValue ? newValue.value : '')
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      name="locationId"
                      id="locationId"
                      label={t('Location')}
                      required
                      error={formik.touched.locationId && Boolean(formik.errors.locationId)}
                      helperText={formik.touched.locationId && formik.errors.locationId}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex gap-3 items-center mb-2">
          <div className="flex items-center gap-1 mt-1">
            <p className="text-sm font-bold text-gray-400">{t('Free Event')}</p>
            <Checkbox
              onChange={() => formik.setFieldValue('isFree', !formik.values.isFree)}
              checked={formik.values.isFree}
            />
          </div>
          <div className="flex items-center gap-1 mt-1">
            <p className="text-sm font-bold text-gray-400">{t('Accreditations enabled')}</p>
            <Checkbox
              onChange={() =>
                formik.setFieldValue('accreditationsEnabled', !formik.values.accreditationsEnabled)
              }
              checked={formik.values.accreditationsEnabled}
            />
          </div>
          <div className="flex items-center gap-1 mt-1">
            <p className="text-sm font-bold text-gray-400">{t('Display in feed')}</p>
            <Checkbox
              onChange={() => formik.setFieldValue('displayInFeed', !formik.values.displayInFeed)}
              checked={formik.values.displayInFeed}
            />
          </div>
          <div className="flex items-center gap-1 mt-1">
            <p className="text-sm font-bold text-gray-400">{t('Face Recognition Filter')}</p>
            <Checkbox
              onChange={() =>
                formik.setFieldValue('faceRecognitionFilter', !formik.values.faceRecognitionFilter)
              }
              checked={formik.values.faceRecognitionFilter}
            />
          </div>
          <div className="flex items-center gap-1 mt-1">
            <p className="text-sm font-bold text-gray-400">{t('Text Filter')}</p>
            <Checkbox
              onChange={() => formik.setFieldValue('textFilter', !formik.values.textFilter)}
              checked={formik.values.textFilter}
            />
          </div>
          <div className="flex items-center gap-1 mt-1">
            <p className="text-sm font-bold text-gray-400">{t('Filters View')}</p>
            <Checkbox
              onChange={() => formik.setFieldValue('filtersView', !formik.values.filtersView)}
              checked={formik.values.filtersView}
            />
          </div>
        </div>
        {!formik.values.isFree && (
          <>
            <div className="w-full flex gap-3 flex-col md:flex-row">
              <div className="w-full md:w-1/4">
                <Autocomplete
                  options={currencies}
                  value={currencies.find((currency) => currency === formik.values.currency) || null}
                  onChange={(event, newValue) => {
                    formik.setFieldValue('currency', newValue ? newValue : undefined)
                  }}
                  getOptionLabel={(option) => (option ? option.toUpperCase() : '')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      name="currency"
                      id="currency"
                      label={t('Currency')}
                      required
                      error={formik.touched.currency && Boolean(formik.errors.currency)}
                      helperText={formik.touched.currency && formik.errors.currency}
                    />
                  )}
                />
              </div>
              <div className="w-full md:w-1/4">
                <TextField
                  id="defaultImagePrice"
                  name="defaultImagePrice"
                  label={t('Default Image Price')}
                  fullWidth
                  required
                  type="number"
                  value={formik.values.defaultImagePrice}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.defaultImagePrice && Boolean(formik.errors.defaultImagePrice)
                  }
                  helperText={formik.touched.defaultImagePrice && formik.errors.defaultImagePrice}
                />
              </div>
              <div className="w-full md:w-1/4">
                <TextField
                  id="defaultPackagePrice"
                  name="defaultPackagePrice"
                  label={t('Default Package Price')}
                  type="number"
                  fullWidth
                  value={formik.values.defaultPackagePrice}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.defaultPackagePrice && Boolean(formik.errors.defaultPackagePrice)
                  }
                  helperText={
                    formik.touched.defaultPackagePrice && formik.errors.defaultPackagePrice
                  }
                />
              </div>
              <div className="w-full md:w-1/4">
                <TextField
                  id="commission"
                  name="commission"
                  label={t('Commission')}
                  type="number"
                  required
                  fullWidth
                  value={formik.values.commission}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.commission && Boolean(formik.errors.commission)}
                  helperText={formik.touched.commission && formik.errors.commission}
                />
              </div>
            </div>
            <TextField
              fullWidth
              variant="outlined"
              id="quantityDiscountId"
              name="quantityDiscountId"
              label={t('QuantityDiscountId')}
              value={formik.values.quantityDiscountId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.quantityDiscountId && Boolean(formik.errors.quantityDiscountId)}
              helperText={formik.touched.quantityDiscountId && formik.errors.quantityDiscountId}
            />
          </>
        )}
        <div className="w-full flex flex-col md:flex-row gap-3">
          <div className="w-full md:w-1/2">
            <TextField
              fullWidth
              variant="outlined"
              id="participants"
              name="participants"
              label={t('Participants')}
              value={formik.values.participants}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.participants && Boolean(formik.errors.participants)}
              helperText={formik.touched.participants && formik.errors.participants}
            />
          </div>
          <div className="w-full md:w-1/2">
            <TextField
              fullWidth
              variant="outlined"
              id="customBannerSrc"
              name="customBannerSrc"
              label={t('Custom banner src')}
              value={formik.values.customBannerSrc}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.customBannerSrc && Boolean(formik.errors.customBannerSrc)}
              helperText={formik.touched.customBannerSrc && formik.errors.customBannerSrc}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default withTranslation('common')(observer(EventFormModal))
