import React from 'react'
import { CheckBadgeIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/20/solid'
import limitChars from '../../../utility'

const ActivityRow = ({ activity, handleClickDelete, handleUndoDelete, handleClickEdit }) => {
  return (
    <tr className="even:bg-gray-100 odd:white text-gray-600 hover:bg-gray-200 transition-colors">
      <td className="px-6 py-4 text-sm font-medium">{limitChars(activity.name, 25)}</td>
      <td className="px-6 py-4 text-sm font-medium">{limitChars(activity.createdAt, 25)}</td>
      <td className="px-6 py-4 text-sm font-medium">
        {activity.deletedAt ? limitChars(activity.deletedAt, 25) : 'N/A'}
      </td>
      <td className="px-6 py-4 text-sm font-medium">{activity.isApproved}</td>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium flex justify-end">
        <>
          <PencilSquareIcon
            className="text-gray-500 h-5 w-5 cursor-pointer transition-colors hover:text-gray-400"
            onClick={() => handleClickEdit(activity)}
          />
          {activity.deletedAt ? (
            <CheckBadgeIcon
              className="text-gray-500 h-5 w-5 cursor-pointer transition-colors hover:text-gray-400"
              onClick={() => handleUndoDelete(activity)}
              title="Undo delete"
            />
          ) : (
            <TrashIcon
              className="text-gray-500 h-5 w-5 cursor-pointer transition-colors hover:text-gray-400"
              onClick={() => handleClickDelete(activity)}
              title="Delete"
            />
          )}
        </>
      </td>
    </tr>
  )
}

export default ActivityRow
