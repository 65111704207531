export enum CurrencyEnum {
  USD = 'usd',
  AED = 'aed',
  AFN = 'afn',
  ALL = 'all',
  AMD = 'amd',
  ANG = 'ang',
  AOA = 'aoa',
  ARS = 'ars',
  AUD = 'aud',
  AWG = 'awg',
  AZN = 'azn',
  BAM = 'bam',
  BBD = 'bbd',
  BDT = 'bdt',
  BGN = 'bgn',
  BIF = 'bif',
  BMD = 'bmd',
  BND = 'bnd',
  BOB = 'bob',
  BRL = 'brl',
  BSD = 'bsd',
  BWP = 'bwp',
  BYN = 'byn',
  BZD = 'bzd',
  CAD = 'cad',
  CDF = 'cdf',
  CHF = 'chf',
  CLP = 'clp',
  CNY = 'cny',
  COP = 'cop',
  CRC = 'crc',
  CVE = 'cve',
  CZK = 'czk',
  DJF = 'djf',
  DKK = 'dkk',
  DOP = 'dop',
  DZD = 'dzd',
  EGP = 'egp',
  ETB = 'etb',
  EUR = 'eur',
  FJD = 'fjd',
  FKP = 'fkp',
  GBP = 'gbp',
  GEL = 'gel',
  GIP = 'gip',
  GMD = 'gmd',
  GNF = 'gnf',
  GTQ = 'gtq',
  GYD = 'gyd',
  HKD = 'hkd',
  HNL = 'hnl',
  HTG = 'htg',
  HUF = 'huf',
  IDR = 'idr',
  ILS = 'ils',
  INR = 'inr',
  ISK = 'isk',
  JMD = 'jmd',
  JPY = 'jpy',
  KES = 'kes',
  KGS = 'kgs',
  KHR = 'khr',
  KMF = 'kmf',
  KRW = 'krw',
  KYD = 'kyd',
  KZT = 'kzt',
  LAK = 'lak',
  LBP = 'lbp',
  LKR = 'lkr',
  LRD = 'lrd',
  LSL = 'lsl',
  MAD = 'mad',
  MDL = 'mdl',
  MGA = 'mga',
  MKD = 'mkd',
  MMK = 'mmk',
  MNT = 'mnt',
  MOP = 'mop',
  MUR = 'mur',
  MVR = 'mvr',
  MWK = 'mwk',
  MXN = 'mxn',
  MYR = 'myr',
  MZN = 'mzn',
  NAD = 'nad',
  NGN = 'ngn',
  NIO = 'nio',
  NOK = 'nok',
  NPR = 'npr',
  NZD = 'nzd',
  PAB = 'pab',
  PEN = 'pen',
  PGK = 'pgk',
  PHP = 'php',
  PKR = 'pkr',
  PLN = 'pln',
  PYG = 'pyg',
  QAR = 'qar',
  RON = 'ron',
  RSD = 'rsd',
  RUB = 'rub',
  RWF = 'rwf',
  SAR = 'sar',
  SBD = 'sbd',
  SCR = 'scr',
  SEK = 'sek',
  SGD = 'sgd',
  SHP = 'shp',
  SLE = 'sle',
  SOS = 'sos',
  SRD = 'srd',
  STD = 'std',
  SZL = 'szl',
  THB = 'thb',
  TJS = 'tjs',
  TOP = 'top',
  TRY = 'try',
  TTD = 'ttd',
  TWD = 'twd',
  TZS = 'tzs',
  UAH = 'uah',
  UGX = 'ugx',
  UYU = 'uyu',
  UZS = 'uzs',
  VND = 'vnd',
  VUV = 'vuv',
  WST = 'wst',
  XAF = 'xaf',
  XCD = 'xcd',
  XOF = 'xof',
  XPF = 'xpf',
  YER = 'yer',
  ZAR = 'zar',
  ZMW = 'zmw',
}
