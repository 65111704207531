import React from 'react'
import c from 'classnames'
import Spinner from '../Spinner'

// this classes are base for all buttons
const baseClasses = [
  'focus:outline-none',
  'focus:transition-colors',
  'hover:transition-colors',
  'hover:outline-none',
  'transition-colors',
  'font-bold',
  'px-4',
  'h-10',
  'rounded-md',
  'flex',
  'items-center',
  'justify-center',
  'min-w-button',
  'no-underline',
]

// theses classes are for the default button (white/ on focus black)
const defaultClasses = [
  'hover:bg-spotted-black',
  'hover:text-spotted-white',
  'text-spotted-black',
  'border-solid',
  'border-2',
  'border-spotted-black',
]

// theses classes are for the default button (black/ on focus white)
const invertedClasses = [
  'hover:bg-spotted-light_black',
  'focus:bg-spotted-light_black',
  'text-spotted-white',
  'bg-spotted-black',
]

const goldClasses = [
  'hover:bg-spotted-gold',
  'hover:text-spotted-white',
  'text-spotted-gold',
  'border-solid',
  'border-2',
  'border-spotted-gold',
]

const goldInvertedClasses = [
  'bg-primary-dark',
  'hover:bg-primary-light',
  'hover:text-spotted-white',
  'text-spotted-white',
]

// theses classes are for the default button (green)
const disabledClasses = ['bg-gray-300', 'text-white', 'cursor-auto']

// theses classes are for the cancel button (black)
const cancelClasses = [
  'hover:bg-spotted-light_black',
  'focus:bg-spotted-light_black',
  'text-spotted-white',
  'bg-spotted-black',
]

// theses classes are for the cancel button (red)
const redCancelClasses = ['focus:bg-red-400', 'hover:bg-red-400', 'bg-red-500', 'text-white']

// these classes are for the delete button (red)
const deleteClasses = ['focus:bg-red-500', 'hover:bg-red-500', 'bg-red-400', 'text-white']

// these classes are for the minimal button (green text, no background)
const minimalClasses = ['text-spotted-black', 'hover:ring-spotted-black']

type ButtonProps = {
  onClick?: any
  extraStyle?: any
  children?: any
  cancel?: boolean
  redCancel?: boolean
  isLoading?: boolean
  isDelete?: boolean
  minimal?: boolean
  disabled?: boolean
  gold?: boolean
  goldInverted?: boolean
  inverted?: boolean
  as?: any
  props?: any
}

const Button = ({
  onClick = null,
  extraStyle,
  children,
  cancel = false,
  redCancel = false,
  isLoading = false,
  isDelete = false,
  minimal = false,
  disabled = false,
  gold = false,
  goldInverted = false,
  inverted = false,
  as: Component = 'button',
  ...props
}: ButtonProps) => {
  let styleClasses = defaultClasses

  if (inverted) {
    styleClasses = invertedClasses
  }

  if (cancel) {
    styleClasses = cancelClasses
  }

  if (redCancel) {
    styleClasses = redCancelClasses
  }

  if (isDelete) {
    styleClasses = deleteClasses
  }

  if (minimal) {
    styleClasses = minimalClasses
  }

  if (disabled) {
    styleClasses = disabledClasses
  }

  if (gold) {
    styleClasses = goldClasses
  }

  if (goldInverted) {
    styleClasses = goldInvertedClasses
  }

  // TODO: extraStyle does not replace classes included in baseClasses or styleClasses. it only adds new classes.
  const buttonClasses = [...baseClasses, ...styleClasses, extraStyle]

  return (
    <Component
      className={c(buttonClasses)}
      onClick={(e: any) => {
        if (!disabled && onClick) {
          onClick(e)
        }
      }}
      disabled={isLoading || disabled}
      {...props}
    >
      {isLoading ? <Spinner /> : children}
    </Component>
  )
}

export default Button
