import React, { useState, useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import AdminUserList from './components/UsersList'
import AddUserFormModal from './components/AddUserFormModal/AddUserFormModal'
import UserDeleteModal from './components/UserDeleteModal'
import UserStore from './UserStore'
import { toast } from 'react-toastify'
import StoresContext from '../../providers/storesContext'
import { User } from '../../models/User'
import Spinner from '../../components/UI/Spinner/Spinner'
import ResultsPager from '../../components/UI/ResultsPager'
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler'
import axios from '../../services/axios'
import { useQuery } from '../../hooks/useQuery'
import UserSearchBox from './components/UserSearchBox'

const AdminUsers = () => {
  const query = useQuery()
  const { authStore } = useContext(StoresContext)!
  const [userStore] = useState(() => new UserStore(authStore))
  const [visibleAddUserFormModal, setVisibleAddUserFormModal] = useState(false)
  const [editionAdminUser, setEditionAdminUser] = useState<User | null>(null)
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)
  const [deletionAdminUser, setDeletionAdminUser] = useState<User | null>(null)
  const [deletionAdminUserError, setDeletionAdminUserError] = useState(null)
  const [searchInput, setSearchInput] = useState<string | undefined>()

  const page = Number(query.get('page')) || 1
  const DEFAULT_PAGE_SIZE = 25

  // Create User
  const showAddUserModal = () => {
    setVisibleAddUserFormModal(true)
  }

  const hideAddUserModal = () => {
    setVisibleAddUserFormModal(false)
    // avoids showing undefined name while modal is fading out
    setTimeout(() => setEditionAdminUser(null), 300)
  }

  const afterSaveUser = () => {
    userStore.fetchUsers(page, DEFAULT_PAGE_SIZE, searchInput)
    hideAddUserModal()
  }

  //Delete User
  const handleClickDelete = (user: User) => {
    showDeleteConfirm(user)
  }
  const showDeleteConfirm = (user: User) => {
    setVisibleDeleteModal(true)
    setDeletionAdminUser(user)
  }
  const hideDeleteConfirm = () => {
    setVisibleDeleteModal(false)
    setDeletionAdminUserError(null)
    // avoids showing undefined name while modal is fading out
    setTimeout(() => setDeletionAdminUser(null), 300)
  }
  const handleDeleteAdminUser = () => {
    userStore.deleteUser(deletionAdminUser!).then((error) => {
      if (error) {
        setDeletionAdminUserError(error)
      } else {
        hideDeleteConfirm()
        userStore.fetchUsers(page, DEFAULT_PAGE_SIZE, searchInput)
        toast.success('El usuario ha sido eliminado correctamente.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
    })
  }

  //Suspend User
  const handleClickSuspend = (user: User) => {
    userStore.suspendUser(user).then((result) => {
      if (result) {
        userStore.fetchUsers(page, DEFAULT_PAGE_SIZE, searchInput)
        toast.success('El usuario ha sido suspendido.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
    })
  }

  //Activate User
  const handleClickActivate = (user: User) => {
    userStore.activateUser(user).then((result) => {
      if (result) {
        userStore.fetchUsers(page, DEFAULT_PAGE_SIZE, searchInput)
        toast.success('El usuario ha sido activado.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
    })
  }

  //Edit User
  const handleClickEdit = (user: User) => {
    setEditionAdminUser(user)
    showAddUserModal()
  }

  // Search
  const handleSearch = (input: string | undefined) => {
    setSearchInput(input)
  }

  useEffect(() => {
    userStore.fetchUsers(page, DEFAULT_PAGE_SIZE, searchInput)
  }, [page, searchInput])

  if (userStore.isLoading) {
    return (
      <div className="container flex justify-center h-screen w-screen">
        <div className="mt-32 mr-32 h-16 w-16 ">
          <Spinner size={11} color="text-spotted-gold" />
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col">
      <UserSearchBox handleSearch={handleSearch} search={searchInput} />
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <AdminUserList
            users={userStore.users}
            isLoading={userStore.isLoading}
            handleClickEdit={handleClickEdit}
            handleClickSuspend={handleClickSuspend}
            handleClickActivate={handleClickActivate}
            handleClickDelete={handleClickDelete}
            handleAddUser={showAddUserModal}
          />
          {userStore.users.length > 0 && !userStore.isLoading && (
            <ResultsPager paginator={userStore.paginator} query={query} />
          )}
        </div>
      </div>
      <AddUserFormModal
        editionAdminUser={editionAdminUser}
        afterSaveUser={afterSaveUser}
        handleClose={hideAddUserModal}
        open={visibleAddUserFormModal}
      />
      <UserDeleteModal
        deletionAdminUser={deletionAdminUser}
        handleDelete={handleDeleteAdminUser}
        handleClose={hideDeleteConfirm}
        open={visibleDeleteModal}
        isDeleting={userStore.isLoading}
        error={deletionAdminUserError}
      />
    </div>
  )
}

export default withErrorHandler(withTranslation('common')(observer(AdminUsers)), axios)
