import React from 'react'
import c from 'classnames'
import moment from 'moment'

const inputBaseClasses = [
  'p-2',
  'rounded-md',
  'w-full',
  'focus:ring-4',
  'focus:outline-none',
  'transition-shadow',
]

const inputDefaultClasses = [
  'text-gray-400',
  'focus:ring-gray-600',
  'focus:ring-opacity-20',
  'bg-gray-50',
  'focus:bg-white',
]

const inputInvertedClasses = [
  'bg-gray-600',
  'text-white',
  'focus:ring-gray-800',
  'focus:ring-opacity-80',
]

const inputErrorClasses = ['text-red-400', 'bg-red-50', 'focus:ring-red-500']

type DatePickerProps = {
  onChange: (value: string) => void
  value: string
  onPressEnter?: () => void
  inputRef?: React.RefObject<HTMLInputElement> | null
  inverted?: boolean
  error?: boolean
  extraClassNames?: string
}

const DatePicker = ({
  onChange,
  value,
  onPressEnter = () => {},
  inputRef = null,
  inverted = false,
  error = false,
  extraClassNames = '',
}: DatePickerProps) => {
  const inputStyleClasses = !inverted ? inputDefaultClasses : inputInvertedClasses
  const errorClasses = error ? inputErrorClasses : []
  const inputClasses = [...inputBaseClasses, ...inputStyleClasses, ...errorClasses, extraClassNames]

  const handleKeyPress = (e: { key: string }) => {
    if (e.key === 'Enter' && onPressEnter !== null) {
      onPressEnter()
    }
  }

  const handleChange = (e: { target: { value: string } }) => {
    onChange(e.target.value)
  }

  const formattedDate = value ? moment(value).format('YYYY-MM-DD') : ''

  return (
    <input
      className={c(inputClasses)}
      onKeyPress={handleKeyPress}
      onChange={handleChange}
      ref={inputRef}
      value={formattedDate}
      type="date"
      // {...props}
    />
  )
}

export default DatePicker
