import { makeAutoObservable, runInAction } from 'mobx'
import AuthStore from '../../stores/AuthStore'
import Paginator from '../../stores/Paginator'
import { Accreditation } from 'models/Accreditation'
import AccreditationService from 'services/AccreditationService'
import { Event } from 'models/Event'

class AccreditationStore {
  public accreditations: Accreditation[]
  public events: Event[]
  public isLoading: boolean
  public error: any
  public paginator: Paginator
  private authStore: AuthStore
  private accreditationService: AccreditationService
  constructor(authStore: AuthStore) {
    this.reset()
    makeAutoObservable(this)
    this.accreditationService = new AccreditationService()
    this.authStore = authStore
  }

  reset() {
    this.accreditations = []
    this.events = []
    this.isLoading = false
    this.error = false
  }

  clearError() {
    this.error = null
  }

  setError(val: string) {
    this.error = val
  }

  async fetchAdminAccreditations(page: number, eventId?: string) {
    runInAction(() => {
      this.isLoading = true
    })
    try {
      const response = await this.accreditationService.fetchAdminAccreditations(
        this.authStore.getAdminToken(),
        page,
        eventId
      )
      runInAction(() => {
        this.isLoading = false
        this.accreditations = response.accreditations
        this.paginator = response.paginator
        this.error = null
      })
    } catch (e) {
      this.error = e
      this.isLoading = false
    }
  }

  async fetchEventsWithAccreditationsEnabled() {
    runInAction(() => {
      this.isLoading = true
    })
    try {
      const response = await this.accreditationService.fetchEventsWithAccreditationsEnabled(
        this.authStore.getAdminToken()
      )
      runInAction(() => {
        this.isLoading = false
        this.events = response
        this.error = null
      })
    } catch (e) {
      this.error = e
      this.isLoading = false
    }
  }

  async createAccreditation(
    alias: string,
    landingPath: string
  ): Promise<Accreditation | undefined> {
    runInAction(() => {
      this.isLoading = true
    })
    try {
      const response = await this.accreditationService.createAccreditation(
        alias,
        landingPath,
        this.authStore.getAdminToken()
      )
      runInAction(() => {
        this.isLoading = false
      })
      return response
    } catch (e) {
      this.error = e
      this.isLoading = false
    }
  }

  updateAccreditationStatus(id: string, newStatus: boolean) {
    const accreditation = this.accreditations.find((acc) => acc.id === id)
    if (accreditation) {
      accreditation.isApproved = newStatus
    }
  }

  async approveAccreditation(id: string) {
    try {
      await this.accreditationService.approveAccreditation(this.authStore.getAdminToken(), id)
      runInAction(() => {
        this.updateAccreditationStatus(id, true)
        this.error = null
      })
    } catch (e) {
      this.error = e
    }
  }

  async cancelAccreditation(id: string) {
    try {
      await this.accreditationService.cancelAccreditation(this.authStore.getAdminToken(), id)
      runInAction(() => {
        this.updateAccreditationStatus(id, false)
        this.error = null
      })
    } catch (e) {
      this.error = e
    }
  }
}

export default AccreditationStore
