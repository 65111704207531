import React from 'react'
import Select, {
  ClearIndicatorProps,
  components,
  DropdownIndicatorProps,
  GroupBase,
} from 'react-select'
import { ChevronDownIcon, ArchiveBoxIcon } from '@heroicons/react/20/solid'

const fontSize = '16px'

const customStyles = (inverted: boolean, error: boolean) => ({
  container: (provided: any) => {
    return {
      ...provided,
      width: '100%',
    }
  },
  control: (provided: any, state: { isDisabled: boolean; isFocused: boolean }) => {
    let boxShadowColor = '#d6d9dd'
    let backgroundColor = '#F9FAFB'

    if (inverted) {
      boxShadowColor = '#252e3c'
      backgroundColor = 'rgba(75, 85, 99, 1)'
    }

    if (error) {
      boxShadowColor = 'rgba(239, 68, 68, .2)'
      backgroundColor = '#FEF2F2'
    }

    return {
      ...provided,
      opacity: state.isDisabled ? '0.3' : '1',
      borderWidth: '2px',
      borderColor: 'transparent',
      borderRadius: '6px',
      boxShadow: state.isFocused ? `0 0 0 2px ${boxShadowColor}` : 'none',
      fontWeight: '400',
      minHeight: '40px',
      paddingBottom: '0',
      paddingTop: '0',
      paddingLeft: '0.5rem',
      paddingRight: '5px',
      width: '100%',
      transition: 'box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1)',
      outline: 'none',
      fontSize,
      backgroundColor,
      color: '#9CA3AF',
      ':hover': {
        outline: 'none',
        borderColor: 'transparent',
      },
      ':focus': {
        outline: 'none',
        borderColor: 'transparent',
      },
    }
  },
  valueContainer: (provided: any) => ({
    ...provided,
    minHeight: '38px',
    fontWeight: '400',
    padding: 0,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#9CA3AF',
    fontWeight: '400',
    whiteSpace: 'nowrap',
    fontSize,
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: '#ffffff',
    boxShadow: 'none',
    borderRadius: '6px',
    overflow: 'hidden',
    width: '100%',
    zIndex: 13,
  }),
  option: (provided: any, state: { isSelected: any; isFocused: any }) => {
    return {
      ...provided,
      ':active': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        color: '#9CA3AF',
      },
      color: state.isSelected ? '#ffffff' : '#9CA3AF',
      backgroundColor: state.isSelected
        ? '#ceb67b'
        : state.isFocused
        ? 'rgba(0, 0, 0, 0.05)'
        : '#ffffff',
      fontSize,
    }
  },
  singleValue: (provided: any) => ({
    ...provided,
    color: inverted ? '#ffffff' : '#4B5563',
    fontWeight: '400',
    fontSize,
  }),
  input: (provided: any) => ({
    ...provided,
    color: inverted ? '#ffffff' : '#4B5563',
    fontSize,
    outline: 'none',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: 'hsl(0, 0%, 80%)',
    marginLeft: '-12px',
    marginRight: '-7px',
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    color: 'hsl(0, 0%, 80%)',
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
  }),
  loadingIndicator: (provided: any) => ({
    ...provided,
    span: {
      backgroundColor: 'black',
    },
  }),
})

const DropdownIndicator = (
  props: JSX.IntrinsicAttributes & DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon className="h-6 w-6" />
    </components.DropdownIndicator>
  )
}

const ClearIndicator = (
  props: JSX.IntrinsicAttributes & ClearIndicatorProps<unknown, boolean, GroupBase<unknown>>
) => {
  return (
    <components.ClearIndicator {...props}>
      <ArchiveBoxIcon className="h-4 w-4" />
    </components.ClearIndicator>
  )
}

type FormSelectProps = {
  formSelectAs?: any
  disabled?: boolean
  value?: any
  isMulti?: boolean
  options?: any
  inputRef?: any
  inverted?: boolean
  placeholder?: string
  onPressEnter?: any
  error?: boolean
}

const FormSelect = ({
  formSelectAs: Component = Select,
  disabled,
  value,
  isMulti,
  options,
  inputRef = null,
  inverted = false,
  placeholder = '',
  onPressEnter = null,
  error = false,
  ...props
}: FormSelectProps) => {
  const eleRef = inputRef

  // if (eleRef === null) {
  //   eleRef = useRef(null)
  // }

  const handleKeyDown = (e: { key: string }) => {
    if (onPressEnter) {
      if (!eleRef.current.state.menuIsOpen && e.key === 'Enter') {
        onPressEnter()
      }
    }
  }

  return (
    <Component
      components={{ DropdownIndicator, ClearIndicator }}
      styles={customStyles(inverted, error)}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      isDisabled={disabled}
      options={options}
      isMulti={isMulti}
      value={value}
      ref={eleRef}
      {...props}
    />
  )
}

export default FormSelect
