import React, { Suspense, useContext } from 'react'
import './App.css'
import Layout from './hoc/Layout/Layout'
import Router from './routing'
import storesContext from './providers/storesContext'
import { observer } from 'mobx-react'

function App() {
  const { authStore } = useContext(storesContext)!
  const isAuthenticated = authStore.isAuthenticated() && !authStore.isLoading
  return (
    <div className="App">
      <Layout>
        <Suspense fallback={<p>Loading...</p>}>
          <Router isLoggedIn={isAuthenticated} />
        </Suspense>
      </Layout>
    </div>
  )
}

export default observer(App)
