import React from 'react'
import { PencilSquareIcon } from '@heroicons/react/20/solid'
import { useTranslation, withTranslation } from 'react-i18next'
import SystemVariable from '../../../models/SystemVariable'
import limitChars from '../../../utility'

type AdminSystemVariableRowProps = {
  systemVariable: SystemVariable
  handleClickEdit: (systemVariable: SystemVariable) => void
  formatSystemVariableKey: (key: string) => string
}

const AdminSystemVariableRow = ({
  systemVariable,
  handleClickEdit,
  formatSystemVariableKey,
}: AdminSystemVariableRowProps) => {
  const { t } = useTranslation('common')

  return (
    <tr className="even:bg-gray-100 odd:white text-gray-600 hover:bg-gray-200 transition-colors">
      <td className="px-6 py-4 text-sm font-medium">
        {limitChars(t(formatSystemVariableKey(systemVariable.key)), 50)}
      </td>
      <td className="px-6 py-4 text-sm font-medium">{limitChars(systemVariable.value, 50)}</td>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium flex justify-end">
        <>
          <PencilSquareIcon
            className="text-gray-500 h-5 w-5 cursor-pointer transition-colors hover:text-gray-400"
            onClick={() => handleClickEdit(systemVariable)}
          />
        </>
      </td>
    </tr>
  )
}

export default withTranslation('common')(AdminSystemVariableRow)
