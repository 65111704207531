import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation, withTranslation } from 'react-i18next'
import ActivityFormModalStore from './ActivityFormModalStore'
import StoresContext from '../../../../providers/storesContext'
import Modal, { ModalButtonsDisplay, ModalSize } from '../../../../components/UI/Modal/Modal'
import ServerError from '../../../../components/UI/ServerError'
import InputWrapper from '../../../../components/UI/Forms/InputWrapper'
import Spinner from '../../../../components/UI/Spinner/Spinner'
import { Activity } from '../../../../models/Activity'
import { isNil } from 'lodash'

type SpotFormModalType = {
  open: boolean
  handleClose: () => void
  afterSaveActivity: (activity: Activity) => void
  activityToEdit?: Activity | null
}

const ActivityFormModal = ({
  open,
  handleClose,
  afterSaveActivity,
  activityToEdit = null,
}: SpotFormModalType) => {
  const { authStore } = useContext(StoresContext)!
  const { t } = useTranslation()
  const [activityFormModalStore] = useState(() => new ActivityFormModalStore(authStore))

  useEffect(() => {
    if (!open) {
      // waits until modal is hidden to avoid showing empty data / validation errors
      setTimeout(() => activityFormModalStore.reset(), 300)
    }
  }, [open])

  const handleChangeActivityName = (val) => activityFormModalStore.changeActivityName(val)
  //const handleChangeSpotName = (val) => activityFormModalStore.changeActivityName(val)

  const { activityName } = activityFormModalStore

  const handleSave = () => {
    if (isNil(activityToEdit)) {
      activityFormModalStore.saveActivity().then((activity) => {
        if (activity) {
          afterSaveActivity(activity)
        }
      })
    } else {
      activityFormModalStore.activity = activityToEdit
      activityFormModalStore.editSpot().then((activity) => {
        if (activity) {
          afterSaveActivity(activity)
        }
      })
    }
  }

  if (activityFormModalStore.isLoading) {
    return (
      <div className="container flex justify-center">
        <div className="mt-14">
          <Spinner size={11} color="text-spotted-gold" />
        </div>
      </div>
    )
  }

  return (
    <Modal
      okMessage={isNil(activityToEdit) ? t('Add') : t('Save')}
      loadingOk={activityFormModalStore.isLoading}
      onCancel={handleClose}
      onSuccess={handleSave}
      opened={open}
      buttonsDisplay={ModalButtonsDisplay.ONLY_OK_BUTTON}
      modalSize={ModalSize.MEDIUM}
    >
      <div className="w-full grid grid-rows-1 gap-2">
        <div className="text-lg text-gray-800 font-bold text-center">
          {isNil(activityToEdit) ? t('New Activity') : t('Edit Activity')}
        </div>
        {activityFormModalStore.error && <ServerError message={t(activityFormModalStore.error)} />}
        <InputWrapper
          label={t('Activity Name').toString()}
          placeholder={activityToEdit?.name}
          inputStore={activityName}
          onChange={handleChangeActivityName}
        />
      </div>
    </Modal>
  )
}

export default withTranslation('common')(observer(ActivityFormModal))
