import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation, withTranslation } from 'react-i18next'
import SearchResultListNoResults from '../../../components/SearchResultListNoResults/SearchResultListNoResults'
import Spinner from '../../../components/UI/Spinner/Spinner'
import AccreditationRow from './AccreditationRow'
import AccreditationStore from '../AccreditationStore'
import { PlusCircleIcon } from '@heroicons/react/20/solid'

type AccreditationListProps = {
  store: AccreditationStore
  handleApproveAccreditation: (id: string) => void
  handleCancelAccreditation: (id: string) => void
  handleAddAccreditation: () => void
}

const AccreditationList = ({
  store,
  handleApproveAccreditation,
  handleCancelAccreditation,
  handleAddAccreditation,
}: AccreditationListProps) => {
  const { t } = useTranslation()
  const { isLoading, accreditations } = store

  if (isLoading) {
    return (
      <div className="container flex justify-center">
        <div className="mt-14">
          <Spinner size={11} color="text-spotted-gold" />
        </div>
      </div>
    )
  }

  if (accreditations.length === 0) {
    return (
      <div className="align-middle inline-block min-w-full px-2">
        <div className="flex flex-col items-center justify-center my-2">
          <SearchResultListNoResults />
        </div>
      </div>
    )
  }

  return (
    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr className="font-bold">
            <th scope="col" className="px-4 py-3 text-left text-xs text-gray-500 uppercase">
              {t('Name')}
            </th>
            <th scope="col" className="px-4 py-3 text-left text-xs text-gray-500 uppercase">
              {t('Alias')}
            </th>
            <th scope="col" className="px-4 py-3 text-left text-xs text-gray-500 uppercase">
              {t('Email')}
            </th>
            <th scope="col" className="px-4 py-3 text-left text-xs text-gray-500 uppercase">
              {t('Event')}
            </th>
            <th scope="col" className="px-4 py-3 text-left text-xs text-gray-500 uppercase">
              {t('Event date')}
            </th>
            <th scope="col" className="px-4 py-3 text-left text-xs text-gray-500 uppercase">
              {t('Accreditation date')}
            </th>
            <th scope="col" className="px-4 py-3 text-left text-xs text-gray-500 uppercase">
              {t('Status')}
            </th>
            <th scope="col" className="relative flex justify-end px-4 py-3">
              <PlusCircleIcon
                className="w-6 h-6 transition-colors cursor-pointer text-primary-light hover:text-primary-dark"
                onClick={handleAddAccreditation}
                title="Add Accreditation"
              />
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {accreditations.map((accreditation, index) => (
            <AccreditationRow
              key={index}
              accreditation={accreditation}
              handleCancelAccreditation={handleCancelAccreditation}
              handleApproveAccreditation={handleApproveAccreditation}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default withTranslation('common')(observer(AccreditationList))
