import { makeAutoObservable, runInAction } from 'mobx'
import AuthStore from 'stores/AuthStore'
import Paginator from 'stores/Paginator'
import SystemVariable from '../../models/SystemVariable'
import SystemVariableService from '../../services/SystemVariableService'

const SystemVariablesKey = {
  MINIMUMPHOTOGRAPHPRICE: 'minimum_photograph_price',
  MAXIMUMPHOTOGRAPHPRICE: 'maximum_photograph_price',
}
class AdminSystemVariablesStore {
  public systemVariables: SystemVariable[]
  public paginator: Paginator
  public isLoading: boolean
  public isDeleteing: boolean
  public error: any
  private systemVariableService: SystemVariableService
  private authStore: AuthStore

  constructor(authStore: AuthStore) {
    this.reset()
    makeAutoObservable(this)
    this.systemVariableService = new SystemVariableService()
    this.authStore = authStore
  }

  reset() {
    this.systemVariables = []
    this.paginator = new Paginator({
      hasNextPage: false,
      hasPreviousPage: false,
      itemCount: 0,
      page: 1,
      pageCount: 1,
      take: 10,
    })
    this.isLoading = false
    this.isDeleteing = false
    this.error = false
  }

  systemVariableKeyFormated(key: string): string {
    switch (key) {
      case SystemVariablesKey.MINIMUMPHOTOGRAPHPRICE:
        return 'Minimum photograph price'

      case SystemVariablesKey.MAXIMUMPHOTOGRAPHPRICE:
        return 'Maximum photograph price'

      default:
        throw new Error("System variable key doesn't exist")
    }
  }

  async fetchSystemVariables(page: number) {
    runInAction(() => {
      this.isLoading = true
    })
    try {
      const response = await this.systemVariableService.fetchSystemVariables(
        this.authStore.getToken(),
        page
      )
      runInAction(() => {
        this.isLoading = false
        this.systemVariables = response.systemVariables
        this.paginator = response.paginator
        this.error = null
      })
      return response
    } catch (e) {
      this.error = e
      this.isLoading = false
    }
  }
}

export default AdminSystemVariablesStore
