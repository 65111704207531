export class Activity {
  id: string

  name: string

  isApproved?: boolean

  createdAt?: Date

  updatedAt?: Date

  deletedAt?: Date | null

  creatorId?: string
}
