import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

const getEventValidationSchema = () => {
  const { t } = useTranslation()

  return yup.object({
    name: yup.string().required(t('Name is required') as string),
    description: yup.string().required(t('Description is required') as string),
    landingPath: yup.string().required(t('LandingPath is required') as string),
    activityId: yup
      .string()
      .uuid()
      .required(t('Activity is required') as string),
    locationId: yup
      .string()
      .uuid()
      .required(t('Location is required') as string),
    participants: yup.number().optional(),
    customBannerSrc: yup.string().optional(),
    date: yup.date().required(t('Date is required') as string),
    isFree: yup.boolean().required(t('IsFree is required') as string),
    accreditationsEnabled: yup.boolean().required(t('AccreditationsEnabled is required') as string),
    displayInFeed: yup.boolean().required(t('DisplayInFeed is required') as string),
    faceRecognitionFilter: yup.boolean().required(t('FaceRecognitionFilter is required') as string),
    textFilter: yup.boolean().required(t('TextFilter is required') as string),
    filtersView: yup.boolean().required(t('FiltersView is required') as string),
    currency: yup
      .string()
      .test('currency-required-if-not-free', t('Currency is required') as string, function (value) {
        const { isFree } = this.parent
        if (!isFree) {
          return !!value
        }
        return true
      }),
    commission: yup
      .string()
      .test(
        'commission-required-if-not-free',
        t('Commission is required') as string,
        function (value) {
          const { isFree } = this.parent
          if (!isFree) {
            return !!value
          }
          return true
        }
      ),
    defaultImagePrice: yup
      .number()
      .test(
        'imagePrice-required-if-not-free',
        t('DefaultImagePrice is required') as string,
        function (value) {
          const { isFree } = this.parent
          if (!isFree) {
            return !!value
          }
          return true
        }
      ),
    defaultPackagePrice: yup
      .number()
      .optional()
      .test({
        name: 'price-comparison',
        message: t('Default package price must be greater than default image price') as string,
        test: function (defaultPackagePrice, { parent }) {
          return defaultPackagePrice === undefined || defaultPackagePrice > parent.defaultImagePrice
        },
      }),
    quantityDiscountId: yup.string().uuid().optional(),
  })
}

export default getEventValidationSchema
