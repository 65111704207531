import React, { useState } from 'react'
import { observer } from 'mobx-react'
import c from 'classnames'
import Input from '../Input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons'
import InputStore from './InputStore'

const spanBaseClasses = ['text-sm', 'font-bold', 'mb-1', 'mt-2']

const spanDefaultClasses = ['text-gray-400']

const spanInvertedClasses = ['text-white']

type InputWrapperProps = {
  as?: any
  label?: string
  onChange: Function
  onPressEnter?: Function
  value?: any
  inputStore?: InputStore<any>
  inputRef?: any
  inverted?: any
  type?: string
  password?: boolean
  error?: any
  selected?: any
  autoFocus?: boolean
  placeholder?: string
  onlyNumeric?: boolean
  options?: any
  disabled?: boolean
  className?: string
  extraClassNames?: string
  fixedUnit?: string
}

const InputWrapper = ({
  as: Component = Input,
  label,
  onChange,
  onPressEnter,
  value,
  inputStore,
  inputRef = null,
  inverted = false,
  password = false,
  type,
  selected,
  error = '',
  placeholder,
  disabled = false,
  autoFocus = false,
  onlyNumeric = false,
  className,
  extraClassNames,
  options,
  fixedUnit,
}: InputWrapperProps) => {
  const [seePassword, setSeePassword] = useState(false)
  const spanStyleClasses = !inverted ? spanDefaultClasses : spanInvertedClasses
  const spanClasses = [...spanBaseClasses, ...spanStyleClasses]

  let val = value
  let errorMsg = error

  if (inputStore) {
    val = inputStore.value
    errorMsg = inputStore.errorMessage
  }

  const showHidePassword = () => setSeePassword(!seePassword)

  return (
    <label className="flex flex-col items-start flex-grow">
      <span className={c(spanClasses)}>{label}</span>
      <Component
        error={errorMsg !== ''}
        inputRef={inputRef}
        inverted={inverted}
        onChange={onChange}
        onPressEnter={onPressEnter}
        label={label}
        password={password && !seePassword}
        value={val}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        autoFocus={autoFocus}
        selected={selected}
        onlyNumeric={onlyNumeric}
        options={options}
        className={className}
        extraClassNames={extraClassNames}
        // fixedUnit
      />

      {password && (
        <div className="relative w-full">
          <div className="absolute bottom-3 right-4" onClick={showHidePassword}>
            <i className="cursor-pointer text-xs">
              {!seePassword ? (
                <FontAwesomeIcon icon={faEyeSlash} />
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )}
            </i>
          </div>
        </div>
      )}

      {fixedUnit && (
        <div className="relative">
          <span className="absolute bottom-2.5 left-2.5 text-lg font-medium text-gray-400">
            {fixedUnit}
          </span>
        </div>
      )}
      {errorMsg !== '' && <div className="text-red-400 text-xs pt-1">{errorMsg}</div>}
    </label>
  )
}

export default observer(InputWrapper)
