import React, { useState } from 'react'
import TextField from '@mui/material/TextField'
import { useTranslation } from 'react-i18next'
import Button from 'components/UI/Button'

type LocationSearchProps = {
  search: string | undefined
  handleSearch: (search: string | undefined) => void
}

const LocationSearchBox = ({ handleSearch, search }: LocationSearchProps) => {
  const [searchValue, setSearchValue] = useState<string | undefined>()
  const { t } = useTranslation()

  const onClickSearch = () => {
    handleSearch(searchValue)
  }

  const clearSearch = () => {
    handleSearch(undefined)
  }

  const enterSearch = (event) => {
    if (event.key === 'Enter') handleSearch(searchValue)
  }

  return (
    <div className="grid grid-cols-5 gap-1 mb-5">
      <TextField
        className="col-span-3"
        id="searchName"
        label={search ? t('Searched: ') + search : t('Search by name')}
        placeholder={search}
        variant="outlined"
        onChange={(event) => setSearchValue(event.target.value)}
        onKeyUp={enterSearch}
      />
      <Button inverted={true} onClick={onClickSearch}>
        {t('Search')}
      </Button>
      <Button onClick={clearSearch}>{t('Clear')}</Button>
    </div>
  )
}

export default LocationSearchBox
